import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./App.css";

// Ant Design Css
import "antd/dist/antd.min.css";

import { BrowserRouter } from "react-router-dom";

//Redux toolkit
import { Provider } from "react-redux";
import { store } from "./redux/store";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
  // </React.StrictMode>
);

reportWebVitals();
