import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./userSlice";
import productSlice from "./product";
import langSlice from "./lang";

export const store = configureStore({
  reducer: {
    user: userSlice,
    product: productSlice,
    lang: langSlice,
  },
});
