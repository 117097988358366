import React, { useState, useEffect } from "react";

import moment from "moment/min/moment-with-locales";

import Adminlayout from "../../../components/layout/AdminLayout";

import { useSelector } from "react-redux";

import { Switch, Select, Tag, Modal } from "antd";

import { UserOutlined, DeleteOutlined } from "@ant-design/icons";

import { Link } from "react-router-dom";

//Function
import {
  listUser,
  changeStatus,
  changeRole,
  removeUser,
  resetPassword,
} from "../../../functions/users";

const AllUser = () => {
  //Redux Store
  const { user } = useSelector((state) => state.user);

  const [data, setData] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [values, setValues] = useState({
    id: "",
    password: "",
  });
  const [selectData, setSelectData] = useState([]);
  const [drop, setDrop] = useState([]);

  const showModal = (id) => {
    setIsModalVisible(true);
    setValues({ ...values, id: id });
  };

  const handleChangePassword = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleOk = () => {
    setIsModalVisible(false);
    resetPassword(user.token, values.id, { values })
      .then((res) => {
        // console.log(res);
        loadData(user.token);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // console.log("Data", data);
  useEffect(() => {
    loadData(user.token);
  }, []);

  const loadData = (authtoken) => {
    listUser(authtoken)
      .then((res) => {
        setData(res.data);
        setSelectData(res.data);

        const dataDrop = [...new Set(res.data.map((item) => item.role))];
        setDrop(dataDrop);
        console.log("User", dataDrop);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };

  const handleOnchange = (e, id) => {
    const value = {
      id: id,
      enabled: e,
    };
    changeStatus(user.token, value)
      .then((res) => {
        // console.log(res);
        loadData(user.token);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const handleChangeRole = (e, id) => {
    let values = {
      id: id,
      role: e,
    };
    changeRole(user.token, values)
      .then((res) => {
        // console.log(res);
        loadData(user.token);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const handleRemove = (id) => {
    if (window.confirm("Are You Sure Delete!!")) {
      removeUser(user.token, id)
        .then((res) => {
          // console.log(res);
          loadData(user.token);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  };

  const roleData = ["admin", "director", "user", "patient", "vip_patient"];

  const handleSelectRole = (e) => {
    const value = e.target.value;
    if (value == "all") {
      //
      setSelectData(data);
    } else {
      //
      const filterData = data.filter((item) => {
        return item.role == value;
      });

      setSelectData(filterData);
    }
  };

  return (
    <Adminlayout>
      <div className="sm:px-6 w-full pt-20 font-Noto">
        <div className="px-4 md:px-10 py-4 md:py-7">
          <div className="lg:flex items-center justify-between">
            <p className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800">
              Users Managements
            </p>
            <div className="pink-btn pr-5">ຈຳນວນ: {selectData.length} User</div>
            <div className="md:flex items-center mt-6 lg:mt-0">
              <div className="flex items-center"></div>
              <div className="flex items-center mt-4 md:mt-0 md:ml-3 lg:ml-0">
                <div className="w-40 py-2 px-3 bg-white lg:ml-3 border rounded border-gray-200">
                  <select
                    onChange={(e) => handleSelectRole(e)}
                    className="w-full text-sm leading-3 text-gray-500 focus:outline-none"
                  >
                    <option value="all">all</option>
                    {drop.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
                <Link to="/register">
                  <button className="inline-flex ml-1.5  justify-center pt-2 px-2 text-center bg-indigo-700 hover:bg-indigo-600 focus:outline-none rounded">
                    <p className="text-sm font-medium leading-none text-white">
                      Add User
                    </p>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white px-4 md:px-8 xl:px-10 overflow-x-auto">
          <table className="w-full whitespace-nowrap">
            <thead>
              <tr className="h-20 w-full  leading-none text-gray-600 font-Phetsarath  text-xl">
                <th className="text-left pl-4">ລຳດັບ</th>
                <th className=" text-left pl-10">ຊື່ຜູ້ໃຊ້ງານ</th>
                <th className=" text-left pl-5">ປະເພດຜູ້ໃຊ້ງານ</th>

                <th className=" text-left ">ສະຖານະ</th>
                <th className=" text-left pl-10 ">ວັນທີສ້າງ</th>
                <th className=" text-left pl-10">ວັນທີອັບເດດ</th>

                <th className="font-normal text-left pl-20 w-32">Actions</th>
              </tr>
            </thead>

            <tbody className="w-full font-Phetsarath">
              {selectData.map((item, index) => (
                <tr
                  key={item.username}
                  className="h-20 text-sm leading-none text-gray-700 border-b border-t border-gray-200 bg-white hover:bg-gray-100"
                >
                  <td className="pl-4">{index + 1}</td>
                  <td className="pl-11">
                    <div className="flex items-center">
                      <img
                        className="shadow-md rounded-full w-10 h-10 mr-3"
                        src={item?.images[1]?.url}
                      />
                      {item.username}
                    </div>
                  </td>
                  <td>
                    <Select
                      style={{ width: "120" }}
                      value={item.role}
                      onChange={(e) => handleChangeRole(e, item._id)}
                    >
                      {roleData.map((item, index) => (
                        <Select.Option value={item} key={index}>
                          {item == "admin" ? (
                            <Tag icon={<UserOutlined />} color="#2de810">
                              {item}
                            </Tag>
                          ) : (
                            <Tag color="red">{item}</Tag>
                          )}
                        </Select.Option>
                      ))}
                    </Select>
                  </td>
                  <td>
                    <Switch
                      checked={item.enabled}
                      onChange={(e) => handleOnchange(e, item._id)}
                    />
                  </td>
                  <td> {moment(item.createdAt).locale("lo").format("ll")}</td>

                  <td>
                    {moment(item.updatedAt)
                      .locale("lo")
                      .startOf(item.updatedAt)
                      .fromNow()}
                  </td>

                  <td>
                    <div className="flex justify-between">
                      <button className="bg-green-500 mr-3 hover:bg-green-700 py-2.5 px-5 rounded text-sm leading-3 text-white focus:outline-none ">
                        <div
                          onClick={() => showModal(item._id)}
                          className="flex justify-between"
                        >
                          <div className="pr-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="w-4 h-4 text-white"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                              />
                            </svg>
                          </div>
                          <div className="text-center pt-1">Edit</div>
                        </div>
                      </button>
                      <button className="bg-pink-500 mr-3 hover:bg-pink-700 py-2.5 px-5 rounded text-sm leading-3 text-white focus:outline-none ">
                        <div
                          onClick={() => handleRemove(item._id)}
                          className="flex justify-between"
                        >
                          <div className="pr-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="w-4 h-4 text-white"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                              />
                            </svg>
                          </div>
                          <div className="text-center pt-1">Delete</div>
                        </div>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Modal
            className="font-Phetsarath font-semibold text-2xl text-center"
            title="ປ່ຽນລະຫັດຜ່ານ"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <div className="flex flex-column">
              <div className="text-center text-xl pt-1">ລະຫັດຜ່ານໃໝ່</div>
              <d className="pl-5">
                <input
                  className="border border-gray-300 dark:border-gray-700 pl-1 py-1 shadow-sm rounded text-lg focus:outline-none bg-transparent focus:border-indigo-700 text-gray-800 dark:text-gray-100"
                  onChange={handleChangePassword}
                  type="text"
                  name="password"
                />
              </d>
            </div>
          </Modal>
        </div>
      </div>
    </Adminlayout>
  );
};

export default AllUser;
