import React from "react";

import { Layout } from "antd";

import DirectorNav from "../../components/sidebar/DirectorNav";
import DirecterTopNav from "../Topnav/DirectorTopNav";

const { Content } = Layout;

const DirectorLayout = ({ children }) => {
  return (
    <Layout>
      {/* <DirecterTopNav /> */}
      <Layout>
        <DirectorNav />
        <Content style={{ padding: "10px" }}>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default DirectorLayout;
