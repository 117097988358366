import React, { useState, useEffect } from "react";
import Adminlayout from "../../../components/layout/AdminLayout";
import { CreateDoctor } from "../../../functions/doctor";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import DoctorFormCreate from "../../../components/forms/DoctorFormCreate";
import { useNavigate } from "react-router-dom";

const DoctorCreate = () => {
  const { user } = useSelector((state) => state.user);
  console.log(user);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [values, setValues] = useState({
    name_en: "",
    name_la: "",
    deeper_la: "",
    deeper_en: "",
    experience_en: "",
    experience_la: "",
    education_la: "",
    education_en: "",
    mobileNumber: "",
    department: "",
    position: "",
    professional_level: "",
    mobileNumber: "",
    email: "",
    images: [],
    description: "",
    dayvisit: [],
    service: [],
    time_visit: [],
    room_number: [],
  });

  const handledoctor = (e) => {
    console.log("name", values);
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    CreateDoctor(user.token, values)
      .then((res) => {
        setLoading(false);
        toast.success("Create " + res.data.name_la + " Success");
        navigate("/admin/alldoctor");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response);
      });
  };

  return (
    <Adminlayout>
      <div className="contrainer mx-auto p-5 pt-20">
        <div className="col-span-9 shadow-xl rounded px-6 bg-white pt-5 pb-7 mt-6 lg:mt-0 font-Noto">
          <DoctorFormCreate
            onSubmit={onSubmit}
            handledoctor={handledoctor}
            values={values}
            setValues={setValues}
          />
        </div>
      </div>
    </Adminlayout>
  );
};

export default DoctorCreate;
