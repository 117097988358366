import React, { useState } from "react";
//and Design

// Load Data From Server pass Functions

//File Upload
import FileUpload from "./FileUpload";

const DepartmentFormUpdate = ({
  onSubmit,
  handleChange,
  values,
  setValues,
}) => {
  const { name, name_en, description } = values;
  console.log("valuesDATA", values);
  const [loading, setLoading] = useState(false);
  return (
    <form onSubmit={onSubmit}>
      <h3 className="text-lg capitalize mb-4 font-semibold text-green-500">
        ແກ້ໄຂຂໍ້ມູນພະແນກ
      </h3>

      <hr className="p-3" />
      <div className="space-y-4">
        <div className="grid sm:grid-cols-2 gap-4">
          <div>
            <input
              type="text"
              placeholder="ຊື່ພະແນກເປັນພາສາລາວ"
              className="input-box w-full px-2 py-2 rounded-lg outline-none bg-slate-100"
              name="name"
              value={name}
              required
              onChange={handleChange}
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="ຊື່ພະແນກເປັນພາສາອັງກິດ"
              className="input-box w-full px-2 py-2 rounded-lg outline-none bg-slate-100"
              name="name_en"
              value={name_en}
              required
              onChange={handleChange}
            />
          </div>

          <div>
            <textarea
              id="message"
              rows="4"
              name="description"
              value={description}
              onChange={handleChange}
              className="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="ປະສົບການເປັນພາສາລາວ"
            ></textarea>
          </div>

          <div>
            <FileUpload
              loading={loading}
              setLoading={setLoading}
              values={values}
              setValues={setValues}
            />
          </div>
        </div>
      </div>
      <div className="pt-3 ">
        <button className="px-6 py-2 bg-green-500 text-white  rounded-lg hover:bg-green-700">
          Save
        </button>
      </div>
    </form>
  );
};

export default DepartmentFormUpdate;
