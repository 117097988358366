import React from "react";

import { Layout } from "antd";

import AdminNav from "../../components/sidebar/AdminNav";
import AdminTopNav from "../Topnav/Admintopnav";

const { Content } = Layout;

const Adminlayout = ({ children }) => {
  return (
    <Layout>
      <AdminNav />
      {/* <AdminTopNav /> */}
      <Layout>
        <Content style={{ padding: "10px" }}>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default Adminlayout;
