import React from "react";
import DayVisitCard from "../../../components/cards/DayVisitCard";

export default function AllDayVisit() {
  return (
    <>
      <div className="  max-w-[1500px] mx-auto">
        <DayVisitCard />
      </div>
    </>
  );
}
