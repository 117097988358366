import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function DoctorCardLandingPage({
  lang,
  Link,
  doctoralldata,
  DoctorLandingPageShow,
}) {
  //css for react-slick
  let settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 5,

    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1536,
        settings: {
          slidesToShow: 6,
        },
      },
    ],
  };

  return (
    <div className="">
      <div className="flex justify-around">
        <div className="text-orange-600 font-bold text-2xl text-center font-Noto ">
          {lang === "en" ? (
            <a className="text-pink-700">Doctors</a>
          ) : (
            <a className="text-pink-700">ບັນດາທ່ານໝໍຊ່ຽວຊານ</a>
          )}
        </div>
        <Link to="/landingpage/alldoctor">
          <button className="bg-pink-500 py-1 px-5 text-white  hover:bg-pink-300 rounded-full mx-0 my-0">
            {lang === "en" ? (
              <a className="text-white">All Doctors</a>
            ) : (
              <a className="text-white font-Noto font-semibold">ທ່ານໝໍທັງໝົດ</a>
            )}
          </button>
        </Link>
      </div>

      <Slider {...settings}>
        {DoctorLandingPageShow?.map((item) => (
          <div
            className="grid grid-cols-1 lg:grid-cols-6 gap-4 md:grid-cols-4 sm:grid-cols-2 pt-4 "
            key={item?._id}
          >
            <div className="border shadow-lg rounded-lg hover:scale-105 duration-300">
              <Link to={`/landingpage/doctordetail/${item?._id}`}>
                <img
                  src={item?.images[0]?.url}
                  alt={item?._id}
                  className="w-full object-cover rounded-lg object-center sm:h-full md:h-[300px] "
                />
              </Link>
              <div className=" md:grid-rows-2 px-2 py-4 font-Noto mx-0 my-0  ">
                <div className="">
                  <p className="font-bold text-center text-pink-600 text-lg">
                    {lang === "en" ? (
                      <a className="text-pink-700 font-Noto font-semibold">
                        {" "}
                        {item?.name_en}
                      </a>
                    ) : (
                      <a className="text-pink-700 font-Noto font-semibold">
                        {item?.name_la}
                      </a>
                    )}
                  </p>
                </div>
                <div
                  className=" text-center  bg-gradient-to-r rounded-full
      from-pink-200
      via-pink-500
      to-pink-300"
                >
                  <p className="font-bold text-white ">{item?.position}</p>
                </div>
                <div className="text-center text-green-500 font-semibold ">
                  {lang === "en" ? (
                    <a className="text-pink-700 font-Noto font-semibold">
                      {" "}
                      {item?.department?.name_en}
                    </a>
                  ) : (
                    <a className="text-pink-700 font-Noto font-semibold">
                      {item?.department?.name}
                    </a>
                  )}
                </div>
                <div className="font-bold text-center ">
                  <Link to={`/landingpage/doctordetail/${item?._id}`}>
                    <button className="pink-btn">
                      {lang === "en" ? (
                        <a className="text-white font-Noto font-semibold">
                          Detail
                        </a>
                      ) : (
                        <a className="text-white font-Noto font-semibold">
                          ລາຍລະອຽດ
                        </a>
                      )}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}
