import { Card, Rate, Avatar, Tooltip, Breadcrumb } from "antd";

import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { ListDoctor, ReadDoctor } from "../../../functions/doctor";
import { useSelector } from "react-redux";
export default function DoctorDetailLandingPage() {
  const { lang } = useSelector((state) => state.lang.lang);
  const params = useParams();
  const [doctorsingledata, setDoctorSingleData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [doctoralldata, setDoctorAllData] = useState([]);
  const {
    name_la,
    name_en,
    mobileNumber,
    deeper_la,
    position,
    department,
    dayvisit,
    time_visit,
    service,
    room_number,
    education_la,
    education_en,
    professional_level,
    deeper_en,
  } = doctorsingledata;

  //   const { name } = department;
  useEffect(() => {
    if (params?.id) loadData();
  }, [params?.id]);

  const loadData = () => {
    setLoading(true);
    ReadDoctor(params.id)
      .then((res) => {
        setDoctorSingleData(res.data);
        setLoading(false);
        // console.log(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });

    setLoading(true);
    ListDoctor()
      .then((res) => {
        setDoctorAllData(res.data);
        setLoading(false);
        // console.log(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const doctordianosis = doctoralldata.filter((item) => {
    return (
      item.diagnosis == doctorsingledata.diagnosis &&
      item.name_la !== doctorsingledata.name_la
    );
  });

  // console.log("doctorSingleData=>", doctorsingledata);
  // console.log("doctorBy Dianosis=>", doctordianosis);
  // console.log(" Dayvisit=>", dayvisit);
  // console.log(" Servicevisit=>", service);

  return (
    <>
      <div className="pt-20 text-xl pl-20 font-Noto">
        <Breadcrumb
          separator=">"
          style={{ fontSize: "16px", fontWeight: "bold" }}
        >
          <Breadcrumb.Item>
            <Link to="/">
              {lang === "en" ? (
                <a className="text-pink-500">Home</a>
              ) : (
                <a className="text-pink-500 font-Noto">ໜ້າຫຼັກ</a>
              )}
            </Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item href="">
            {" "}
            <Link to="/landingpage/alldoctor">
              {lang === "en" ? (
                <a>All Doctors</a>
              ) : (
                <a className=" font-Noto">ທ່ານໝໍທັງໝົດ</a>
              )}
            </Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            {lang === "en" ? (
              <a className=" font-Noto"> {name_en}</a>
            ) : (
              <a className=" font-Noto"> {name_la}</a>
            )}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="container pt-5 mx-auto font-Noto bg-[#FBFBFC]">
        <div className="flex flex-wrap">
          <div className="md:w-2/5 w-full pb-6 md:pb-0 md:pr-6 ">
            {doctorsingledata?.images?.map((im) => (
              <Card
                className="text-center"
                hoverable
                style={{
                  width: 500,
                  borderRadius: 20,
                }}
                key={im._id}
                cover={<img alt={im?.public_id} src={im?.url} />}
              ></Card>
            ))}
            <br />
            <p className="text-center text-xl font-semibold text-gray-700">
              {lang === "en" ? (
                <a> Related Doctors </a>
              ) : (
                <a className=" font-Noto">ທ່ານໝໍທີ່ກ່ຽວຂ້ອງ</a>
              )}
            </p>
            <section className="bg-white border overflow-x-scroll border-gray-400 p-4 flex space-x-4 rounded-xl">
              {doctordianosis.map((d) => (
                <div key={d._id} className="rounded-full w-15   h-14 flex-none">
                  <Tooltip
                    placement="top"
                    title={
                      lang === "en" ? (
                        <a> {d.name_en}</a>
                      ) : (
                        <a className=" font-Noto"> {d.name_la}</a>
                      )
                    }
                  >
                    <Link to={`/landingpage/doctordetail/${d?._id}`}>
                      <Avatar src={d?.images[0]?.url} size={70} />
                    </Link>
                  </Tooltip>
                </div>
              ))}
            </section>
          </div>
          <div className="md:w-3/5 w-full">
            <div className=" rounded-t-xl border-solid border-2 border-pink-300 text-gray-500">
              <h2 className="header-content-doctor-detail">
                {lang === "en" ? (
                  <a className="font-Noto text-gray-800 ">
                    {" "}
                    Doctor Information
                  </a>
                ) : (
                  <a className=" font-Noto text-gray-700 "> ຂໍ້ມູນທ່ານໝໍ</a>
                )}
              </h2>
              <div className="p-5">
                <div className="text-xl border-b-2 flex">
                  <p className="font-semibold pr-5">
                    {lang === "en" ? (
                      <a className=" font-Noto text-gray-500"> Full Name:</a>
                    ) : (
                      <a className=" font-Noto text-gray-500">
                        {" "}
                        ຊື່ ແລະ ນາມສະກຸນ:
                      </a>
                    )}
                  </p>
                  <p className="text-pink-500 font-semibold">
                    {lang === "en" ? (
                      <a className="text-pink-500"> {name_en}</a>
                    ) : (
                      <a className=" text-pink-500 font-Noto"> {name_la}</a>
                    )}
                  </p>
                </div>
                <div className="text-xl border-b-2 flex">
                  <p className="font-semibold pr-5">
                    {lang === "en" ? (
                      <a className=" font-Noto text-gray-500"> Tel:</a>
                    ) : (
                      <a className=" font-Noto text-gray-500">ເບີໂທລະສັບ:</a>
                    )}
                  </p>
                  <p className="text-pink-500 font-semibold"> {mobileNumber}</p>
                </div>
                <div className="text-xl border-b-2 flex">
                  <p className="font-semibold pr-5">
                    {lang === "en" ? (
                      <a className=" font-Noto text-gray-500">Position:</a>
                    ) : (
                      <a className=" font-Noto text-gray-500">ຕຳແໜ່ງ:</a>
                    )}
                  </p>
                  <p className="text-pink-500 font-semibold"> {position}</p>
                </div>

                <div className="text-xl border-b-2 flex">
                  <p className="p font-semibold pr-2 ">
                    {lang === "en" ? (
                      <a className=" font-Noto text-gray-500">Department:</a>
                    ) : (
                      <a className=" font-Noto text-gray-500">ພະແນກ:</a>
                    )}
                  </p>
                  <p className=" text-pink-500 pr-2 flex font-semibold">
                    {lang === "en" ? (
                      <a className=" text-pink-500 pr-2 flex font-semibold">
                        {" "}
                        {department?.name_en}
                      </a>
                    ) : (
                      <a className=" text-pink-500 pr-2 flex font-semibold">
                        {" "}
                        {department?.name}
                      </a>
                    )}
                  </p>
                </div>
                <div className="text-xl border-b-2 flex">
                  <p className="font-semibold pr-2">
                    {lang === "en" ? (
                      <a className=" text-gray-500 pr-2 flex font-semibold">
                        {" "}
                        Specialist
                      </a>
                    ) : (
                      <a className=" text-gray-500 pr-2 flex font-semibold">
                        {" "}
                        ລົງເລິກ:
                      </a>
                    )}
                  </p>
                  <p className="text-pink-500 pr-2 flex font-semibold">
                    {" "}
                    {lang === "en" ? (
                      <a className=" text-pink-500 pr-2 flex font-semibold">
                        {" "}
                        {deeper_en}
                      </a>
                    ) : (
                      <a className=" text-pink-500 pr-2 flex font-semibold">
                        {" "}
                        {deeper_la}
                      </a>
                    )}
                  </p>
                </div>
              </div>
            </div>
            <br />
            <div className="grid sm:grid-cols-2 gap-4">
              <div className=" pb-3 rounded-t-xl border-solid border-2 border-pink-300 text-center ">
                <h2 className="header-content-doctor-detail ">
                  {lang === "en" ? <a>Day</a> : <a>ວັນກວດ</a>}
                </h2>

                {dayvisit?.map((d) => (
                  <Link to={`/landingpage/daydetail/${d?._id}`}>
                    <button
                      className="pink-btn text-center text-xl"
                      key={d?._id}
                    >
                      {lang === "en" ? (
                        <a className="text-white">{d?.name_en}</a>
                      ) : (
                        <a className="text-white">ວັນ{d?.name_la}</a>
                      )}
                    </button>
                  </Link>
                ))}
              </div>

              <div className="  rounded-t-xl border-solid border-2 border-pink-300 text-center ">
                <h2 className="header-content-doctor-detail ">
                  {" "}
                  {lang === "en" ? <a>Time</a> : <a>ເວລາກວດ</a>}
                </h2>
                {time_visit?.map((d, i) => (
                  <button className="pink-btn text-center text-xl" key={i}>
                    {d}
                  </button>
                ))}
              </div>
            </div>
            <br />
            <div className="grid sm:grid-cols-2 gap-4">
              <div className="rounded-t-xl border-solid border-2 border-pink-300 text-center ">
                <h2 className="header-content-doctor-detail ">
                  {lang === "en" ? <a>Dianosis</a> : <a>ພະຍາດທີ່ກວດ</a>}
                </h2>

                {service?.map((d) => (
                  <Link to={`/landingpage/servicedetail/${d?._id}`}>
                    <button
                      className="pink-btn text-center text-xl"
                      key={d?._id}
                    >
                      {lang === "en" ? (
                        <a className="text-white"> {d?.serviceName_en}</a>
                      ) : (
                        <a className="text-white"> {d?.serviceName_la}</a>
                      )}
                    </button>
                  </Link>
                ))}
              </div>

              <div className=" pb-2 rounded-t-xl border-solid border-2 border-pink-300 text-center ">
                <h2 className="header-content-doctor-detail ">
                  {lang === "en" ? <a>Number</a> : <a>ຫ້ອງເບີ</a>}
                </h2>
                {room_number?.map((d, i) => (
                  <button className="pink-btn text-center text-xl" key={i}>
                    NO: {d}
                  </button>
                ))}
              </div>
            </div>
            <br />
            <div className="  rounded-t-xl border-solid border-2 border-pink-300 text-center ">
              <h2 className="header-content-doctor-detail ">
                {lang === "en" ? <a>Education</a> : <a>ຂໍ້ມູນການສືກສາ</a>}
              </h2>
              <div className="flex justify-center px-5">
                {lang === "en" ? (
                  <a className="text-gray-600 text-lg">{education_en}</a>
                ) : (
                  <a className="text-gray-600 text-lg">{education_la}</a>
                )}
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
    </>
  );
}
