import React from "react";
import { Select } from "antd";

const { Option } = Select;

const DayFormUpdate = ({
  handleSubmit,
  handleChange,
  setValues,
  values,
  doctordb,
  servicedb,
  time_visitdata,
  room_numberdata,
}) => {
  const {
    name_la,
    name_en,
    description_la,
    description_en,
    doctor,
    service,
    time_visit,
    room_number,
  } = values;

  return (
    <form
      onSubmit={handleSubmit}
      className="container mx-auto bg-white shadow rounded p-5 font-Noto"
    >
      <div className="pb-5 text-center text-xl font-bold text-orange-600">
        {" "}
        ແກ້ໄຂວັນກວດພະຍາດ
      </div>
      <div className=" pb-5">
        <label className="font-Noto font-semibold">ຊື່ວັນກວດເປັນພາສາລາວ</label>
        <input
          type="text"
          name="name_la"
          className="form-control "
          value={name_la}
          onChange={handleChange}
        />
      </div>
      <div className=" pb-5">
        <label className="font-Noto font-semibold ">
          ຊື່ວັນກວດເປັນພາສາອັງກິດ
        </label>
        <input
          type="text"
          name="name_en"
          className="form-control"
          value={name_en}
          onChange={handleChange}
        />
      </div>

      <div className="pb-5">
        <label className="font-Noto font-semibold ">ເລືອກທ່ານໝໍທີ່ຈະກວດ</label>
        <Select
          className="font-Noto"
          mode="multiple"
          style={{ width: "100%" }}
          placeholder="Please select"
          value={doctor}
          onChange={(value) => setValues({ ...values, doctor: value })}
        >
          {doctordb?.map((s) => (
            <Option key={s._id} value={s._id} className="font-Noto">
              {s.name_la}
            </Option>
          ))}
        </Select>
      </div>

      <div className="pb-5">
        <label className="font-Noto font-semibold ">ເລືອກການບໍລິການ</label>
        <Select
          mode="multiple"
          style={{ width: "100%" }}
          placeholder="Please select"
          value={service}
          className="font-Noto"
          onChange={(value) => setValues({ ...values, service: value })}
        >
          {servicedb?.map((s) => (
            <Option key={s._id} value={s._id} className="font-Noto">
              {s.serviceName_la}
            </Option>
          ))}
        </Select>
      </div>

      <div className="pb-5">
        <label className="font-Noto font-semibold ">ເລືອກເວລາກວດ</label>
        <Select
          className="font-Noto"
          mode="multiple"
          style={{ width: "100%" }}
          placeholder="ເລືອກເວລາກວດ"
          value={time_visit}
          onChange={(value) => setValues({ ...values, time_visit: value })}
        >
          {time_visitdata?.map((s) => (
            <Option key={s.value} value={s.value} className="font-Noto">
              {s.value}
            </Option>
          ))}
        </Select>
      </div>

      <div>
        <label className="font-Noto font-semibold ">ເລືອກຫ້ອງກວດ</label>
        <Select
          className="font-Noto"
          mode="multiple"
          style={{ width: "100%" }}
          placeholder="ເລືອກຫ້ອງກວດເບີ"
          value={room_number}
          onChange={(value) => setValues({ ...values, room_number: value })}
        >
          {room_numberdata?.map((s) => (
            <Option key={s.value} value={s.value} className="font-Noto">
              {s.value}
            </Option>
          ))}
        </Select>
      </div>

      <div className="pt-3">
        <textarea
          id="message"
          rows="4"
          value={description_la}
          name="description_la"
          onChange={handleChange}
          className="form-control"
          placeholder="ຂໍ້ມູນຕ່າງໆເປັນພາສາລາວ"
        ></textarea>
      </div>
      <div className="pt-3">
        <textarea
          id="message"
          rows="4"
          value={description_en}
          name="description_en"
          onChange={handleChange}
          className="form-control"
          placeholder="ຂໍ້ມູນຕ່າງໆເປັນພາສາອັງກິດ"
        ></textarea>
      </div>
      <br />
      <button className="pink-btn ">Save</button>
    </form>
  );
};

export default DayFormUpdate;
