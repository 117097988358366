import React, { useState, useEffect } from "react";
import Adminlayout from "../../../components/layout/AdminLayout";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { ReadDay, UpdateDay } from "../../../functions/day";
import DayFormUpdate from "../../../components/forms/DayFormUpdate";
import { ListServices } from "../../../functions/service";
import { ListDoctor } from "../../../functions/doctor";

const time_visitdata = [
  { value: "8:00-16:00" },
  { value: "8:00-12:00" },
  { value: "13:30-16:00" },
];
const room_numberdata = [
  { value: "OPD" },
  { value: "1" },
  { value: "2" },
  { value: "3" },
  { value: "4" },
  { value: "5" },
  { value: "6" },
  { value: "7" },
  { value: "8" },
  { value: "9" },
  { value: "10" },
  { value: "11" },
  { value: "12" },
  { value: "13" },
  { value: "14" },
  { value: "15" },
  { value: "16" },
  { value: "17" },
  { value: "18" },
  { value: "19" },
  { value: "20" },
];

const DayUpdate = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [values, setValues] = useState({
    name_la: "",
    name_en: "",
    description_la: "",
    description_en: "",
    time_visit: [],
    room_number: [],
    doctor: [],
    service: [],
  });
  const { user } = useSelector((state) => state.user);
  // console.log(user);
  const [doctordb, setDoctordb] = useState([]);
  const [servicedb, setServicedb] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    ReadDay(id)
      .then((res) => {
        setValues({ ...values, ...res.data });
      })
      .catch((err) => {
        console.log(err);
      });

    // Load Doctor Data
    ListDoctor()
      .then((res) => {
        setDoctordb(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    // Load Service Data
    ListServices()
      .then((res) => {
        setServicedb(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    UpdateDay(user.token, values, id)
      .then((res) => {
        console.log(res);
        toast.success("ອັບເດດ " + res.data.name_la + " Success!!!");
        navigate("/admin/day/create");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.err);
      });
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    // console.log(e.target.name, " ----- ", e.target.value);
  };

  return (
    <Adminlayout>
      <div className="pt-20" />
      <div className="container pt-6 mx-auto">
        <div className="flex flex-wrap">
          <div className="md:w-4/4 w-full pb-6 md:pb-0 md:pr-6">
            {/* form */}

            <DayFormUpdate
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              setValues={setValues}
              values={values}
              time_visitdata={time_visitdata}
              room_numberdata={room_numberdata}
              servicedb={servicedb}
              doctordb={doctordb}
            />
          </div>
        </div>
      </div>
    </Adminlayout>
  );
};

export default DayUpdate;
