import React, { useState, useEffect } from "react";
import Adminlayout from "../../../components/layout/AdminLayout";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import DayFormCreate from "../../../components/forms/DayFormCreate";
import { Table } from "antd";
import { CreateDay, DeleteDay, ListDay } from "../../../functions/day";
import { ListDoctor } from "../../../functions/doctor";
import { ListServices } from "../../../functions/service";

const time_visitdata = [
  { value: "8:00-16:00" },
  { value: "8:00-12:00" },
  { value: "13:30-16:00" },
];
const room_numberdata = [
  { value: "OPD" },
  { value: "1" },
  { value: "2" },
  { value: "3" },
  { value: "4" },
  { value: "5" },
  { value: "6" },
  { value: "7" },
  { value: "8" },
  { value: "9" },
  { value: "10" },
  { value: "11" },
  { value: "12" },
  { value: "13" },
  { value: "14" },
  { value: "15" },
  { value: "16" },
  { value: "17" },
  { value: "18" },
  { value: "19" },
  { value: "20" },
];

const DayCreate = () => {
  const [values, setValues] = useState({
    name_la: "",
    name_en: "",
    description_la: "",
    description_en: "",
    time_visit: [],
    room_number: [],
    doctor: [],
    service: [],
  });
  const { user } = useSelector((state) => state.user);
  // console.log(user);
  const [servicedata, setServicedata] = useState([]);
  const [doctordb, setDoctordb] = useState([]);
  const [servicedb, setServicedb] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    ListDay()
      .then((res) => {
        setServicedata(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    // Load Doctor Data
    ListDoctor()
      .then((res) => {
        setDoctordb(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    // Load Service Data
    ListServices()
      .then((res) => {
        setServicedb(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    CreateDay(user.token, values)
      .then((res) => {
        console.log(res);
        toast.success("ເພີ່ມ " + res.data.name_la + " Success!!!");
        loadData();
        setValues("");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.err);
      });
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    // console.log(e.target.name, " ----- ", e.target.value);
  };

  const handleRemove = (id) => {
    if (window.confirm("Are you sure Delete!")) {
      DeleteDay(user.token, id)
        .then((res) => {
          console.log(res);
          loadData(user.token);
          toast.success("Remove Data " + res.data.name + " Success!!!");
        })
        .catch((err) => {
          console.log(err);
          toast.error("Error!!! Insert Data");
        });
    }
  };

  const columns = [
    {
      title: "ຊື່ ແລະ ນາມສະກຸນ",
      dataIndex: "name_la",
      key: "name_la",
    },

    {
      title: "Actions",
      render: (record) => (
        <>
          <button className="orange-btn">
            <div
              onClick={() => handleRemove(record._id)}
              className="flex justify-between"
            >
              <div className="pr-1">
                <DeleteOutlined
                  style={{ fontSize: "15px", pendinglelf: "10px" }}
                />
              </div>
              <div className="text-center pt-1">Delete</div>
            </div>
          </button>

          <Link to={"/admin/day/update/" + record._id}>
            <button className="green-btn">
              <div className="flex justify-between">
                <div className="pr-1">
                  <EditOutlined style={{ fontSize: "15px" }} />
                </div>
                <div className="text-center pt-1">Edit</div>
              </div>
            </button>
          </Link>
        </>
      ),
    },
  ];

  return (
    <Adminlayout>
      <div className="pt-20" />
      <div className="container pt-6 mx-auto bg-gray-200 p-5">
        <div className="flex flex-wrap">
          <div className="md:w-2/4 w-full pb-6 md:pb-0 md:pr-6">
            {/* form */}
            <DayFormCreate
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              setValues={setValues}
              values={values}
              time_visitdata={time_visitdata}
              room_numberdata={room_numberdata}
              servicedb={servicedb}
              doctordb={doctordb}
            />
          </div>
          <div className="md:w-2/4 w-full font-Noto">
            <Table columns={columns} dataSource={servicedata} />
          </div>
        </div>
      </div>
    </Adminlayout>
  );
};

export default DayCreate;
