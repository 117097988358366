import React from "react";
import { Form, Input, Button, Space } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
function SkillsEducation({}) {
  return (
    <div>
      <h5>
        <b>Education</b>
      </h5>
      <hr className="pb-3" />
      <Form.List name="education">
        {(fields, { add, remove }) => (
          <>
            <div className="space-y-1">
              <div className="grid sm:grid-cols-5 gap-4">
                {fields.map(({ key, name, ...restField }) => (
                  <>
                    <div className="w-full">
                      <Form.Item
                        {...restField}
                        name={[name, "qualification"]}
                        rules={[
                          { required: true, message: "Missing first name" },
                        ]}
                      >
                        <Input placeholder="Qualification" />
                      </Form.Item>
                    </div>

                    <div className="w-full">
                      <Form.Item
                        {...restField}
                        name={[name, "percentage"]}
                        rules={[
                          { required: true, message: "Missing first name" },
                        ]}
                      >
                        <Input placeholder="Percentage" />
                      </Form.Item>
                    </div>

                    <div className="w-full">
                      <Form.Item
                        {...restField}
                        name={[name, "institution"]}
                        rules={[
                          { required: true, message: "Missing first name" },
                        ]}
                      >
                        <Input placeholder="Institution" />
                      </Form.Item>
                    </div>
                    <div className="w-full">
                      <Form.Item
                        {...restField}
                        name={[name, "range"]}
                        rules={[
                          { required: true, message: "Missing first name" },
                        ]}
                      >
                        <Input placeholder="Year Range" />
                      </Form.Item>
                    </div>
                    <div className="col-md...........-2">
                      <MinusCircleOutlined
                        style={{ fontSize: 25, color: "tomato" }}
                        onClick={() => remove(name)}
                      />
                    </div>
                  </>
                ))}
              </div>
            </div>

            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add Education
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      <h5>
        <b>Skills</b>
      </h5>
      <hr className="pb-3" />
      <Form.List name="skills">
        {(fields, { add, remove }) => (
          <>
            <div className="speace-y-1">
              <div className="grid sm:grid-cols-3 gap-4">
                {fields.map(({ key, name, ...restField }) => (
                  <>
                    <div className="w-full">
                      <Form.Item
                        {...restField}
                        name={[name, "technology"]}
                        rules={[
                          { required: true, message: "Missing first name" },
                        ]}
                      >
                        <Input placeholder="Technology" />
                      </Form.Item>
                    </div>

                    <div className="w-full">
                      <Form.Item
                        {...restField}
                        name={[name, "rating"]}
                        rules={[
                          { required: true, message: "Missing first name" },
                        ]}
                      >
                        <Input placeholder="Rating" />
                      </Form.Item>
                    </div>

                    <div className="w-full">
                      <MinusCircleOutlined
                        style={{ fontSize: 25, color: "tomato" }}
                        onClick={() => remove(name)}
                      />
                    </div>
                  </>
                ))}
              </div>
            </div>

            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add Skill
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </div>
  );
}

export default SkillsEducation;
