import React, { useState, useEffect } from "react";
import { Menu, Layout, Space } from "antd";
import { Link } from "react-router-dom";

import { useWindowWidth } from "@react-hook/window-size";

import {
  UserSwitchOutlined,
  MoneyCollectOutlined,
  DeploymentUnitOutlined,
  NotificationOutlined,
  HddOutlined,
  TeamOutlined,
} from "@ant-design/icons";

const { SubMenu } = Menu;
const { Sider } = Layout;

const DirectorNav = () => {
  // context

  // state
  const [collapsed, setCollapsed] = useState(false);

  // hooks
  const onlyWidth = useWindowWidth();

  useEffect(() => {
    if (onlyWidth < 800) {
      setCollapsed(true);
    } else if (onlyWidth > 800) {
      setCollapsed(false);
    }
  }, [onlyWidth < 800]);

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={() => setCollapsed(!collapsed)}
    >
      <Menu
        defaultOpenKeys={["1", "201", "2", "3", , "4", "5", "6", "7", "8"]}
        mode="inline"
        inlineCollapsed={collapsed}
        style={{ paddingTop: "35px", fontSize: 18 }}
        className="font-Noto"
      >
        <SubMenu
          key="1"
          style={{ paddingTop: "25px" }}
          icon={
            <UserSwitchOutlined style={{ fontSize: 22, color: "#f01a9e" }} />
          }
          title="Profile"
        >
          <Menu.Item key="3">
            <Link to="/director/updateprofile">
              <a className="font-Noto font-semibold text-md">Edit Profile</a>
            </Link>
          </Menu.Item>
          <Menu.Item key="4">
            <Link to="/director/updatepassword">
              <a>Edit Password</a>
            </Link>
          </Menu.Item>
        </SubMenu>

        {/* ເລືີມ ສຳປະທານ */}
        <SubMenu
          key="201"
          icon={
            <MoneyCollectOutlined style={{ fontSize: 22, color: "#f01a9e" }} />
          }
          title="ສຳປະທານ (ລາຍຮັບ)"
          style={{ paddingTop: "10px" }}
        >
          <Menu.Item key="3">
            <Link to="/director/updateprofile">
              <a>ລາຍການສຳປະທານ</a>
            </Link>
          </Menu.Item>
        </SubMenu>
        {/* ສຸດ ສຳປະທານ */}
        {/* ເລືີມ ສຳປະທານລາຍຈ່າຍ */}
        <SubMenu
          key="2"
          icon={
            <MoneyCollectOutlined style={{ fontSize: 22, color: "#f01a9e" }} />
          }
          title="ສຳປະທານ (ລາຍຈ່າຍ)"
          style={{ paddingTop: "10px" }}
        >
          <Menu.Item key="3">
            <Link to="/director/updateprofile">
              <a>ອະນາໄມ</a>
            </Link>
          </Menu.Item>
          <Menu.Item key="3">
            <Link to="/director/updateprofile">
              <a>ຂີ້ເຫຍື່ອ</a>
            </Link>
          </Menu.Item>
        </SubMenu>
        {/* ສຸດ ສຳປະທານລາຍຈ່າຍ */}
        {/* --------------- */}
        {/* ເລືີມ ອົງການຈັດຕັ້ງ */}
        <SubMenu
          key="3"
          icon={
            <DeploymentUnitOutlined
              style={{ fontSize: 22, color: "#f01a9e" }}
            />
          }
          title="ອົງການຈັດຕັ້ງ"
          style={{ paddingTop: "10px" }}
        >
          <Menu.Item key="3">
            <Link to="/director/updateprofile">
              <a>ພັກ</a>
            </Link>
          </Menu.Item>
          <Menu.Item key="4">
            <Link to="/director/updatepassword">
              <a>ຊາວໜຸ່ມ</a>
            </Link>
          </Menu.Item>
          <Menu.Item key="4">
            <Link to="/director/updatepassword">
              <a>ກຳມະບານ</a>
            </Link>
          </Menu.Item>
          <Menu.Item key="4">
            <Link to="/director/updatepassword">
              <a>ແມ່ຍິງ</a>
            </Link>
          </Menu.Item>
        </SubMenu>
        {/* ສຸດ ອົງການຈັດຕັ້ງ */}
        {/* --------------- */}
        {/* ເລືີມ ການເວນຍາມ*/}
        <SubMenu
          key="4"
          icon={
            <NotificationOutlined style={{ fontSize: 22, color: "#f01a9e" }} />
          }
          title="ການເວນຍາມ"
          style={{ paddingTop: "10px" }}
        >
          <Menu.Item key="">
            <Link to="/director/updateprofile">
              <a>ຍາມຕົວຈິງ</a>
            </Link>
          </Menu.Item>
          <Menu.Item key="4">
            <Link to="/director/updatepassword">
              <a>ສະພາບການເວນຍາມ</a>
            </Link>
          </Menu.Item>
        </SubMenu>
        {/* ສຸດ ການເວນຍາມ */}

        {/* --------------- */}
        {/* ເລືີມ ລາຍການບໍລິການຕ່າງໆ*/}
        <SubMenu
          key="5"
          icon={<HddOutlined style={{ fontSize: 22, color: "#f01a9e" }} />}
          title="ລາຍການບໍລິການ"
          style={{ paddingTop: "10px" }}
        >
          <Menu.Item key="">
            <Link to="/director/updateprofile">
              <a>ລວມລາຍການບໍລິການຕ່າງໆ</a>
            </Link>
          </Menu.Item>
        </SubMenu>
        {/* ສຸດ ລາຍການບໍລິການຕ່າງໆ */}
        {/* --------------- */}
        {/* ເລືີມ ບຸກຄະລາກອນ*/}
        <SubMenu
          key="6"
          icon={<TeamOutlined style={{ fontSize: 22, color: "#f01a9e" }} />}
          title="ບຸກຄະລາກອນ"
          style={{ paddingTop: "10px" }}
        >
          <Menu.Item key="">
            <Link to="/director/updateprofile">
              <a>ພະນັກງານສົມບູນ</a>
            </Link>
          </Menu.Item>
          <Menu.Item key="">
            <Link to="/director/updateprofile">
              <a>ພະນັກງານຕາມສັນຍາ</a>
            </Link>
          </Menu.Item>
          <Menu.Item key="">
            <Link to="/director/updateprofile">
              <a>ພາລາກອນ</a>
            </Link>
          </Menu.Item>
        </SubMenu>
        {/* ສຸດ ບຸກຄະລາກອນ */}
        {/* --------------- */}
        {/* ເລືີມ ລາຍຮັບ*/}
        <SubMenu
          key="7"
          icon={
            <MoneyCollectOutlined style={{ fontSize: 22, color: "#f01a9e" }} />
          }
          title="ລາຍຮັບ"
          style={{ paddingTop: "10px" }}
        >
          <Menu.Item key="">
            <Link to="/director/updateprofile">
              <a>ຈຸດ ຫ້ອງຍາມ</a>
            </Link>
          </Menu.Item>
          <Menu.Item key="">
            <Link to="/director/updateprofile">
              <a>ຈຸດໂຮງໝໍພາກຂະຫຍາຍ</a>
            </Link>
          </Menu.Item>
          <Menu.Item key="">
            <Link to="/director/updateprofile">
              <a>ຈຸດ ກວດເຂດນອກ</a>
            </Link>
          </Menu.Item>
          <Menu.Item key="">
            <Link to="/director/updateprofile">
              <a>ຈຸດ ສູນສາຍແສງ</a>
            </Link>
          </Menu.Item>
        </SubMenu>
        {/* ສຸດ ລາຍຮັບ */}
        {/* --------------- */}
      </Menu>
    </Sider>
  );
};

export default DirectorNav;
