import React, { useState, useEffect } from "react";
import RoomCard from "../../../components/cards/RoomCard";
import { ListRoom } from "../../../functions/room";

import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function AllRoomLandingPage() {
  const [roomdata, setRoomData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { lang } = useSelector((state) => state.lang.lang);

  // console.log("lang=>", lang);
  // console.log("roomdata=>", roomdata);

  useEffect(() => {
    setLoading(true);
    ListRoom()
      .then((res) => {
        setLoading(false);
        setRoomData(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  // console.log("Rooms Data =>", roomdata);
  return (
    <>
      <div className="contrainer mx-auto  font-Noto pt-20 ">
        <Breadcrumb separator=">" style={{ fontSize: "14px" }}>
          <Breadcrumb.Item className="pl-20">
            <Link to="/">{lang === "en" ? "Home" : "ໜ້າຫຼັກ"}</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            {lang === "en" ? "All Bed" : "ຫ້ອງນອນທັງໝົດ"}
          </Breadcrumb.Item>

          <Breadcrumb.Item className="font-Noto text-pink-500 font-semibold"></Breadcrumb.Item>
        </Breadcrumb>
        <div className=" ml-20 pt-2 mr-20">
          <h1 className="p-4 bg-pink-500 text-center text-white text-xl font-semibold rounded-t-xl">
            {lang === "en" ? "All Bed" : "ຫ້ອງນອນທັງໝົດ"}
          </h1>
          <div className="grid grid-cols-1 xl:grid-cols-6  lg:grid-cols-4 gap-4 md:grid-cols-4 sm:grid-cols-1 xs:grid-cols-1  pt-0 pb-5 pr-3 pl-3">
            <RoomCard rooms={roomdata} lang={lang} Link={Link} />
          </div>
        </div>
      </div>
    </>
  );
}
