//rafce
import React, { useEffect, useState } from "react";
import { Breadcrumb } from "antd";
import { HomeOutlined } from "@ant-design/icons";
//function
import {
  ReadDepartment,
  UpdateDepartment,
} from "../../../functions/department";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Adminlayout from "../../../components/layout/AdminLayout";

import { Link } from "react-router-dom";

import DepartmentFormUpdate from "../../../components/forms/DepartmentFormUpdate";

const DepartmentUpdate = () => {
  const { user } = useSelector((state) => state.user);

  const navigate = useNavigate();
  const { id } = useParams();

  console.log(id);

  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    name_en: "",
    name: "",
    description: "",
    images: [],
  });

  useEffect(() => {
    ReadDepartment(id)
      .then((res) => {
        setValues({ ...values, ...res.data });
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    UpdateDepartment(user.token, id, values)
      .then((res) => {
        setLoading(false);
        navigate("/admin/Department/create");
        toast.success("Update " + res.data.name + " Success!!");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response);
      });
  };

  const handleChange = (e) => {
    console.log("name", values);
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  return (
    <Adminlayout>
      <div className="contrainer mx-auto p-5 pt-20 font-Noto">
        <Breadcrumb className="font-Phetsarath font-semibold">
          <Breadcrumb.Item href="/admin/department/create">
            <HomeOutlined style={{ fontSize: "23px", color: "#17cf73" }} />
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/admin/department/create">
            <span>ລາຍການພະແນກທັງໝົດ</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>ແກ້ໄຂພະແນກ</Breadcrumb.Item>
        </Breadcrumb>
        <div className="col-span-9 shadow-xl rounded px-6 bg-white pt-2 pb-7 mt-6 lg:mt-0 font-Phetsarath">
          <DepartmentFormUpdate
            onSubmit={onSubmit}
            handleChange={handleChange}
            values={values}
            setValues={setValues}
          />
        </div>
      </div>
    </Adminlayout>
  );
};

export default DepartmentUpdate;
