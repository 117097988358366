import React from "react";
import { Avatar, Tooltip } from "antd";
import { Link } from "react-router-dom";

export default function DayVisitCard({ lang, dayvisitdata }) {
  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-5 md:grid-cols-2 sm:grid-cols-1 xs:grid-cols-1  md:p-3 gap-x-1 xs:gap-y-3 sm:p-5 xs:p-5">
        {dayvisitdata.map((d) => (
          <div
            key={d._id}
            className="border shadow-lg hover:scale-105 duration-300 rounded-xl"
          >
            <Link to={`/landingpage/daydetail/${d._id}`}>
              <div className="px-10 py-10 bg-pink-100  font-Noto text-pink-500 text-center rounded-t-xl">
                {lang === "en" ? (
                  <a className="text-pink-700 text-3xl font-semibold">
                    {" "}
                    {d.name_en}
                  </a>
                ) : (
                  <a className="text-pink-700 text-5xl font-semibold">
                    {" "}
                    {d.name_la}
                  </a>
                )}
              </div>
            </Link>

            <div className=" font-Noto text-pink-500 text-center font-semibold">
              {lang === "en" ? (
                <a className="text-pink-700">OPD(Out Patient)</a>
              ) : (
                <a className="text-pink-700"> OPD(ຄົນເຈັບກວດເຂດນອກ)</a>
              )}
            </div>
            <div className="text-center font-Noto pt-2  text-pink-500 font-semibold text-lg">
              {lang === "en" ? (
                <a className="text-pink-700">Doctors</a>
              ) : (
                <a className="text-pink-700">ທ່ານໝໍ</a>
              )}

              <div className="grid grid-cols-6 text-center ">
                {d?.doctor.map((doc) => (
                  <div className="p-1 pb-3rounded-xl" key={doc.id}>
                    <Link to={`/landingpage/doctordetail/${doc._id}`}>
                      <div className="text-white font-semibold">
                        <Tooltip
                          placement="top"
                          title={
                            lang === "en" ? (
                              <a className="font-Noto">{doc.name_en}</a>
                            ) : (
                              <a className="font-Noto">{doc.name_la}</a>
                            )
                          }
                        >
                          <Avatar src={doc?.images[0]?.url} size={40} />
                        </Tooltip>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>

            <div className="text-center font-Noto font-semibold text-xl ">
              {lang === "en" ? (
                <a className="text-pink-700">Number</a>
              ) : (
                <a className="text-pink-700">ເບີຫ້ອງກວດ</a>
              )}
            </div>
            <div className="flex justify-center">
              {d?.room_number.map((r, i) => (
                <button
                  className="py-2 px-2 m-0.5 bg-pink-400 rounded shadow-xl text-white font-semibold"
                  key={i}
                >
                  {r}
                </button>
              ))}
            </div>

            <div className="text-center font-Noto font-semibold text-xl pt-3 ">
              {lang === "en" ? (
                <a className="text-pink-700">Dianosis</a>
              ) : (
                <a className="text-pink-700">ມະຕິພະຍາດ</a>
              )}
            </div>
            <div className="grid grid-cols-4 text-center ">
              {d?.service.map((d) => (
                <Link to={`/landingpage/servicedetail/${d._id}`} key={d._id}>
                  <div className="pb-3">
                    <Tooltip
                      className="font-Noto"
                      placement="top"
                      title={
                        lang === "en" ? (
                          <a className="font-Noto"> {d?.serviceName_en}</a>
                        ) : (
                          <a className="font-Noto"> {d?.serviceName_la}</a>
                        )
                      }
                    >
                      <Avatar src={d?.images[0]?.url} />
                    </Tooltip>
                  </div>
                </Link>
              ))}
            </div>

            <div className="text-center font-Noto font-semibold text-xl pt-3 ">
              {lang === "en" ? (
                <a className="text-pink-700">Time</a>
              ) : (
                <a className="text-pink-700">ໂມງກວດ</a>
              )}
            </div>
            <div className="flex justify-center pb-3">
              {d?.time_visit.map((r, i) => (
                <button
                  className="p-1 m-1 bg-pink-400 rounded-xl shadow-xl text-white font-semibold font-Noto"
                  key={i}
                >
                  {r}
                </button>
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
