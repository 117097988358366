import React, { useState, useEffect } from "react";
import { listMachin } from "../../../functions/machin";
import { useSelector } from "react-redux";
import MedicalDeviceCard from "../../../components/cards/MedicalDeviceCard";
import { Breadcrumb } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
export default function AllMedicalDeviceLandingPage() {
  const [medicaldevicedata, setMedicalDevicedata] = useState([]);
  const [loading, setLoading] = useState(false);
  const { lang } = useSelector((state) => state.lang.lang);

  useEffect(() => {
    setLoading(true);
    listMachin()
      .then((res) => {
        setLoading(false);
        setMedicalDevicedata(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);
  return (
    <div className="max-w-[1640px] m-auto px-4 py-12 pt-20 font-Noto">
      <Breadcrumb className="font-Phetsarath font-semibold">
        <Breadcrumb.Item>
          <Link to="/">
            <HomeOutlined style={{ fontSize: "23px", color: "#17cf73" }} />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/">
            <span>{lang === "en" ? "Home" : "ໜ້າຫຼັກ"}</span>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {lang === "en" ? "All Medical Devices" : "ເຄື່ອງມືການແພດທັງໝົດ"}
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="flex justify-around  font-Phetsarath font-semibold">
        <div className="text-pink-400  text-xl">
          {lang === "en" ? "All Medical Devices" : "ເຄື່ອງມືການແພດທັງໝົດ"}
        </div>
      </div>
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-6 pt-4">
        <MedicalDeviceCard medicaldevicedata={medicaldevicedata} lang={lang} />
      </div>
    </div>
  );
}
