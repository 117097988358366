import React, { useState, useEffect } from "react";
import DoctorCard from "../../../components/cards/DoctorCard";
import { ListDoctor } from "../../../functions/doctor";

export default function AllDoctorsLandingPage() {
  const [doctordata, setDoctorData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    setLoading(true);
    ListDoctor()
      .then((res) => {
        setLoading(false);
        setDoctorData(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  console.log("doctordata", doctordata);

  return (
    <>
      <div className="pt-20">
        <DoctorCard doctordata={doctordata} />
      </div>
    </>
  );
}
