import React from "react";
import { useParams } from "react-router-dom";
import UserFormFileUploadCreate from "../../components/forms/UserFormFileUploadCreate";
import Userlayout from "../../components/layout/UserLayout";

export default function UserProfileUpdate() {
  const params = useParams();

  return (
    <Userlayout>
      <div className="pt-20">
        <div className="form">
          <UserFormFileUploadCreate />
        </div>
      </div>
    </Userlayout>
  );
}
