import React, { useState, useEffect } from "react";

import { toast } from "react-toastify";
import { Table, Avatar, Image } from "antd";
import {
  createSliderimage,
  getSliderimage,
  removeSliderimage,
} from "../../../functions/sliderimage";

import {
  DeleteOutlined,
  CloudUploadOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

// custom date format
import moment from "moment/min/moment-with-locales";

import { Progress } from "antd";

import Adminlayout from "../../../components/layout/AdminLayout";

const SliderImage = () => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [sliderimages, setSliderImages] = useState([]);

  const [file, setFile] = useState("");
  const [filename, setFilename] = useState("");

  const [uploadPerscentage, setUploadPerscenTage] = useState(0);

  const authtoken = localStorage.token;

  useEffect(() => {
    loadSliderIamges(authtoken);
  }, []);

  const loadSliderIamges = (authtoken) => {
    getSliderimage(authtoken)
      .then((res) => {
        setSliderImages(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        toast.error(err);
        console.log(err);
      });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("data", name);

    // console.log(name)
    createSliderimage(formData, authtoken, setUploadPerscenTage)
      .then((res) => {
        setName("");
        setFilename("");
        setUploadPerscenTage(0);
        loadSliderIamges(authtoken);
        setLoading(false);
        toast.success("Create " + res.data.name + " Success");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response);
      });
  };

  const handleRemove = (id) => {
    if (window.confirm("Are you sure Delete!")) {
      setLoading(true);
      removeSliderimage(id, authtoken)
        .then((res) => {
          loadSliderIamges(authtoken);
          setLoading(false);
          toast.success("Remove " + res.data.name + " Success");
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.response);
        });
    }
  };

  const columns = [
    {
      title: "ຊື່ຮູບ",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "ວັນທີສ້າງຮູບ",
      render: (record) => (
        <>{moment(record.date).locale("lo").format("dddd D MMM YYYY")}</>
      ),
    },
    {
      title: "ຕົວຢ່າງຮູບ",
      render: (record) => (
        <Avatar
          src={
            <Image
              src={`${process.env.REACT_APP_API_Img}/images/sliderimages/${record.pic}`}
            />
          }
        />
      ),
    },
    {
      title: "Actions",
      render: (record) => (
        <>
          <span
            onClick={() => handleRemove(record._id)}
            className="cursor-pointer ml-10"
          >
            <DeleteOutlined
              style={{
                fontSize: "22px",
                color: "#b34969",
              }}
            />
          </span>
          <Link to={"/admin/updatesliderimage/" + record.slug}>
            <span>
              <EditOutlined
                style={{
                  fontSize: "22px",
                  color: "#b34969",
                  marginLeft: "5px",
                }}
              />
            </span>
          </Link>
        </>
      ),
    },
  ];

  return (
    <Adminlayout>
      <div className=" h-24" />
      <div className="container mx-auto  ">
        {loading ? <h4>Loading...</h4> : <h4>.</h4>}
        <div className="flex flex-wrap">
          <div className="md:w-2/5 w-full pb-6 md:pb-0 md:pr-6 pt-20 text-center bg-white shadow-2xl rounded">
            <p className="text-center font-Phetsarath font-semibold text-xl">
              ເພີ່ມຮູບສະໄລໂຊ
            </p>
            <form onSubmit={onSubmit} className="flex-grow">
              <div className="font-Phetsarath font-semibold flex flex-justify-between">
                <div className="p-2 w-32">
                  <label className="pl-3 text-xl">ຊື່ຮູບ</label>
                </div>
                <div className="pl-3 w-full">
                  <input
                    type="text"
                    className="border w-full border-gray-300 dark:border-gray-700 pl-1 py-1 shadow-lg rounded text-lg focus:outline-none bg-transparent focus:border-indigo-700 text-gray-800 dark:text-gray-100"
                    autoFocus
                    value={name}
                    required
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="custom-file pt-2">
                <CloudUploadOutlined
                  style={{
                    fontSize: "30px",
                    color: "green",
                  }}
                />
                <input
                  type="file"
                  className="pl-1 py-1 "
                  onChange={(e) => {
                    setFile(e.target.files[0]);
                    setFilename(e.target.files[0].name);
                  }}
                />
                <label htmlFor="customfile">{filename}</label>
                <Progress
                  strokeColor={{
                    "0%": "#108ee9",
                    "100%": "#87d068",
                  }}
                  percent={uploadPerscentage}
                />
              </div>

              <button className="inline-flex ml-1.5 shadow-lg justify-center pt-2 px-6 text-center bg-green-500 hover:bg-indigo-600 focus:outline-none rounded">
                <p className="text-center mb-2 font-semibold text-lg text-white font-['Phetsarath_OT']">
                  ບັນທຶກຂໍ້ມູນ
                </p>
              </button>
            </form>
          </div>
          <div className="md:w-3/5 w-full font-Phetsarath font-semibold shadow-2xl bg-white rounded-md">
            <Table columns={columns} dataSource={sliderimages} rowKey="slug" />
          </div>
        </div>
      </div>
    </Adminlayout>
  );
};

export default SliderImage;
