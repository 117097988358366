import React, { useState, useEffect } from "react";
import { Breadcrumb, Menu } from "antd";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { ListServices, ReadService } from "../../../functions/service";
import Footer from "../../../components/landingpages/Footer";

export default function ServiceDetailLandingPage() {
  const params = useParams();
  const { lang } = useSelector((state) => state.lang.lang);

  const [servicesingledata, setServicesingledata] = useState([]);
  const [servicedata, setServicedata] = useState([]);

  const [loading, setLoading] = useState(false);
  const {
    serviceName_la,
    serviceName_en,
    doctor,
    dayvisit,
    room_number,
    time_visit,
  } = servicesingledata;

  useEffect(() => {
    if (params?.id) setLoading(true);
    //Load Dianosis Single data
    ReadService(params?.id)
      .then((res) => {
        setServicesingledata(res.data);
        setLoading(false);
        // console.log(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response);
      });

    //Load Dianosis All data
    setLoading(true);
    ListServices()
      .then((res) => {
        setServicedata(res.data);
        setLoading(false);
        // console.log(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response);
      });
  }, [params?.id]);

  // console.log("DoctorData=>", doctor);
  // console.log("DayData=>", dayvisit);

  const dianosisfilter = servicedata?.filter(
    (item) => item._id !== servicesingledata._id
  );


  const menu = (
    <Menu>
      {dianosisfilter?.map((diano) => (
        <Menu.Item className="font-Noto">
          <Link to={`/landingpage/servicedetail/${diano._id}`} key={diano.id}>
            {lang === "en" ? (
              <a> {diano.serviceName_en}</a>
            ) : (
              <a> {diano.serviceName_la}</a>
            )}
          </Link>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      <div className="contrainer mx-auto pt-20 p-10">
        <div
          className="px-20 py-20  bg-pink-400 text-center
    
       font-Noto text-6xl font-semibold text-white rounded-t-xl"
        >
          {lang === "en" ? (
            <a className="text-white "> {serviceName_en}</a>
          ) : (
            <a className="text-white"> {serviceName_la}</a>
          )}
        </div>

        <div className=" text-xl pl-20 font-Noto font-semibold ">
          <Breadcrumb style={{ fontSize: "16px" }}>
            <Breadcrumb.Item className="font-Noto text-lg font-semibold ">
              <Link to="/">{lang === "en" ? <a>Home</a> : <a>ໜ້າຫຼັກ</a>}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item overlay={menu}>
              {lang === "en" ? <a>Other Dianosis</a> : <a>ພະຍາດອື່ນໆ</a>}
            </Breadcrumb.Item>
            <Breadcrumb.Item className="font-Noto text-lg font-semibold text-pink-600">
              {lang === "en" ? (
                <a>{serviceName_en}</a>
              ) : (
                <a>{serviceName_la}</a>
              )}
            </Breadcrumb.Item>

            <Breadcrumb.Item className="font-Noto text-pink-500 font-semibold"></Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="header-gradient">
          {lang === "en" ? (
            <a className="text-white"> Doctors</a>
          ) : (
            <a className="text-white"> ບັນດາທ່ານໝໍທີ່ກວດ</a>
          )}
        </div>

        <div className="w-auto mx-w-none  flex justify-center flex-wrap  border-2 border-pink-400 ">
          {doctor?.map((item) => {
            return (
              <div className="team-profile pb-5" key={item._id}>
                <Link to={`/landingpage/doctordetail/${item?._id}`}>
                  <img
                    src={item?.images[0]?.url}
                    alt={item?._id}
                    className="team-img"
                  />
                </Link>

                <div className="px-2 py-4 font-Noto mx-0 my-0  ">
                  <div className="">
                    <p className="font-bold text-center text-pink-600">
                      {lang === "en" ? (
                        <a> {item?.name_en}</a>
                      ) : (
                        <a> {item?.name_la}</a>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <hr className="pb-5" />
        <div className="header-gradient">
          {lang === "en" ? (
            <a className="text-white">Day</a>
          ) : (
            <a className="text-white"> ວັນກວດ</a>
          )}
        </div>
        <div className="pt-2 flex justify-center font-Noto font-semibold text-2xl gap-4  border-2 border-pink-400 pb-3">
          {dayvisit?.map((d) => {
            return (
              <Link to={`/landingpage/daydetail/${d._id}`}>
                <div className="text-center text-white  bg-pink-400 px-5 py-5 rounded-xl shadow-xl hover:bg-pink-500 hover:scale-105 duration-300">
                  {lang === "en" ? (
                    <a className="text-white"> {d.name_en}</a>
                  ) : (
                    <a className="text-white"> {d.name_la}</a>
                  )}
                </div>
              </Link>
            );
          })}
        </div>
        <hr className="pb-5" />
        <div className="header-gradient">
          {lang === "en" ? (
            <a className="text-white">Number</a>
          ) : (
            <a className="text-white">ຫ້ອງກວດ</a>
          )}
        </div>
        <div className="pt-2 flex justify-center font-Noto font-semibold text-2xl gap-4 border-2 border-pink-400 pb-3">
          {room_number?.map((r, i) => {
            return (
              <div
                className="text-center text-white  bg-pink-400 px-5 py-5 rounded-xl shadow-xl hover:bg-pink-500 hover:scale-105 duration-300"
                key={i}
              >
                {`NO: ${r}`}
              </div>
            );
          })}
        </div>
        <hr className="pb-5" />
        <div className="header-gradient">
          {lang === "en" ? (
            <a className="text-white">Time</a>
          ) : (
            <a className="text-white">ເວລາກວດ</a>
          )}
        </div>
        <div className="pt-2 flex justify-center font-Noto font-semibold text-2xl gap-4  border-2 border-pink-400 pb-3">
          {time_visit?.map((t, i) => {
            return (
              <div
                className="text-center text-white  bg-pink-400 px-5 py-5 rounded-xl shadow-xl hover:bg-pink-500 hover:scale-105 duration-300"
                key={i}
              >
                {t}
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </>
  );
}
