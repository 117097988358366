import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Adminlayout from "../../../components/layout/AdminLayout";

import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

import { ReadRoom, UpdateRoom } from "../../../functions/room";
import RoomFormUpdate from "../../../components/forms/RoomFormUpdate";

const RoomUpdate = () => {
  const { user } = useSelector((state) => state.user);
  console.log(user);
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [values, setValues] = useState({
    roomName_la: "",
    roomName_en: "",
    price: "",
    nursePrice: "",
    description_la: "",
    description_en: "",
    images: [],
  });

  useEffect(() => {
    ReadRoom(id)
      .then((res) => {
        // console.log("single Doctor", dc);
        // 1 load single Doctor
        setValues({ ...values, ...res.data });
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleChange = (e) => {
    console.log("name", values);
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    UpdateRoom(user.token, values, id)
      .then((res) => {
        setLoading(false);
        toast.success("Create " + res.data.roomName_la + " Success");
        navigate("/admin/room/allroom");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response);
      });
  };

  return (
    <Adminlayout>
      <div className="contrainer mx-auto p-5 pt-20 font-Noto">
        <div className="col-span-9 shadow-xl rounded px-6 bg-white pt-5 pb-7 mt-6 lg:mt-0 font-Phetsarath">
          <RoomFormUpdate
            onSubmit={onSubmit}
            handleChange={handleChange}
            values={values}
            setValues={setValues}
          />
        </div>
      </div>
    </Adminlayout>
  );
};

export default RoomUpdate;
