import React from "react";
import Adminlayout from "../../../components/layout/AdminLayout";
// Antd
import { LockOutlined, UserOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";

import { Col, Divider, Row } from "antd";

const style = {
  background: "#0092ff",
  padding: "8px 0",
};
export default function UserAdminUpdate() {
  // const onFinish = (e) => {
  //   console.log("onSubmit=>", e);
  // };

  return (
    <Adminlayout>
      <Divider orientation="left">Responsive</Divider>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={3}>
          <div style={style}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae
            quisquam dolorem autem, voluptatibus provident est nisi totam
            repellat? Enim beatae nobis excepturi aliquam cumque illo fuga
            voluptatem natus, cum adipisci?
          </div>
        </Col>
        <Col className="gutter-row" span={16}>
          <div style={style}>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolore
            omnis quibusdam at dolorum quos et aliquid nesciunt repellat quaerat
            placeat aliquam, mollitia vel eveniet dicta accusantium velit
            voluptate molestias numquam?
          </div>
        </Col>
        <Col className="gutter-row" span={3}>
          <div style={style}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo illum
            accusamus voluptatibus, ipsa id reiciendis, est consequuntur ipsam
            exercitationem incidunt quam ducimus numquam fugit nulla? Aliquid at
            asperiores a maiores!
          </div>
        </Col>
      </Row>
    </Adminlayout>
  );
}
