import React from "react";

const Footer = () => {
  return (
    <>
      <div className="bg-gray-600 w-full lg:mt-60 mt-10 px-4 lg:px-0">
        <div className="mx-auto container">
          <div className="lg:flex pt-8 lg:pt-16 border-b border-white pb-8">
            <div className="ml-4 lg:ml-0 lg:w-1/4 pb-8 lg:pb-8 ">
              <img src="./assets/logo.png" alt="logo" width="160" />
              {/* <img src={logo} alt="logo" width="160" /> */}
            </div>
            <div className="ml-4 mt-2 lg:mt-0 lg:ml-4 lg:w-1/2">
              <div className="grid gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4  f-f-l text-white">
                <div className>
                  <div className="text-xl font-bold pb-2 lg:pb-5 font-lato cursor-pointer font-Noto text-white">
                    ລາຍການການບໍລິການຕ່າງໆ
                  </div>
                  <div className="text-base font-light font-Noto text-white">
                    <div className="pb-2 cursor-pointer ">ກວດກະດູກ</div>
                    <div className="pb-2 cursor-pointer ">
                      ກວດ ແລະ ຝອກໄຂ່ຫຼັງທຽມ
                    </div>
                    <div className="pb-2 cursor-pointer ">
                      ກວດສະໝອງ ແລະ ກະດູກສັນຫຼັງ
                    </div>
                    <div className="cursor-pointer">
                      ກວດ ແລະ ປິ່ນປົວລະບົບປະສາດ
                    </div>
                  </div>
                </div>
                <div className>
                  <div className="text-xl font-bold pb-2 lg:pb-5 font-lato cursor-pointer font-Noto text-white">
                    ການຜ່າຕັດ
                  </div>
                  <div className="text-base font-light font-Noto text-white">
                    <div className="pb-2 cursor-pointer">
                      ຜ່າຕັດສະໝອງ ແລະ ກະດູກສັນຫຼັງ
                    </div>
                    <div className="pb-2 cursor-pointer">ຜ່າຕັດໃສ່ເຫຼັກ</div>
                    <div className="pb-2 cursor-pointer">ຜ່າຕັດຜ່ານທໍ່</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mx-auto w-11/12 pt-4 lg:pt-0 lg:w-1/4 pl-4 xl:pl-0">
              <div className="flex w-full">
                <div className="text-2xl font-bold font-lato uppercase text-white">
                  Send E-mail
                </div>
              </div>
              <div className="flex justify-center items-center rounded relative w-full mt-2 lg:mt-6">
                <input
                  type="email"
                  required
                  placeholder="Email Address"
                  id="email"
                  aria-label="email"
                  className="placeholder-white h-8 lg:h-12 pl-4 rounded-lg border border-white text-white bg-transparent focus:outline-none w-full text-base"
                />
                <button
                  type="submit"
                  className="flex items-center justify-center absolute right-0 inset-y-0 h-full focus:outline-none bg-white text-xs w-1/5 rounded px-2 sm:px-2 py-2 lg:py-4"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={26}
                    height={16}
                    viewBox="0 0 26 16"
                    fill="none"
                  >
                    <path
                      d="M0.785156 7.85693H24.7852"
                      stroke="#334048"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M17.9282 14.7141L24.7854 7.85693"
                      stroke="#334048"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M17.9282 1L24.7854 7.85714"
                      stroke="#334048"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="lg:ml-0 py-2 lg:py-8 w-full flex lg:flex-row flex-col-reverse justify-between items-center font-lato text-white text-base">
            <div className="lg:my-0 my-8">
              Copyright @2022 All rights reserved
            </div>
            <div className="mt-4 lg:mt-0 flex items-center">
              <div className="pr-5 cursor-pointer ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={13}
                  height={20}
                  viewBox="0 0 13 20"
                  fill="none"
                >
                  <path
                    d="M1 8.46387V12.4639H4V19.4639H8V12.4639H11L12 8.46387H8V6.46387C8 6.19865 8.10536 5.9443 8.29289 5.75676C8.48043 5.56922 8.73478 5.46387 9 5.46387H12V1.46387H9C7.67392 1.46387 6.40215 1.99065 5.46447 2.92833C4.52678 3.86602 4 5.13778 4 6.46387V8.46387H1Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className="pr-5 cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={25}
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <path
                    d="M16 4.46387H8C5.79086 4.46387 4 6.25473 4 8.46387V16.4639C4 18.673 5.79086 20.4639 8 20.4639H16C18.2091 20.4639 20 18.673 20 16.4639V8.46387C20 6.25473 18.2091 4.46387 16 4.46387Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 15.4639C13.6569 15.4639 15 14.1207 15 12.4639C15 10.807 13.6569 9.46387 12 9.46387C10.3431 9.46387 9 10.807 9 12.4639C9 14.1207 10.3431 15.4639 12 15.4639Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.5 7.96387V7.96487"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className="cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={25}
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <path
                    d="M22 4.47388C21 4.96388 20.02 5.16288 19 5.46388C17.879 4.19888 16.217 4.12888 14.62 4.72688C13.023 5.32488 11.977 6.78688 12 8.46388V9.46388C8.755 9.54688 5.865 8.06888 4 5.46388C4 5.46388 -0.182 12.8969 8 16.4639C6.128 17.7109 4.261 18.5519 2 18.4639C5.308 20.2669 8.913 20.8869 12.034 19.9809C15.614 18.9409 18.556 16.2579 19.685 12.2389C20.0218 11.0166 20.189 9.75374 20.182 8.48588C20.18 8.23688 21.692 5.71388 22 4.47288V4.47388Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
