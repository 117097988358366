import axios from "axios";

export const CreateDay = async (authtoken, value) =>
  await axios.post(`${process.env.REACT_APP_API}/day`, value, {
    headers: {
      authtoken,
    },
  });

export const ListDay = async () =>
  await axios.get(`${process.env.REACT_APP_API}/day`);
export const ListDayOnly = async () =>
  await axios.get(`${process.env.REACT_APP_API}/dayonly`);

export const ReadDay = async (id) =>
  await axios.get(`${process.env.REACT_APP_API}/day/${id}`);

export const DeleteDay = async (authtoken, id) =>
  await axios.delete(`${process.env.REACT_APP_API}/day/${id}`, {
    headers: {
      authtoken,
    },
  });

export const UpdateDay = async (authtoken, value, id) =>
  await axios.put(`${process.env.REACT_APP_API}/day/${id}`, value, {
    headers: {
      authtoken,
    },
  });
