import axios from "axios";

export const ReadDoctor = async (id) =>
  await axios.get(`${process.env.REACT_APP_API}/doctor/${id}`);

export const ListDoctor = async () =>
  await axios.get(`${process.env.REACT_APP_API}/doctor`);

export const CreateDoctor = async (authtoken, value) =>
  await axios.post(`${process.env.REACT_APP_API}/doctor`, value, {
    headers: {
      authtoken,
    },
  });
export const uploadDoctor1 = async (formData, id) =>
  await axios.put(`${process.env.REACT_APP_API}/doctorupload/${id}`, formData);

export const DoctorUpdateProfile = async (authtoken, value, id) =>
  await axios.put(`${process.env.REACT_APP_API}/doctor/${id}`, value, {
    headers: {
      authtoken,
    },
  });
export const DeleteDoctor = async (authtoken, id) =>
  await axios.delete(`${process.env.REACT_APP_API}/doctor/${id}`, {
    headers: {
      authtoken,
    },
  });

export const ListDoctorByCount = async (count) =>
  await axios.get(`${process.env.REACT_APP_API}/doctorByCount/${count}`);
