import { Routes, Route } from "react-router-dom";

// Landing Pages

import Allmachin from "./pages/landingpages/Allmachin";
import Medicaldevicedetail from "./pages/landingpages/medicaldevicedetail";

import TopNav from "./components/TopNav";
import AdminPage from "./pages/admin";

// import ImagesSlider from "./pages/admin/sliderimage";
import UpdateSliderimage from "./pages/admin/sliderimage/UpdateSliderimage";

//pages admin
import AllUser from "./pages/admin/user/alluser";
import Login from "./pages/auth/login";

import SliderImage from "./pages/admin/sliderimage/sliderImage";
//Service Pages Admin

import Register from "./pages/auth/Register";

// redux
import { useDispatch } from "react-redux";
import { auth } from "./redux/userSlice";

//function
import { currentUser } from "./functions/auth";

//Toattify
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Protect Route
import UserRoute from "./components/routes/UserRoute";
import AdminRoute from "./components/routes/AdminRoute";
import DirectorRoute from "./components/routes/DirectorRoute";

//Director

import DepartmentCreate from "./pages/admin/department/DepartmentCreate";
import DepartmentUpdate from "./pages/admin/department/DepartmentUpdate";

// import Doctor from "./components/landingpages/Doctors";

//Start Admin Dcotor pages
import {
  DoctorCreate,
  AllDoctor,
  DoctorUpdate,
  DoctorUploadImage,
} from "./pages/admin/doctor";
// End Admin Doctor pages

//--------------Start Admin Profile------------------//

import { UpdateAdminProfile } from "./pages/admin/profile";

//--------------End Admin Profile-------------------//

//---------------------------------//
//Start Admin Machin pages
import { Machin, MachinUpdate } from "./pages/admin/machin";
// End Admin Machin pages
//--------------------------------//
import Userlayout from "./components/layout/UserLayout";

//Start Director  pages
import { DirectorPage, UpdateDirectorProfile } from "./pages/director";

//Start User  pages
import { UpdateUserProfile, UserProfileUpdate } from "./pages/user/index";

//Landing Pages
import Departmentdetail from "./pages/landingpages/Departmentdetail";

import Profile from "./pages/admin/doctor/Profile";
import { DayCreate, DayUpdate } from "./pages/admin/dayvisit";
import { ServiceCreate, Services, ServiceUpdate } from "./pages/admin/services";
import { AllRooms, RoomCreate, RoomUpdate } from "./pages/admin/rooms";
import UserAdminUpdate from "./pages/admin/user/UserAdminUpdate";
import AllDepartment from "./pages/admin/department/AllDepartment";

import {
  AllDayVisit,
  AllDoctorsLandingPage,
  AllMedicalDeviceLandingPage,
  AllRoomLandingPage,
  DayDetailLandingpage,
  DepartmentDetailLandingPages,
  DetailRoomLandingPage,
  DoctorDetailLandingPage,
  HomeLandingPages,
  ServiceDetailLandingPage,
} from "./pages/landingpages";

import { la } from "./redux/lang";
import UserFormFileUploadCreate from "./components/forms/UserFormFileUploadCreate";

// End Director  pages

const PageNotFound = () => {
  return (
    <div className="flex justify-center text-center vh-100">
      404 | Page not found
    </div>
  );
};
function App() {
  const dispatch = useDispatch();
  const idtoken = localStorage.token;
  const lng = localStorage.lang;
  // console.log(idtoken);

  dispatch(
    la({
      lang: lng,
    })
  );

  if (idtoken) {
    currentUser(idtoken)
      .then((res) => {
        //code
        // console.log("App response", res.data._id);

        dispatch(
          auth({
            token: idtoken,
            username: res.data.username,
            _id: res.data._id,
            role: res.data.role,
          })
        );

        // localStorage.setItem("user", JSON.stringify(res.data));
      })
      .catch((err) => {
        //err
        console.log(err);
      });
  }

  return (
    <>
      <TopNav />
      <ToastContainer />
      <Routes>
        {/* Public path */}
        <Route path="/userimage" element={<UserFormFileUploadCreate />} />
        <Route path="/" element={<HomeLandingPages />} />
        <Route path="/allmachin" element={<Allmachin />} />
        <Route path="/profile/:id" element={<Profile />} />
        <Route
          path="/medicaldevicedetail/:id"
          element={<Medicaldevicedetail />}
        />

        {/* --------------Start--Landing Pages------------------- */}

        <Route path="landingpage">
          <Route path="alldoctor" element={<AllDoctorsLandingPage />} />
          <Route path="alldayvisit" element={<AllDayVisit />} />
          <Route
            path="doctordetail/:id"
            element={<DoctorDetailLandingPage />}
          />
          <Route
            path="servicedetail/:id"
            element={<ServiceDetailLandingPage />}
          />
          <Route
            path="allmedicaldevices"
            element={<AllMedicalDeviceLandingPage />}
          />
          <Route
            path="departmentdetail/:id"
            element={<DepartmentDetailLandingPages />}
          />
          <Route path="daydetail/:id" element={<DayDetailLandingpage />} />
          <Route path="rooms" element={<AllRoomLandingPage />} />
          <Route path="roomdetail/:id" element={<DetailRoomLandingPage />} />
        </Route>

        {/* --------------End--Landing Pages------------------- */}

        {/* Start Landing Pages */}

        <Route path="/alldepartment" element={<AllDepartment />} />
        {/* Stop Landing Pages */}
        <Route path="/login" element={<Login />} />

        {/* ---------------Start Admin Pages--------------------- */}
        <Route path="admin">
          <Route
            path="index"
            element={
              <AdminRoute>
                <AdminPage />
              </AdminRoute>
            }
          />
          <Route
            path="adminupdate"
            element={
              <AdminRoute>
                <UserAdminUpdate />
              </AdminRoute>
            }
          />
          <Route
            path="alluser"
            element={
              <AdminRoute>
                <AllUser />
              </AdminRoute>
            }
          />
          <Route
            path="updateprofile/:id"
            element={
              <AdminRoute>
                <UpdateAdminProfile />
              </AdminRoute>
            }
          />
          <Route
            path="service/allservice"
            element={
              <AdminRoute>
                <Services />
              </AdminRoute>
            }
          />
          <Route
            path="service/create"
            element={
              <AdminRoute>
                <ServiceCreate />
              </AdminRoute>
            }
          />
          <Route
            path="service/update/:id"
            element={
              <AdminRoute>
                <ServiceUpdate />
              </AdminRoute>
            }
          />
          <Route
            path="room/allroom"
            element={
              <AdminRoute>
                <AllRooms />
              </AdminRoute>
            }
          />
          <Route
            path="room/create"
            element={
              <AdminRoute>
                <RoomCreate />
              </AdminRoute>
            }
          />
          <Route
            path="room/update/:id"
            element={
              <AdminRoute>
                <RoomUpdate />
              </AdminRoute>
            }
          />
          <Route
            path="doctor/create"
            element={
              <AdminRoute>
                <DoctorCreate />
              </AdminRoute>
            }
          />
          <Route
            path="doctor/:id"
            element={
              <AdminRoute>
                <DoctorUpdate />
              </AdminRoute>
            }
          />

          <Route
            path="alldoctor"
            element={
              <AdminRoute>
                <AllDoctor />
              </AdminRoute>
            }
          />
          <Route
            path="doctorupload/:id"
            element={
              <AdminRoute>
                <DoctorUploadImage />
              </AdminRoute>
            }
          />
          <Route
            path="machin"
            element={
              <AdminRoute>
                <Machin />
              </AdminRoute>
            }
          />
          <Route
            path="machinupdate/:id"
            element={
              <AdminRoute>
                <MachinUpdate />
              </AdminRoute>
            }
          />
          <Route
            path="day/create"
            element={
              <AdminRoute>
                <DayCreate />
              </AdminRoute>
            }
          />
          <Route
            path="day/update/:id"
            element={
              <AdminRoute>
                <DayUpdate />
              </AdminRoute>
            }
          />
          <Route
            path="Department/create"
            element={
              <AdminRoute>
                <DepartmentCreate />
              </AdminRoute>
            }
          />
          <Route
            path="Department/update/:id"
            element={
              <AdminRoute>
                <DepartmentUpdate />
              </AdminRoute>
            }
          />
          <Route
            path="updatesliderimage/:slug"
            element={
              <AdminRoute>
                <UpdateSliderimage />
              </AdminRoute>
            }
          />
          <Route
            path="slider"
            element={
              <AdminRoute>
                <SliderImage />
              </AdminRoute>
            }
          />
        </Route>
        {/* ---------------End Admin Pages--------------------- */}
        {/* Start User Layout */}

        <Route path="user">
          <Route
            path="index"
            element={
              <UserRoute>
                <Userlayout />
              </UserRoute>
            }
          />
          <Route
            path="userprofileupdate/:id"
            element={
              <UserRoute>
                <UserProfileUpdate />
              </UserRoute>
            }
          />
        </Route>
        <Route
          path="/director/index"
          element={
            <DirectorRoute>
              <DirectorPage />
            </DirectorRoute>
          }
        />
        <Route
          path="/director/updateprofile/:id"
          element={<UpdateDirectorProfile />}
        />

        {/* <Route path="/alldoctor" element={<Doctor />} /> */}

        <Route
          path="/register"
          element={
            <AdminRoute>
              <Register />
            </AdminRoute>
          }
        />

        <Route
          path="/director"
          element={
            <DirectorRoute>
              <DirectorPage />
            </DirectorRoute>
          }
        />

        <Route path="*" element={<PageNotFound />} replace />
      </Routes>
    </>
  );
}

export default App;
