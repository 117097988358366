import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Breadcrumb } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { listDepartments } from "../../../functions/department";
const AllDepartment = () => {
  const [departmentalldata, setDepartmentAlldata] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadData(4);
  }, []);

  const loadData = (count) => {
    setLoading(true);
    listDepartments()
      .then((res) => {
        setLoading(false);
        setDepartmentAlldata(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <div className="max-w-[1640px] m-auto px-4 py-12 pt-20 font-Noto">
      <div className="p-5 rounded-t-lg bg-slate-200">
        <Breadcrumb className="font-Phetsarath font-semibold">
          <Breadcrumb.Item>
            <Link to="/">
              <HomeOutlined style={{ fontSize: "23px", color: "#17cf73" }} />
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item className="text-lg  text-gray-500">
            ພະແນກການທັງໝົດ
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 md:grid-cols-2 pt-4 font-Phetsarath">
        {departmentalldata.map((item) => (
          <div
            key={item._id}
            className="border shadow-lg rounded-lg hover:scale-105 duration-300"
          >
            <img
              src={item.images[0].url}
              alt={item.name}
              className="w-full h-[200px] object-fit rounded-t-lg"
            />
            <div className="px-2 py-4 text-center">
              <p className="font-bold">{item.name}</p>
              <Link to={`/landingpage/departmentdetail/${item._id}`}>
                <button
                  className="text-white bg-gradient-to-r from-pink-400 via-pink-500 to-pink-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-pink-300 dark:focus:ring-pink-800 shadow-lg shadow-pink-500/50 dark:shadow-lg dark:shadow-pink-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 font-Phetsarath"
                  sx={{ marginLeft: "auto" }}
                >
                  ລາຍລະອຽດ
                </button>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllDepartment;
