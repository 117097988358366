import React from "react";
import { Link } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";

export default function MedicalDevices({ lang, machin }) {
  return (
    <div className="max-w-[1500px] m-auto  font-Noto border-solid border-2 border-pink-300 rounded-xl ">
      <div
        className="text-center 
  bg-pink-500 font-Noto text-xl font-semibold text-white rounded-t-xl"
      >
        {lang === "en" ? (
          <a className="text-white ">Medical Devices</a>
        ) : (
          <a className="text-white">ເຄື່ອງມືການແພດ</a>
        )}
      </div>
      <div className="flex justify-end font-Noto font-semibold md:flex-rows">
        <Link to="/allmachin">
          <button className="pr-3">
            <div className="flex text-center">
              <a className="">
                <EyeOutlined
                  style={{
                    fontSize: "25px",
                    pendingright: "3px",
                    color: "#ed1191",
                  }}
                />
              </a>

              <p className="pl-1 pt-0.5">
                {lang === "en" ? (
                  <a className="text-pink-500">All See</a>
                ) : (
                  <a className="text-pink-500">ເບິ່ງທັງໝົດ</a>
                )}
              </p>
            </div>
          </button>
        </Link>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 md:grid-cols-4 sm:grid-cols-2 pt-0 pb-5 pr-3 pl-3 font-Noto">
        {machin?.map((item) => (
          <div
            key={item?._id}
            className="border shadow-lg rounded-lg hover:scale-105 duration-300"
          >
            <img
              src={item?.images[0]?.url}
              alt={item?.name}
              className="w-full h-[200px] object-cover rounded-t-lg"
            />
            <div className=" px-2 py-4 text-center">
              <p className="font-bold">{item?.name}</p>
              <Link to={`/medicaldevicedetail/${item?._id}`}>
                <button className="pink-btn" sx={{ marginLeft: "auto" }}>
                  {lang === "en" ? (
                    <a className="text-white">Detail</a>
                  ) : (
                    <a className="text-white">ລາຍລະອຽດ</a>
                  )}
                </button>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
