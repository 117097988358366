import React, { useState, useEffect } from "react";

import { toast } from "react-toastify";
import {
  DeleteImages,
  ReadUser,
  UpdateProfileimage,
} from "../../functions/users";
import { useSelector } from "react-redux";

export default function UserFormFileUploadCreate() {
  const [file, setFile] = useState("");
  const [fileold, setFileold] = useState("");
  const [filename, setFilename] = useState("Choose File");
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.user);

  console.log("UserId=>", user);
  console.log("FileName=>", filename);
  console.log("OldFile=>", fileold);
  console.log("file=>", file);
  const id = user._id;

  useEffect(() => {
    ReadUser(user._id, user.token)
      .then((res) => {
        console.log("UserSingleDATA", res.data);
        setFileold(res.data.pic);
        setFilename(res.data.pic);
      })
      .catch((err) => {
        toast.error(err);
        console.log(err);
      });
  }, []);

  // const loadSingleUser = (id, authtoken) => {
  //   ReadUser(id, authtoken)
  //     .then((res) => {
  //       console.log("res", res.data);
  //       setFileold(res.data.pic);
  //       setFilename(res.data.pic);
  //     })
  //     .catch((err) => {
  //       toast.error(err);
  //       console.log(err);
  //     });
  // };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("filename", filename);
    formData.append("fileold", fileold);

    // console.log(name)
    UpdateProfileimage(user._id, formData)
      .then((res) => {
        setFilename("");
        setLoading(false);
        toast.success("Create " + res.data.name + " Success");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response);
      });
  };

  return (
    <>
      <form
        onSubmit={onSubmit}
        className="contrainer mx-auto w-[700px] shadow-xl p-5"
      >
        <h3 className="text-lg capitalize mb-4 font-semibold text-green-500 pt-20 font-Noto text-center">
          ເພີ່ມຮູບ
        </h3>
        <div className="space-y-4">
          <div className="grid sm:grid-cols-2 gap-4">
            {/* <div>
              <input
                type="text"
                className="input-box w-full px-2 py-2 rounded-lg outline-none bg-slate-100"
                value={name}
                required
                onChange={(e) => setName(e.target.value)}
              />
            </div> */}
            <div>
              <input
                type="file"
                className="input-box w-full px-2 py-2 rounded-lg outline-none bg-slate-100"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                  setFilename(e.target.files[0].name);
                }}
              />
              <label htmlFor="customfile">{filename}</label>
            </div>
          </div>
        </div>
        <div className="text-center pt-5">
          <button className="inline-flex ml-1.5 shadow-lg justify-center pt-2 px-6 text-center bg-green-500 hover:bg-indigo-600 focus:outline-none rounded">
            <p className="text-center mb-2 font-semibold text-lg text-white font-['Phetsarath_OT']">
              ບັນທຶກຂໍ້ມູນ
            </p>
          </button>
        </div>
      </form>
    </>
  );
}
