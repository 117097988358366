import React, { useState, useEffect } from "react";

import FileUpload from "./FileUpload";

const RoomFormUpdate = ({ onSubmit, handleChange, values, setValues }) => {
  const {
    roomName_la,
    roomName_en,
    price,
    nursePrice,
    description_la,
    description_en,
    images,
  } = values;

  const [loading, setLoading] = useState(false);

  return (
    <form onSubmit={onSubmit}>
      <h3 className="text-lg capitalize mb-4 font-semibold text-green-500">
        New Update
      </h3>

      <hr className="p-3" />
      <div className="space-y-4">
        <div className="grid sm:grid-cols-2 gap-4">
          <div>
            <input
              type="text"
              placeholder="ຊື່ເປັນພາສາລາວ"
              className="input-box w-full px-2 py-2 rounded-lg outline-none bg-slate-100"
              name="roomName_la"
              value={roomName_la}
              required
              onChange={handleChange}
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="ຊື່ເປັນພາສາອັງກິດ"
              className="input-box w-full px-2 py-2 rounded-lg outline-none bg-slate-100"
              name="roomName_en"
              value={roomName_en}
              required
              onChange={handleChange}
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="ລາຄາຄ່າຫ້ອງ"
              className="input-box w-full px-2 py-2 rounded-lg outline-none bg-slate-100"
              name="price"
              value={price}
              required
              onChange={handleChange}
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="ຄ່າບໍລິການພະຍາບານ"
              className="input-box w-full px-2 py-2 rounded-lg outline-none bg-slate-100"
              name="nursePrice"
              value={nursePrice}
              required
              onChange={handleChange}
            />
          </div>

          <div>
            <textarea
              id="message"
              rows="4"
              name="description_la"
              value={description_la}
              onChange={handleChange}
              className="input-box w-full px-2 py-2 rounded-lg outline-none bg-slate-100"
              placeholder="ຄຳອະທິບາຍເປັນພາສາລາວ"
            ></textarea>
          </div>
          <div>
            <textarea
              id="message"
              rows="4"
              name="description_en"
              value={description_en}
              onChange={handleChange}
              className="input-box w-full px-2 py-2 rounded-lg outline-none bg-slate-100"
              placeholder="ຄຳອະທິບາຍເປັນພາສາອັງກິດ"
            ></textarea>
          </div>

          <div>
            <FileUpload
              loading={loading}
              setLoading={setLoading}
              values={values}
              setValues={setValues}
            />
          </div>
        </div>
      </div>
      <div className="pt-3 ">
        <button className="px-6 py-2 bg-green-500 text-white  rounded-lg hover:bg-green-700">
          Save
        </button>
      </div>
    </form>
  );
};

export default RoomFormUpdate;
