import { createSlice } from "@reduxjs/toolkit";
const initialStae = {
  product: {},
  loadings: false,
};

export const productSlice = createSlice({
  name: "productStore",
  initialState: initialStae,
  reducers: {
    product: (state, action) => {
      state.product = action.payload;
      state.loadings = true;
    },
  },
});
export const { product } = productSlice.actions;
export default productSlice.reducer;
