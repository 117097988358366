import React, { useEffect, useState } from "react";

import { Table, Avatar, Image } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import Adminlayout from "../../../components/layout/AdminLayout";
import { useSelector } from "react-redux";
import {
  createDepartment,
  listDepartments,
  deleteDepartment,
} from "../../../functions/department";

import { Link } from "react-router-dom";
// redux

import { toast } from "react-toastify";

// Component DepartmentFormCreate
// import DepartmentFormCreate from "../../../components/forms/DepartmentFormCreate";
import FileUpload from "../../../components/forms/FileUpload";

const DepartmentCreate = () => {
  const { user } = useSelector((state) => state.user);
  console.log(user);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    name: "",
    name_en: "",
    description: "",
    images: [],
  });
  const [Departments, setDepartments] = useState([]);

  useEffect(() => {
    loadData(user.token);
  }, []);

  const loadData = (authtoken) => {
    listDepartments(authtoken)
      .then((res) => {
        setDepartments(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRemove = (id) => {
    if (window.confirm("Are you sure Delete!")) {
      deleteDepartment(user.token, id)
        .then((res) => {
          console.log(res);
          loadData(user.token);
          toast.success("Remove Data " + res.data.name + " Success!!!");
        })
        .catch((err) => {
          console.log(err);
          toast.error("Error!!! Insert Data");
        });
    }
  };

  // console.log("datalist", Departments);

  const handleChangeDepartment = (e) => {
    console.log(values.name);
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createDepartment(user.token, values)
      .then((res) => {
        console.log(res);
        loadData(user.token);
        toast.success("Insert Data " + res.data.name + " Success!!!");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error!!! Insert Data");
      });
  };

  const columns = [
    {
      title: "ຊື່ພະແນກ",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "ຊື່ພະແນກເປັນພາສາອັງກິດ",
      dataIndex: "name_en",
      key: "name_en",
    },

    {
      title: "ຕົວຢ່າງຮູບ",
      render: (record) => (
        <Avatar src={<Image src={record?.images[0]?.url} />} />
      ),
    },

    {
      title: "Actions",
      render: (record) => (
        <div className="flex">
          <span
            onClick={() => handleRemove(record._id)}
            className="cursor-pointer ml-auto"
          >
            <DeleteOutlined
              style={{
                fontSize: "20px",
                color: "#b34969",
              }}
            />
          </span>
          <Link to={"/admin/Department/update/" + record._id}>
            <span>
              <EditOutlined
                style={{
                  fontSize: "20px",
                  color: "#b34969",
                  marginLeft: "5px",
                }}
              />
            </span>
          </Link>
        </div>
      ),
    },
  ];
  // console.log("ketoudone", Departments);
  return (
    <Adminlayout>
      <div className="flex ms:flex-row gap-5 font-Noto">
        <div className="pt-20 w-2/5">
          <div>
            <form
              onSubmit={handleSubmit}
              className="container mx-auto bg-white shadow rounded font-Phetsarath text-lg"
            >
              <div className="w-full">
                <div className="xl:w-full border-b border-gray-300 dark:border-gray-700 py-0">
                  <p className=" text-gray-800 dark:text-gray-100 font-bold text-center pt-2">
                    Add department
                  </p>
                </div>
                <div className="flex mx-auto">
                  <div className="container mx-auto">
                    <div className="my-8 mx-auto xl:w-full md:w-full sm:w-full lg-w-full xl:mx-0">
                      <div className="xl:flex lg:flex md:flex flex-wrap justify-between p-5">
                        <div className="xl:w-full lg:w-5/5 md:w-5/5 flex flex-col mb-6">
                          <label
                            htmlFor="FirstName"
                            className="pb-2 font-bold text-gray-800 dark:text-gray-100 text-center"
                          >
                            ຊື່ພະແນກເປັນພາສາລາວ
                          </label>
                          <input
                            onChange={handleChangeDepartment}
                            value={values.name}
                            type="text"
                            name="name"
                            required
                            id="Name"
                            className="border border-gray-300 dark:border-gray-700 pl-3 py-3 shadow-sm rounded  focus:outline-none bg-transparent focus:border-indigo-700 text-gray-800 dark:text-gray-100"
                            placeholder
                          />
                        </div>
                        <div className="xl:w-full lg:w-5/5 md:w-5/5 flex flex-col mb-6">
                          <label
                            htmlFor="FirstName"
                            className="pb-2  font-bold text-gray-800 dark:text-gray-100 text-center"
                          >
                            ຊື່ພະແນກເປັນພາສາອັງກິດ
                          </label>
                          <input
                            onChange={handleChangeDepartment}
                            value={values.name_en}
                            type="text"
                            name="name_en"
                            id="Name_en"
                            required
                            className="border border-gray-300 dark:border-gray-700 pl-3 py-3 shadow-sm rounded focus:outline-none bg-transparent focus:border-indigo-700 text-gray-800 dark:text-gray-100"
                            placeholder
                          />
                        </div>

                        <div className="xl:w-full lg:w-5/5 md:w-5/5 flex flex-col mb-6">
                          <label
                            htmlFor="FirstName"
                            className="pb-2  font-bold text-gray-800 dark:text-gray-100 text-center"
                          >
                            ຄຳອະທິບາຍ
                          </label>
                          <textarea
                            id="message"
                            rows="4"
                            name="description"
                            value={values.description}
                            onChange={handleChangeDepartment}
                            className="border border-gray-300 dark:border-gray-700 pl-3 py-3 shadow-sm rounded  focus:outline-none bg-transparent focus:border-indigo-700 text-gray-800 dark:text-gray-100"
                            placeholder="ຄຳອະທິບາຍ"
                          ></textarea>
                        </div>
                        <div className="">
                          <FileUpload
                            loading={loading}
                            setLoading={setLoading}
                            values={values}
                            setValues={setValues}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full py-4 sm:px-12 px-4 bg-gray-100 dark:bg-gray-700 mt-6 flex justify-end rounded-bl rounded-br">
                  <button class="px-6 py-2 text-purple-100 rounded bg-gradient-to-r from-purple-600 to-purple-400 hover:from-pink-600 hover:to-red-800">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="w-3/5">
          <div className="text-center pt-20 font-Phetsarath text-xl font-semibold">
            ພະແນກການຕ່າງໆ
          </div>
          <Table columns={columns} dataSource={Departments} />
        </div>
      </div>
    </Adminlayout>
  );
};

export default DepartmentCreate;
