import React, { useState, useEffect } from "react";
import { Table, Avatar, Image } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Adminlayout from "../../../components/layout/AdminLayout";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
// import FileUpload from "./FileUpload";
import FileUpload from "../../../components/forms/FileUpload";

import {
  listMachin,
  createMachin,
  deleteMachin,
} from "../../../functions/machin";

const Machin = () => {
  const { user } = useSelector((state) => state.user);
  console.log(user);

  const [values, setValues] = useState({
    name: "",
    description: "",
    images: [],
  });
  const [machin, setMachin] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    listMachin()
      .then((res) => {
        setMachin(res.data);
        console.log(res.data);
      })
      .catch((err) => console.log(err));
  };

  const handleMachin = (e) => {
    console.log("name", values.name);
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  console.log(values);

  const handleSubmit = (e) => {
    e.preventDefault();
    createMachin(user.token, values)
      .then((res) => {
        console.log(res);
        loadData();
        toast.success("Insert Data " + res.data.name + " Success!!!");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error!!! Insert Data");
      });
  };

  const handleRemove = (id) => {
    if (window.confirm("Are you sure Delete!")) {
      deleteMachin(user.token, id)
        .then((res) => {
          console.log(res);
          toast.success("Remove Data " + res.data.name + " Success!!!");
          loadData();
        })
        .catch((err) => {
          console.log(err);
          toast.error("Error!!! Insert Data");
        });
    }
  };

  const columns = [
    {
      title: "ຊື່",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "ຄຳບັນຍາຍ",
      dataIndex: "description",
      key: "description",
    },

    {
      title: "Code",
      dataIndex: "_id",
      key: "_id",
    },
    {
      title: "ຕົວຢ່າງຮູບ",
      render: (record) => (
        <Avatar src={<Image src={record?.images[0]?.url} />} />
      ),
    },

    {
      title: "Actions",
      render: (record) => (
        <>
          <button className="bg-pink-500 mr-1 hover:bg-pink-700 py-2 px-3 rounded text-sm leading-3 text-white focus:outline-none ">
            <div
              onClick={() => handleRemove(record._id)}
              className="flex justify-between"
            >
              <div className="pr-1">
                <DeleteOutlined
                  style={{ fontSize: "15px", pendinglelf: "10px" }}
                />
              </div>
              <div className="text-center pt-1">Delete</div>
            </div>
          </button>

          <Link to={"/admin/machinupdate/" + record._id}>
            <button className="bg-green-500 mr-1 hover:bg-green-700 py-2 px-3 rounded text-sm leading-3 text-white focus:outline-none ">
              <div className="flex justify-between">
                <div className="pr-1">
                  <EditOutlined style={{ fontSize: "15px" }} />
                </div>
                <div className="text-center pt-1">Edit</div>
              </div>
            </button>
          </Link>
        </>
      ),
    },
  ];

  return (
    <Adminlayout>
      <div className="pt-20" />
      <div className="container pt-6 mx-auto">
        <div className="flex flex-wrap">
          <div className="md:w-1/4 w-full pb-6 md:pb-0 md:pr-6">
            <h1 className="text-center text-xl font-semibold">
              Add Medical Devices
            </h1>
            <form
              onSubmit={handleSubmit}
              className="container mx-auto bg-white shadow rounded p-5"
            >
              <div className="mt-1 w-full px-2 sm:px-6">
                <div className="flex flex-col mt-1">
                  <input
                    required
                    id="name"
                    name="name"
                    className="h-8 px-2 w-full rounded mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 dark:focus:border-indigo-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 border-gray-300 border shadow"
                    type="text"
                    placeholder="ຊື່ເຄື່ອງມືການແພດ"
                    onChange={handleMachin}
                    value={values.name}
                  />
                </div>
              </div>
              <div className="mt-1 w-full px-2 sm:px-6">
                <div className="flex flex-col mt-1">
                  <textarea
                    id="message"
                    rows="6"
                    name="description"
                    onChange={handleMachin}
                    value={values.description}
                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Write your thoughts here..."
                  ></textarea>
                  {/* <input
                    required
                    id="description"
                    name="description"
                    className="h-8 px-2 w-full rounded mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 dark:focus:border-indigo-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 border-gray-300 border shadow"
                    type="text"
                    placeholder="ຊື່ເຄື່ອງມືການແພດ"
                    onChange={handleMachin}
                    value={values.description}
                  /> */}
                </div>
              </div>
              <div className="mt-1 w-full px-2 sm:px-6">
                <div className="flex flex-col mt-1">
                  <FileUpload
                    loading={loading}
                    setLoading={setLoading}
                    values={values}
                    setValues={setValues}
                  />
                </div>
              </div>

              <div className="px-2 sm:mb-16 sm:px-6 text-center">
                <button
                  type="submit"
                  className="focus:outline-none w-full sm:w-auto bg-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 rounded text-white px-8 py-3 text-sm mt-6"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
          <div className="md:w-3/4 w-full">
            <h1 className="text-center font-Noto font-semibold text-xl">
              ຕາຕະລາງເຄື່ອງມືການແພດ
            </h1>
            <Table columns={columns} dataSource={machin} />
          </div>
        </div>
      </div>
    </Adminlayout>
  );
};

export default Machin;
