import axios from "axios";

// router.post("/sliderimage", auth, uploadsliderimage, create);
export const createSliderimage = async (
  formData,
  authtoken,
  setUploadPerscenTage
) =>
  await axios.post(`${process.env.REACT_APP_API}/sliderimage`, formData, {
    headers: {
      authtoken,
    },
    onUploadProgress: (progressEvent) => {
      setUploadPerscenTage(
        parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
      );
    },
  });

//   router.get("/sliderimage", auth, list);
export const getSliderimage = async (authtoken) =>
  await axios.get(`${process.env.REACT_APP_API}/sliderimage`, {
    headers: {
      authtoken,
    },
  });

export const getSliderimageLandingPage = async () =>
  await axios.get(`${process.env.REACT_APP_API}/sliderimagefrontend`);

//   router.get("/sliderimage/:slug", auth, read);
export const getSingleSliderimage = async (slug, authtoken) =>
  await axios.get(`${process.env.REACT_APP_API}/sliderimage/${slug}`, {
    headers: {
      authtoken,
    },
  });

//   router.delete("/sliderimage/:id", auth, remove);
export const removeSliderimage = async (id, authtoken) =>
  await axios.delete(`${process.env.REACT_APP_API}/sliderimage/${id}`, {
    headers: {
      authtoken,
    },
  });

//   router.put("/sliderimage/:slug", auth, uploadsliderimage, update);
export const updateSliderimage = async (formData, slug, authtoken) =>
  await axios.put(
    `${process.env.REACT_APP_API}/sliderimage/${slug}`,
    formData,
    {
      headers: {
        authtoken,
      },
    }
  );
