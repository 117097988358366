import React, { useState, useEffect } from "react";
import { Menu, Avatar, Image } from "antd";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { Languages } from "../data/data";

//antd
import {
  LogoutOutlined,
  UserSwitchOutlined,
  DownOutlined,
} from "@ant-design/icons";

import { toast } from "react-toastify";

// redux
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/userSlice";

//Function fetch data
import { listDepartments } from "../functions/department";
import { ListDay } from "../functions/day";
import { ListServicesCustom } from "../functions/service";
import { la } from "../redux/lang";

const { SubMenu } = Menu;
const lang1 = localStorage.getItem("lang");
const TopNav = () => {
  const { user } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [departmentData, setDepartmentData] = useState([]);
  const [dayvisitData, setDayvisitData] = useState([]);
  const [servicedata, setServicedata] = useState([]);
  const [lang, setLang] = useState(lang1);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (lang) dispatch(la({ lang: lang }));

    setLoading(true);
    listDepartments()
      .then((res) => {
        setDepartmentData(res.data);
        // console.log(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    // Dayvisit Data
    ListDay()
      .then((res) => {
        setDayvisitData(res.data);
        // console.log(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });

    // Service data
    setLoading(true);
    ListServicesCustom()
      .then((res) => {
        setServicedata(res.data);
        setLoading(false);
        // console.log(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [lang]);

  const handlelogout = () => {
    dispatch(logout());
    toast.warn("Logout Success");
    navigate("/");
  };

  return (
    <Menu
      mode="horizontal"
      style={{
        position: "fixed",
        zIndex: 1,
        width: "100%",
      }}
    >
      <Menu.Item key="1">
        <Link to="/">
          <img
            src="https://res.cloudinary.com/mtphospital/image/upload/v1665818678/SliderImages/logo1_ohayh2.png"
            className="h-[60px] w-[170px] cursor-pointer"
          />
        </Link>
      </Menu.Item>
      <Menu.Item
        key="positioninstill"
        style={{ marginLeft: "auto" }}
      ></Menu.Item>

      <Menu.Item>
        <Link to="/" key="Home">
          <a className="font-Noto font-semibold text-xl">
            {lang === "en" ? "Home" : "ໜ້າຫຼັກ"}
          </a>
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/news" key="News">
          <a className="font-Noto font-semibold text-xl">
            {lang === "en" ? "News" : "ຂ່າວສານຕ່າງໆ"}
          </a>
        </Link>
      </Menu.Item>

      <SubMenu
        key="Dayvisit1"
        title={lang === "en" ? "About us" : "ກ່ຽວກັບໂຮງໝໍ"}
        style={{ fontSize: "20px" }}
        className=" font-Noto font-semibold "
      >
        <Menu.Item>
          <Link to={`/landingpage/daydetail/`}>
            <a className="font-Noto font-semibold ">
              {lang === "en" ? "Background" : "ປະຫວັດຂອງໂຮງໝໍ"}
            </a>
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to={`/landingpage/daydetail/`}>
            <a className="font-Noto font-semibold">
              {lang === "en" ? "Administrator" : "ຜູ້ບໍລິຫານຂອງໂຮງໝໍ"}
            </a>
          </Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="Dayvisit"
        title={lang === "en" ? "Day Visit" : "ວັນກວດພະຍາດ"}
        style={{ fontSize: "20px" }}
        className="font-Noto font-semibold "
      >
        {dayvisitData?.map((dv) => (
          <Menu.Item key={dv._id}>
            {lang === "en" ? (
              <Link to={`/landingpage/daydetail/${dv._id}`}>
                <a className="font-Noto font-semibold ">{dv.name_en}</a>
              </Link>
            ) : (
              <Link to={`/landingpage/daydetail/${dv._id}`}>
                <a className="font-Noto font-semibold ">{dv.name_la}</a>
              </Link>
            )}
          </Menu.Item>
        ))}
      </SubMenu>
      {/* ---------Start  Services List---------- */}
      <SubMenu
        key="Service"
        title={lang === "en" ? "Our Services" : "ລາຍການກວດທັງໝົດ"}
        style={{ fontSize: "20px" }}
        className="font-Noto font-semibold justify-center"
      >
        {servicedata.map((s) => (
          <Menu.Item
            key={s._id}
            icon={
              <Avatar
                src={<Image src={s?.images[0]?.url} style={{ width: 32 }} />}
              />
            }
          >
            <Link to={`landingpage/servicedetail/${s._id}`}>
              {lang === "en" ? (
                <a className="font-Noto font-semibold ">{s?.serviceName_en}</a>
              ) : (
                <a className="font-Noto font-semibold ">{s?.serviceName_la}</a>
              )}
            </Link>
          </Menu.Item>
        ))}
      </SubMenu>

      {/* ---------End   Services List---------- */}

      {/* ---------Start  Department List---------- */}
      <SubMenu
        key="department"
        title={lang === "en" ? "All Department" : "ພະແນກການຕ່າງໆ"}
        style={{ fontSize: "20px" }}
        className="font-Noto font-semibold justify-center"
      >
        {departmentData.map((d) => (
          <Menu.Item
            key={d._id}
            icon={
              <Avatar
                src={<Image src={d.images[0]?.url} style={{ width: 32 }} />}
              />
            }
          >
            <Link to={`/landingpage/departmentdetail/${d?._id}`}>
              {lang === "en" ? (
                <a className="font-Noto font-semibold ">{d?.name_en}</a>
              ) : (
                <a className="font-Noto font-semibold ">{d?.name}</a>
              )}
            </Link>
          </Menu.Item>
        ))}
      </SubMenu>

      {/* ---------End   Department List---------- */}
      {user && user.token ? (
        <>
          <SubMenu key="SubMenu" icon={<DownOutlined />} title={user?.username}>
            <Menu.ItemGroup>
              <Menu.Item key="Dashboard">
                <Link to={`/${user?.role}/index`}>
                  <a>Dashboard</a>
                </Link>
              </Menu.Item>
              <Menu.Item key="UpdateProfile">
                <Link to={`/${user?.role}/updateprofile/${user?._id}`}>
                  <a>Update Profile</a>
                </Link>
              </Menu.Item>

              <Menu.Item
                key="HandleLogout"
                onClick={handlelogout}
                icon={<LogoutOutlined />}
              >
                <a>Logout</a>
              </Menu.Item>
            </Menu.ItemGroup>
          </SubMenu>
        </>
      ) : (
        <Menu.Item key="22">
          <Link to="/login">
            <button className="mx-0 my-0 bg-green-600 transition duration-150 ease-in-out hover:bg-indigo-600 rounded-full text-white px-3 py-0 text-sm">
              <div className="flex flex-justify-between">
                <div className="pt-1">
                  <UserSwitchOutlined style={{ fontSize: "22px" }} />
                </div>
                <div className="pl-0 text-lg pb-0">Login</div>
              </div>
            </button>
          </Link>
        </Menu.Item>
      )}

      {lang === "en" ? (
        <Menu.Item
          onClick={() => {
            setLang("la");
            dispatch(
              la({
                lang: lang,
              })
            );
            localStorage.setItem("lang", "la");
          }}
          style={{ fontSize: "24px" }}
        >
          {Languages.map((r) => (
            <Avatar src={r?.en} />
          ))}
        </Menu.Item>
      ) : (
        <Menu.Item
          onClick={() => {
            setLang("en");
            dispatch(
              la({
                lang: lang,
              })
            );
            localStorage.setItem("lang", "en");
          }}
          style={{ fontSize: "24px" }}
        >
          {Languages.map((r) => (
            <Avatar src={r?.la} />
          ))}
        </Menu.Item>
      )}
    </Menu>
  );
};

export default TopNav;
