import React, { useState, useEffect } from "react";
import { Button, Form, message, Spin, Tabs, Input } from "antd";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

import ExperienceProjects from "../../../components/Doctors/ExperienceProjects";
import PersonalInfo from "../../../components/Doctors/PersonalInfo";
import SkillsEducation from "../../../components/Doctors/SkillsEducation";
import Adminlayout from "../../../components/layout/AdminLayout";
import { DoctorUpdateProfile, ReadDoctor } from "../../../functions/doctor";
const { TextArea } = Input;
const { TabPane } = Tabs;
function Profile() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const doctorvalues = JSON.parse(localStorage.getItem("doctorinfo"));
  const [values, setValues] = useState([]);

  useEffect(() => {
    ReadDoctor(id)
      .then((res) => {
        setValues(res.data);
        console.log(res.data);
        localStorage.setItem("doctorinfo", JSON.stringify(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  console.log("doctordata", values);
  // const user = JSON.parse(localStorage.getItem("sheyresume-user"));
  const onFinish = async (values) => {
    console.log("ket", values);
    setLoading(true);
    try {
      DoctorUpdateProfile({ ...values }, id).then((res) => {
        setLoading(false);
        message.success("Profile updated successfully");
        localStorage.removeItem("doctorinfo");
      });
    } catch (error) {
      setLoading(false);
      // message.error("Registration failed");
    }
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <Adminlayout>
      {loading && <Spin size="large" />}
      <div className="contrainer mx-auto pt-20">
        <div className="col-span-9 shadow-xl rounded px-6 bg-white pt-5 pb-7 mt-6 lg:mt-0 font-Phetsarath">
          <h4>
            <b>Update Profile</b>
          </h4>
          <hr />
          <Form
            layout="vertical"
            onFinish={onFinish}
            initialValues={doctorvalues}
          >
            <Tabs
              defaultActiveKey="1"
              className="col-span-1 flex shadow-sm rounded-md "
            >
              <TabPane tab="Personal Info" key="1">
                <PersonalInfo />
              </TabPane>
              <TabPane tab="Skills and Education" key="2">
                <SkillsEducation />
              </TabPane>
              <TabPane tab="Experience / Projects" key="3">
                <ExperienceProjects />
              </TabPane>
            </Tabs>

            <Button htmlType="submit">UPDATE</Button>
          </Form>
        </div>
      </div>
    </Adminlayout>
  );
}

export default Profile;
