import React from "react";
import { Tabs, Breadcrumb } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../landingpages/Footer";
import { Link } from "react-router-dom";
export default function RoomDetailCard({ Room, lang }) {
  const { roomName_la, roomName_en, images, description_la } = Room;

  const { TabPane } = Tabs;
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,

    appendDots: (dots) => {
      return <ul style={{ margin: "0px" }}>{dots}</ul>;
    },
  };
  return (
    <>
      <div className="p-3 bg-pink-300 rounded-t-lg text-center">
        <Breadcrumb className="font-Noto font-semibold">
          <Breadcrumb.Item>
            <Link to="/">
              <HomeOutlined style={{ fontSize: "23px", color: "#17cf73" }} />
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item className="text-lg">
            <Link to="/landingpage/rooms">
              {lang === "en" ? "All BedRooms" : "ຫ້ອງນອນທັງໝົດ"}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item className="text-gray-400 text-lg">
            {lang === "en" ? <a>{roomName_en}</a> : <a>{roomName_la}</a>}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <div className="flex flex-wrap">
        <div className="md:w-2/3 w-full pb-6 md:pb-0 md:pr-6">
          <Slider {...settings}>
            {images &&
              images.map((value, index) => {
                return (
                  <>
                    <div className="flex justify-center" key={index}>
                      <img src={value.url} />
                    </div>
                  </>
                );
              })}
          </Slider>
        </div>

        <div className="md:w-1/3 w-full border-1 bg-slate-100 p-3 ">
          <div className="w-full px-3 py-3 bg-green-400 rounded-t-lg text-center text-white text-2xl font-semibold font-Noto">
            {lang === "en" ? <a>{roomName_en}</a> : <a>{roomName_la}</a>}
          </div>
          <Tabs className="font-Noto">
            <TabPane tab={lang === "en" ? "Detail" : "ລາຍລະອຽດ"} key="1">
              <p className="flex flex-wrap ">{description_la}</p>
            </TabPane>
          </Tabs>
        </div>
      </div>

      <Footer />
    </>
  );
}
