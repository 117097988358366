import React, { useState, useEffect } from "react";
//and Design
import { Select } from "antd";
// Load Data From Server pass Functions
import { listDayvisit } from "../../functions/dayvisit";
import { listDepartments } from "../../functions/department";
import { ListServices } from "../../functions/service";

// Toastify Notifications
import { toast } from "react-toastify";
//File Upload
import FileUpload from "./FileUpload";
import { ListDay } from "../../functions/day";

// AntD
const { Option } = Select;

// Time Data
const time_visitdata = [
  { value: "8:00-16:00" },
  { value: "8:00-12:00" },
  { value: "13:30-16:00" },
];
// Rooms Data
const room_numberdata = [
  { value: "OPD" },
  { value: "1" },
  { value: "2" },
  { value: "3" },
  { value: "4" },
  { value: "5" },
  { value: "6" },
  { value: "7" },
  { value: "8" },
  { value: "9" },
  { value: "10" },
  { value: "11" },
  { value: "12" },
  { value: "13" },
  { value: "14" },
  { value: "15" },
  { value: "16" },
  { value: "17" },
  { value: "18" },
  { value: "19" },
  { value: "20" },
];
const DoctorFormUpdate = ({ onSubmit, handledoctor, values, setValues }) => {
  const {
    name_en,
    name_la,
    deeper_la,
    deeper_en,
    experience_la,
    experience_en,
    education_la,
    education_en,
    mobileNumber,
    department,
    position,
    professional_level,
    description,
    dayvisit,
    time_visit,
    room_number,
    service,
    diagnosis,
  } = values;
  const [departmentData, setDepartmentData] = useState([]);
  const [dayvisitdata, setDayvisitdata] = useState([]);
  const [servicedata, setServicedata] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    // Load Department data
    setLoading(true);
    listDepartments()
      .then((res) => {
        setDepartmentData(res.data);
        console.log(res.data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err);
        console.error(err);
        setLoading(false);
      });
    // Load Dayvisit data
    setLoading(true);
    ListDay()
      .then((res) => {
        setDayvisitdata(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    // Load Services data
    setLoading(true);
    ListServices()
      .then((res) => {
        setServicedata(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  // console.log("dayvisitdata", dayvisitdata);
  // console.log("departmentdata", departmentData);
  // console.log("ServiceData", departmentData);
  console.log("departmentvalue", department);

  return (
    <form onSubmit={onSubmit}>
      <h3 className="text-lg capitalize mb-4 font-semibold text-green-500">
        ແກ້ໄຂຂໍ້ມູນທ່ານໝໍ
      </h3>

      <hr className="p-3" />
      <div className="space-y-4">
        <div className="grid sm:grid-cols-2 gap-4">
          <div>
            <input
              type="text"
              placeholder="ຊື່ ແລະ ນາມສະກຸນ ພາສາລາວ"
              className="form-control"
              name="name_la"
              value={name_la}
              required
              onChange={handledoctor}
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="ຊື່ ແລະ ນາມສະກຸນ ພາສາອັງກິດ"
              className="form-control"
              name="name_en"
              value={name_en}
              required
              onChange={handledoctor}
            />
          </div>
          <div>
            <input
              type="text"
              className="form-control"
              placeholder="ລົງເລິກຂະແໜງ ພາສາລາວ"
              name="deeper_la"
              value={deeper_la}
              onChange={handledoctor}
            />
          </div>
          <div>
            <input
              type="text"
              className="form-control"
              placeholder="ລົງເລິກຂະແໜງ ພາສາອັງກິດ"
              name="deeper_en"
              value={deeper_en}
              onChange={handledoctor}
            />
          </div>
          <div>
            <input
              type="text"
              className="form-control"
              placeholder="ເບີໂທລະສັບ"
              name="mobileNumber"
              value={mobileNumber}
              onChange={handledoctor}
            />
          </div>

          <div>
            <select
              className="form-control"
              name="department"
              value={department}
              onChange={handledoctor}
            >
              <option key={department?._id} value={department?._id}>
                {department?.name}
              </option>
              {departmentData?.map((item) => (
                <option key={item._id} value={item._id}>
                  {item?.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <select
              className="form-control"
              name="position"
              value={position}
              onChange={handledoctor}
            >
              <option hidden>---ກະລຸນາເລືອກຕຳແໜ່ງ---</option>
              <option key="1" value="ຫົວໜ້າພະແນກ">
                ຫົວໜ້າພະແນກ
              </option>
              <option key="2" value="ຮອງຫົວໜ້າພະແນກ">
                ຮອງຫົວໜ້າພະແນກ
              </option>
              <option key="3" value="ວິຊາການ">
                ວິຊາການ
              </option>
            </select>
          </div>
          <div>
            <select
              className="form-control"
              name="professional_level"
              value={professional_level}
              onChange={handledoctor}
            >
              <option hidden>---ກະລຸນາເລືອກຂັ້ນຊ່ຽວຊານ---</option>
              <option key="1" value="1">
                ຊ່ຽວຊານຂັ້ນ 1
              </option>
              <option key="2" value="2">
                ຊ່ຽວຊານຂັ້ນ 2
              </option>
              <option key="3" value="3">
                ຊ່ຽວຊານຂັ້ນ 3
              </option>
            </select>
          </div>

          <div>
            <select
              className="form-control"
              name="diagnosis"
              value={diagnosis}
              onChange={handledoctor}
            >
              <option key={diagnosis?._id} value={diagnosis?.serviceName_la}>
                {diagnosis?.serviceName_la}
              </option>
              {servicedata?.map((item) => (
                <option key={item._id} value={item.serviceName_la}>
                  {item?.serviceName_la}
                </option>
              ))}
            </select>
          </div>

          <div>
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="ເລືອກວັນທີ່ທ່ານໝໍກວດ"
              value={dayvisit}
              onChange={(value) => setValues({ ...values, dayvisit: value })}
            >
              {dayvisitdata?.map((s) => (
                <Option key={s._id} value={s._id}>
                  {s.name_la}
                </Option>
              ))}
            </Select>
          </div>
          <div>
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="ເລືອກປະເພດການກວດ"
              value={service}
              onChange={(value) => setValues({ ...values, service: value })}
            >
              {servicedata?.map((s) => (
                <Option key={s._id} value={s._id}>
                  {s.serviceName_la}
                </Option>
              ))}
            </Select>
          </div>
          <div>
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="ເລືອກເວລາກວດ"
              value={time_visit}
              onChange={(value) => setValues({ ...values, time_visit: value })}
            >
              {time_visitdata?.map((s) => (
                <Option key={s.value} value={s.value}>
                  {s.value}
                </Option>
              ))}
            </Select>
          </div>
          <div>
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="ເລືອກຫ້ອງກວດເບີ"
              value={room_number}
              onChange={(value) => setValues({ ...values, room_number: value })}
            >
              {room_numberdata?.map((s) => (
                <Option key={s.value} value={s.value}>
                  {s.value}
                </Option>
              ))}
            </Select>
          </div>
          <div>
            <textarea
              id="message"
              rows="4"
              name="experience_la"
              value={experience_la}
              onChange={handledoctor}
              className="form-control"
              placeholder="ປະສົບການເປັນພາສາລາວ"
            ></textarea>
          </div>
          <div>
            <textarea
              id="message"
              rows="4"
              name="experience_en"
              value={experience_en}
              onChange={handledoctor}
              className="form-control"
              placeholder="ປະສົບການເປັນພາສາອັງກິດ"
            ></textarea>
          </div>
          <div>
            <textarea
              id="message"
              rows="4"
              name="education_la"
              value={education_la}
              onChange={handledoctor}
              className="form-control"
              placeholder="ປະຫັວດການຮຽນເປັນພາສາລາວ"
            ></textarea>
          </div>
          <div>
            <textarea
              id="message"
              rows="4"
              name="education_en"
              value={education_en}
              onChange={handledoctor}
              className="form-control"
              placeholder="ປະຫັວດການຮຽນເປັນພາສາອັງກິດ"
            ></textarea>
          </div>
          <div>
            <div>
              <textarea
                id="message"
                rows="4"
                name="description"
                value={description}
                onChange={handledoctor}
                className="form-control"
                placeholder="ຂໍ້ມູນຕ່າງໆ"
              ></textarea>
            </div>
            <FileUpload
              loading={loading}
              setLoading={setLoading}
              values={values}
              setValues={setValues}
            />
          </div>
        </div>
      </div>
      <div className="pt-3 ">
        <button className="px-6 py-2 bg-green-500 text-white  rounded-lg hover:bg-green-700">
          Save
        </button>
      </div>
    </form>
  );
};

export default DoctorFormUpdate;
