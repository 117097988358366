import React from "react";

export default function RoomCard({ rooms, lang, Link }) {
  return (
    <>
      {rooms?.map((item) => (
        <div
          key={item?._id}
          className="border shadow-lg rounded-lg hover:scale-105 duration-300 font-Noto"
        >
          <img
            src={item?.images[0]?.url}
            alt={item?.roomName_la}
            className="w-full h-[200px] object-cover rounded-t-lg "
          />
          <div className=" px-2 py-4 text-center">
            <p className="font-bold text-xl text-pink-600">
              {lang === "en" ? (
                <a> {item?.roomName_en}</a>
              ) : (
                <a>{item?.roomName_la}</a>
              )}
            </p>
            <Link to={`/landingpage/roomdetail/${item?._id}`}>
              <button className="pink-btn" sx={{ marginLeft: "auto" }}>
                {lang === "en" ? (
                  <a className="text-white"> Detail</a>
                ) : (
                  <a className="text-white"> ລາຍລະອຽດ</a>
                )}
              </button>
            </Link>
          </div>
        </div>
      ))}
      ;
    </>
  );
}
