import { Table, Avatar, Image } from "antd";
import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import Adminlayout from "../../../components/layout/AdminLayout";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { DeleteService, ListServices } from "../../../functions/service";
import { useSelector } from "react-redux";

export default function Services() {
  const { user } = useSelector((state) => state.user);
  const [servicedata, setServicedata] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    ListServices()
      .then((res) => {
        setServicedata(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRemove = (id) => {
    if (window.confirm("Are you sure Delete!")) {
      DeleteService(user.token, id)
        .then((res) => {
          console.log(res);
          loadData();
          toast.error("Remove Data " + res.data.serviceName_la + " Success!!!");
        })
        .catch((err) => {
          console.log(err);
          toast.error("Error!!! Insert Data");
        });
    }
  };

  const columns = [
    {
      title: "ຊື່ການບໍລິການ",
      dataIndex: "serviceName_la",
      key: "serviceName_la",
    },
    {
      title: "Name Of Service",
      dataIndex: "serviceName_en",
      key: "serviceName_en",
    },
    {
      title: "ຕົວຢ່າງຮູບ",
      render: (record) => (
        <Avatar src={<Image src={record?.images[0]?.url} />} />
      ),
    },
    {
      title: "Actions",
      render: (record) => (
        <>
          <span
            onClick={() => handleRemove(record._id)}
            className="cursor-pointer ml-auto"
          >
            <DeleteOutlined
              style={{
                fontSize: "22px",
                color: "#b34969",
                marginLeft: "10px",
              }}
            />
          </span>
          <Link to={"/admin/service/update/" + record._id}>
            <span>
              <EditOutlined
                style={{
                  fontSize: "22px",
                  color: "#b34969",
                  marginLeft: "10px",
                }}
              />
            </span>
          </Link>
        </>
      ),
    },
  ];

  return (
    <Adminlayout>
      <div className="contrainer mx-auto p-5 pt-20">
        <div className="flex justify-end pb-2">
          <Link to="/admin/service/create">
            <button className="pink-btn ">
              <PlusOutlined style={{ fontSize: 20, color: "#07eb16" }} />
              New Service
            </button>
          </Link>
        </div>

        <div className="col-span-9 shadow-xl rounded px-6 bg-white pt-5 pb-7 mt-6 lg:mt-0 font-Noto">
          <Table columns={columns} dataSource={servicedata} />
        </div>
      </div>
    </Adminlayout>
  );
}
