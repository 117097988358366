import React, { useState, useEffect } from "react";
import { Menu, Layout } from "antd";
import { Link } from "react-router-dom";

import { useWindowWidth } from "@react-hook/window-size";

import { UserSwitchOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

const { SubMenu } = Menu;
const { Sider } = Layout;

const UserNav = () => {
  const { user } = useSelector((state) => state.user);

  // state
  const [collapsed, setCollapsed] = useState(false);

  // hooks
  const onlyWidth = useWindowWidth();

  useEffect(() => {
    if (onlyWidth < 800) {
      setCollapsed(true);
    } else if (onlyWidth > 800) {
      setCollapsed(false);
    }
  }, [onlyWidth < 800]);

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={() => setCollapsed(!collapsed)}
    >
      <Menu
        defaultOpenKeys={["2", "5", "11", "14"]}
        mode="inline"
        inlineCollapsed={collapsed}
      >
        <SubMenu
          key="2"
          icon={
            <UserSwitchOutlined style={{ fontSize: 22, color: "#f01a9e" }} />
          }
          title="Profile"
          style={{ paddingTop: "80px" }}
        >
          <Menu.Item key="3">
            <Link to={`/user/userprofileupdate/${user._id}`}>
              <a>Edit Profile</a>
            </Link>
          </Menu.Item>
          <Menu.Item key="4">
            <Link to="/register">
              <a>Edit Password</a>
            </Link>
          </Menu.Item>
        </SubMenu>
      </Menu>
    </Sider>
  );
};

export default UserNav;
