import React, { useState, useEffect } from "react";
import { Card, Tabs, Breadcrumb } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { readMachin } from "../../functions/machin";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import "../../../src/App.css";
const Medicaldevicedetail = () => {
  const { id } = useParams();

  const [medicaldevice, setMedicalDevice] = useState([]);

  useEffect(() => {
    readMachin(id)
      .then((res) => {
        setMedicalDevice(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);

  // console.log(medicaldevice);
  const { description, images, name } = medicaldevice;
  // console.log("medicaldevice", description, images, name);

  const { TabPane } = Tabs;
  return (
    <>
      <div className="container pt-20 mx-auto">
        <div className="pb-5">
          <Breadcrumb className="font-Noto font-semibold">
            <Breadcrumb.Item>
              <HomeOutlined style={{ fontSize: "23px", color: "#17cf73" }} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>ໜ້າຫຼັກ</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>ລາຍລະອຽດເຄື່ອງມືການແພດ</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="flex flex-wrap">
          <div className="md:w-2/3 w-full pb-6 md:pb-0 md:pr-6">
            <Carousel autoPlay showArrows={true} infiniteLoop>
              {images &&
                images.map((item) => (
                  <img
                    src={item.url}
                    key={item.public_id}
                    className="object-cover w-full object-center rounded-full"
                  />
                ))}
            </Carousel>
            <Tabs>
              <TabPane tab="Description" key="1">
                {description}
              </TabPane>
              <TabPane tab="More..." key="2">
                More...
              </TabPane>
            </Tabs>
          </div>
          <div className="md:w-1/3 w-full">
            <div className="w-full px-3 py-3 bg-pink-500 rounded-l-lg text-center text-2xl font-semibold font-Noto">
              {name}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Medicaldevicedetail;
