import React, { useState, useEffect } from "react";

import { Select } from "antd";

import FileUpload from "./FileUpload";
import { ListDoctor } from "../../functions/doctor";
import { ListDay } from "../../functions/day";
const { Option } = Select;

const ServiceFormCreate = ({ onSubmit, handleChange, values, setValues }) => {
  const {
    roomName_la,
    roomName_en,
    price,
    nursePrice,
    description_la,
    description_en,
  } = values;

  const [dayvisitdata, setDayvisitdata] = useState([]);
  const [doctordata, setDoctordata] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    // Load Dayvisit data
    setLoading(true);
    ListDay()
      .then((res) => {
        setDayvisitdata(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    // Load Services data
    setLoading(true);
    ListDoctor()
      .then((res) => {
        setDoctordata(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  console.log("dayvisitdata", dayvisitdata);

  console.log("DoctorData", doctordata);

  return (
    <>
      <div>
        <div className="bg-pink-200 ">
          {" "}
          <h3 className="text-lg capitalize mb-4 font-semibold text-green-500 text-center ">
            New Room
          </h3>
        </div>
        <form onSubmit={onSubmit}>
          <hr className="p-3" />
          <div className="space-y-4">
            <div className="grid sm:grid-cols-2 gap-4">
              <div>
                <input
                  type="text"
                  placeholder="ຊື່ເປັນພາສາລາວ"
                  className="input-box w-full px-2 py-2 rounded-lg outline-none bg-slate-100"
                  name="roomName_la"
                  value={roomName_la}
                  required
                  onChange={handleChange}
                />
              </div>
              <div>
                <input
                  type="text"
                  placeholder="ຊື່ເປັນພາສາອັງກິດ"
                  className="input-box w-full px-2 py-2 rounded-lg outline-none bg-slate-100"
                  name="roomName_en"
                  value={roomName_en}
                  required
                  onChange={handleChange}
                />
              </div>
              <div>
                <input
                  type="text"
                  placeholder="ລາຄາຄ່າຫ້ອງ"
                  className="input-box w-full px-2 py-2 rounded-lg outline-none bg-slate-100"
                  name="price"
                  value={price}
                  required
                  onChange={handleChange}
                />
              </div>
              <div>
                <input
                  type="text"
                  placeholder="ຄ່າບໍລິການພະຍາບານ"
                  className="input-box w-full px-2 py-2 rounded-lg outline-none bg-slate-100"
                  name="nursePrice"
                  value={nursePrice}
                  required
                  onChange={handleChange}
                />
              </div>

              <div>
                <textarea
                  id="message"
                  rows="4"
                  name="description_la"
                  value={description_la}
                  onChange={handleChange}
                  className="input-box w-full px-2 py-2 rounded-lg outline-none bg-slate-100"
                  placeholder="ຄຳອະທິບາຍເປັນພາສາລາວ"
                ></textarea>
              </div>
              <div>
                <textarea
                  id="message"
                  rows="4"
                  name="description_en"
                  value={description_en}
                  onChange={handleChange}
                  className="input-box w-full px-2 py-2 rounded-lg outline-none bg-slate-100"
                  placeholder="ຄຳອະທິບາຍເປັນພາສາອັງກິດ"
                ></textarea>
              </div>

              <div>
                <FileUpload
                  loading={loading}
                  setLoading={setLoading}
                  values={values}
                  setValues={setValues}
                />
              </div>
            </div>
          </div>
          <div className="pt-3 ">
            <button className="px-6 py-2 bg-green-500 text-white  rounded-lg hover:bg-green-700">
              Save
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ServiceFormCreate;
