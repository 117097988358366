import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Form, message, Spin, Tabs } from "antd";
import Adminlayout from "../../../components/layout/AdminLayout";
import { CloudUploadOutlined } from "@ant-design/icons";
import PersonalInfo from "../../../components/Doctors/PersonalInfo";
import SkillsEducation from "../../../components/Doctors/SkillsEducation";
import ExperienceProjects from "../../../components/Doctors/ExperienceProjects";
import { useSelector } from "react-redux";
import axios from "axios";
const { TabPane } = Tabs;
const DoctorUploadImage = () => {
  const { user } = useSelector((state) => state.user);

  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const result = await axios.post("api/user/update", {
        ...values,
        _id: user._id,
      });
      localStorage.setItem("sheyresume-user", JSON.stringify(result.data));
      setLoading(false);
      message.success("Profile updated successfully");
    } catch (error) {
      setLoading(false);
      message.error("Registration failed");
    }
  };

  return (
    <Adminlayout>
      {loading && <Spin size="large" />}
      <div className="update-profile">
        <h4>
          <b>Update Profile</b>
        </h4>
        <hr />
        <Form layout="vertical" onFinish={onFinish} initialValues={user}>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Personal Info" key="1">
              <PersonalInfo />
            </TabPane>
            <TabPane tab="Skills and Education" key="2">
              <SkillsEducation />
            </TabPane>
            <TabPane tab="Experience / Projects" key="3">
              <ExperienceProjects />
            </TabPane>
          </Tabs>

          <Button htmlType="submit">UPDATE</Button>
        </Form>
      </div>
    </Adminlayout>
  );
};

export default DoctorUploadImage;
