import React, { useState, useEffect } from "react";
import { listDepartments } from "../../functions/department";
import { toast } from "react-toastify";
import FileUpload from "./FileUpload";

export default function UserFormProfileUpdate({
  values,
  setValues,
  handlechange,
  onSubmit,
}) {
  const {
    name_en,
    name_la,
    Dbirth,
    department,
    position,
    youth_union_date,
    labour_union_date,
    women_date,
    employee_type,
    party_membership_date,
    username,
    labour_union_leader,
    women_leader,
    youth_union_leader,
    sexual,
    status,
  } = values;
  const [departmentData, setDepartmentData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // Load Department data
    setLoading(true);
    listDepartments()
      .then((res) => {
        setDepartmentData(res.data);
        console.log(res.data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err);
        console.error(err);
        setLoading(false);
      });
  }, []);

  console.log("sexual=>", sexual);
  return (
    <form
      onSubmit={onSubmit}
      className="shadow-xl p-5 font-Noto bg-green-100 rounded-xl"
    >
      <h3 className="text-lg capitalize mb-4 font-semibold text-gray-700 text-center rounded-t-xl bg-green-500">
        ແກ້ໄຂຂໍ້ມູນ
      </h3>

      <hr className="p-3" />
      <div className="space-y-4">
        <div className="grid sm:grid-cols-3 gap-4 lg:grid-cols-4 md:grid-cols-3">
          <div>
            <input
              type="text"
              placeholder="ຜູ້ໃຊ້ງານ"
              className="form-control"
              name="username"
              value={username}
              required
              disabled
              onChange={handlechange}
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="ຊື່ ແລະ ນາມສະກຸນ ພາສາລາວ"
              className="form-control"
              name="name_la"
              value={name_la}
              required
              onChange={handlechange}
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="ຊື່ ແລະ ນາມສະກຸນ ເປັນພາສາອັງກິດ"
              className="form-control"
              name="name_en"
              value={name_en}
              required
              onChange={handlechange}
            />
          </div>

          <div>
            <select
              className="form-control"
              name="department"
              value={department}
              onChange={handlechange}
            >
              <option hidden>---ກະລຸນາເລືອກພະແນກຂອງທ່ານ---</option>
              {departmentData?.map((item) => (
                <option key={item._id} value={item._id}>
                  {item?.name}
                </option>
              ))}
            </select>
          </div>

          <div>
            <select
              className="form-control"
              name="position"
              value={position}
              onChange={handlechange}
            >
              <option hidden>---ຕຳແໜ່ງພາຍໃນພະແນກ---</option>
              <option key="1" value="1">
                ຫົວໜ້າພະແນກ
              </option>
              <option key="2" value="2">
                ຮອງຫົວໜ້າພະແນກ
              </option>
              <option key="3" value="3">
                ວິຊາການ
              </option>
            </select>
          </div>
          <div>
            <select
              className="form-control"
              name="employee_type"
              value={employee_type}
              onChange={handlechange}
            >
              <option hidden>---ສາຍພະນັກງານ---</option>
              <option key="1" value="1">
                ບໍລິຫານ
              </option>
              <option key="2" value="2">
                ວິຊາການ
              </option>
            </select>
          </div>
          <div>
            <select
              className="form-control"
              name="sexual"
              value={sexual}
              onChange={handlechange}
            >
              <option hidden>---ສາຍພະນັກງານ---</option>
              <option key="1" value="male">
                ຊາຍ
              </option>
              <option key="2" value="female">
                ຍິງ
              </option>
            </select>
          </div>
          <div>
            <select
              className="form-control"
              name="status"
              value={status}
              onChange={handlechange}
            >
              <option hidden>---ສະຖານະ---</option>
              <option key="1" value="single">
                ໂສດ
              </option>
              <option key="2" value="married">
                ແຕ່ງງານແລ້ວ
              </option>
              <option key="3" value="other">
                ບໍ່ລະບຸ
              </option>
            </select>
          </div>
        </div>

        <div className="border-2 border-pink-500 rounded-t-xl ">
          <div className="text-center text-white text-xl bg-gray-500  rounded-t-xl">
            ວັນເດືອນປີເຂົ້າອົກງານຈັດຕັ້ງ
          </div>

          <div className="grid sm:grid-cols-3 gap-4 lg:grid-cols-4 md:grid-cols-3 p-3">
            <div>
              <label>ວັນ,ເດືອນ,ປີ ເກີດ</label>
              <input
                type="date"
                placeholder="ວັນ,ເດືອນ,ປິ ເກິດ"
                className="form-control"
                name="Dbirth"
                value={Dbirth}
                required
                onChange={handlechange}
              />
            </div>
            <div>
              <label>ວັນ,ເດືອນ,ປີ ເຂົ້າຊາວໜຸ່ມ</label>
              <input
                type="date"
                placeholder="ວັນເຂົ້າຊາວໝຸ່ມ"
                className="form-control"
                name="youth_union_date"
                value={youth_union_date}
                required
                onChange={handlechange}
              />
            </div>
            <div>
              <label>ວັນ,ເດືອນ,ປີ ເຂົ້າກຳມະບານ</label>
              <input
                type="date"
                placeholder="ວັນເຂົ້າກຳມະບານ"
                className="form-control"
                name="labour_union_date"
                value={labour_union_date}
                required
                onChange={handlechange}
              />
            </div>

            {sexual !== "male" ? (
              <div>
                <label>ວັນ,ເດືອນ,ປີ ເຂົ້າແມ່ຍິງ</label>
                <input
                  type="date"
                  placeholder="ວັນເຂົ້າແມ່ຍິງ"
                  className="form-control"
                  name="women_date"
                  value={women_date}
                  required
                  onChange={handlechange}
                />
              </div>
            ) : (
              ""
            )}

            <div>
              <label>ວັນ,ເດືອນ,ປີ ເຂົ້າພັກ</label>
              <input
                type="date"
                placeholder="ວັນ,ເດືອນ,ປິ ເຂົ້າພັກ"
                className="form-control"
                name="party_membership_date"
                value={party_membership_date}
                onChange={handlechange}
              />
            </div>
          </div>
        </div>

        <div className="border-2 border-pink-500 rounded-t-xl ">
          <div className="text-center text-white text-xl bg-gray-500  rounded-t-xl">
            ວຽກອົງການຈັດຕັ້ງ ພາຍໃນພະແນກ
          </div>

          <div className="grid sm:grid-cols-3 gap-4 lg:grid-cols-4 md:grid-cols-3 p-3">
            {sexual !== "male" ? (
              <div>
                <select
                  className="form-control"
                  name="women_leader"
                  value={women_leader}
                  onChange={handlechange}
                >
                  <option hidden>---ຕຳແໜ່ງກ່ຽວັບແມ່ຍິງ---</option>
                  <option key="1" value="1">
                    ຫົວໜ້າແມ່ຍິງ
                  </option>
                  <option key="2" value="2">
                    ຮອງຫົວໜ້າແມ່ຍິງ
                  </option>
                  <option key="3" value="3">
                    ສະມາຊິກແມ່ຍິງ
                  </option>
                </select>
              </div>
            ) : (
              ""
            )}

            <div>
              <select
                className="form-control"
                name="labour_union_leader"
                value={labour_union_leader}
                onChange={handlechange}
              >
                <option hidden>---ຕຳແໜ່ງກ່ຽວກຳມະບານ---</option>
                <option key="1" value="1">
                  ຫົວໜ້າກຳມະບານ
                </option>
                <option key="2" value="2">
                  ຮອງຫົວໜ້າກຳມະບານ
                </option>
                <option key="3" value="3">
                  ສະມາຊິກກຳມະບານ
                </option>
              </select>
            </div>
            <div>
              <select
                className="form-control"
                name="youth_union_leader"
                value={youth_union_leader}
                onChange={handlechange}
              >
                <option hidden>---ຕຳແໜ່ງກ່ຽວຊາວໜຸ່ມ---</option>
                <option key="1" value="1">
                  ຫົວໜ້າຊາວໜຸ່ມ
                </option>
                <option key="2" value="2">
                  ຮອງຫົວໜ້າຊາວໜຸ່ມ
                </option>
                <option key="3" value="3">
                  ສະມາຊິກຊາວໜຸ່ມ
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-3 ">
        <button className="pink-btn">Save</button>
      </div>
    </form>
  );
}
