import React, { useState, useEffect } from "react";
import { Table, Avatar, Image } from "antd";

import { Link } from "react-router-dom";
import Adminlayout from "../../../components/layout/AdminLayout";
import { DeleteDoctor, ListDoctor } from "../../../functions/doctor";
import { listDepartments } from "../../../functions/department";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const AllDoctor = () => {
  const { user } = useSelector((state) => state.user);
  const [doctor, setDoctor] = useState([]);
  const [department, setDepartment] = useState([]);
  useEffect(() => {
    loadData();
  }, []);
  console.log("doctor", doctor);

  const loadData = () => {
    ListDoctor()
      .then((res) => {
        setDoctor(res.data);
        console.log("alldoctor", res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    listDepartments()
      .then((res) => {
        setDepartment(res.data);
        console.log("department", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log("doctor=>", doctor);

  const handleRemove = (id) => {
    if (window.confirm("Are you sure Delete!")) {
      DeleteDoctor(user.token, id)
        .then((res) => {
          console.log(res);
          loadData();
          toast.success("Remove Data " + res?.data?.name_la + " Success!!!");
        })
        .catch((err) => {
          console.log(err);
          toast.error("Error!!! Insert Data");
        });
    }
  };

  const dep = department.map((p) => p.name);
  console.log("departmenttttttttt=>", dep);

  const columns = [
    {
      title: "ຊື່ ແລະ ນາມສະກຸນ",
      dataIndex: "name_la",
      key: "name_la",
    },
    {
      title: "ພະແນກ",
      render: (record) => record?.department?.name,

      filters: [
        {
          text: "ພະແນກ ກະທົບ-ຜ່າຕັດກະດູກ",
          value: "ພະແນກ ກະທົບ-ຜ່າຕັດກະດູກ",
        },
        {
          text: "ພະແນກ ອອກລູກ-ພະຍາດຍິງ-ແມ່ ແລະ ເດັກ",
          value: "ພະແນກ ອອກລູກ-ພະຍາດຍິງ-ແມ່ ແລະ ເດັກ",
        },
      ],

      onFilter: (value, record) => record.department.name.startsWith(value),
      filterSearch: true,
    },
    {
      title: "ຕຳແໜ່ງ",
      dataIndex: "position",
      key: "position",
    },
    {
      title: "ຊ່ຽວຊານຂັ້ນ",
      dataIndex: "professional_level",
      key: "professional_level",
    },
    {
      title: "ເວລາກວດ",
      render: (record) => record?.time_visit,
    },
    {
      title: "ຫ້ອງກວດ",
      render: (record) => record?.room_number,
    },

    {
      title: "ຕົວຢ່າງຮູບ",
      render: (record) => (
        <Avatar src={<Image src={record?.images[0]?.url} />} />
      ),
    },

    {
      title: "Actions",
      render: (record) => (
        <>
          <button className="bg-pink-500 mr-1 hover:bg-pink-700 py-2 px-3 rounded text-sm leading-3 text-white focus:outline-none ">
            <div
              onClick={() => handleRemove(record._id)}
              className="flex justify-between"
            >
              <div className="pr-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-4 h-4 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              </div>
              <div className="text-center pt-1">Delete</div>
            </div>
          </button>

          <Link to={"/admin/doctor/" + record._id}>
            <button className="bg-green-500 mr-1 hover:bg-green-700 py-2 px-3 rounded text-sm leading-3 text-white focus:outline-none ">
              <div className="flex justify-between">
                <div className="pr-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-4 h-4 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                    />
                  </svg>
                </div>
                <div className="text-center pt-1">Edit</div>
              </div>
            </button>
          </Link>
        </>
      ),
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    // console.log("params", pagination, filters, sorter, extra);
  };
  return (
    <Adminlayout>
      <div className="pt-20 contrainer mx-auto font-Noto">
        <Table columns={columns} dataSource={doctor} onChange={onChange} />
      </div>
    </Adminlayout>
  );
};

export default AllDoctor;
