import { Form, Input } from "antd";
import React from "react";

const { TextArea } = Input;

function PersonalInfo() {
  return (
    <>
      <div className="space-y-1">
        <div className="grid sm:grid-cols-2 gap-4">
          <div className="w-full">
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </div>
          <div className="w-full">
            <Form.Item
              name="lastName"
              label="Last Name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </div>
          <div className="w-full">
            <Form.Item label="Email" name="email" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </div>
          <div className="w-full">
            <Form.Item
              name="mobileNumber"
              label="Mobile Number"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </div>
          <div className="w-full">
            <Form.Item
              name="portfolio"
              label="Portfolio"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <Form.Item
          name="carrierObjective"
          label="Carrier Objective"
          rules={[{ required: true }]}
        >
          <TextArea />
        </Form.Item>
      </div>
      <div className="col-md-12">
        <Form.Item name="address" label="Address" rules={[{ required: true }]}>
          <TextArea />
        </Form.Item>
      </div>
    </>
  );
}

export default PersonalInfo;
