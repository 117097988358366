import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { listMachin } from "../../functions/machin";
import { Breadcrumb } from "antd";
import { HomeOutlined } from "@ant-design/icons";
const Allmachin = () => {
  const [machin, setMachin] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadData(4);
  }, []);

  const loadData = (count) => {
    setLoading(true);
    listMachin()
      .then((res) => {
        setLoading(false);
        setMachin(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <div className="max-w-[1640px] m-auto px-4 py-12 pt-20 font-Noto">
      <Breadcrumb className="font-Phetsarath font-semibold">
        <Breadcrumb.Item href="/">
          <HomeOutlined style={{ fontSize: "23px", color: "#17cf73" }} />
        </Breadcrumb.Item>
        <Breadcrumb.Item href="/">
          <span>ໜ້າຫຼັກ</span>
        </Breadcrumb.Item>
        <Breadcrumb.Item>ເຄື່ອງມືການແພດທັງໝົດ</Breadcrumb.Item>
      </Breadcrumb>
      <div className="flex justify-around  font-Phetsarath font-semibold">
        <div className="text-pink-400  text-xl">ເຄື່ອງມືການແພດທັງໝົດ</div>
      </div>
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-6 pt-4">
        {machin.map((item) => (
          <div
            key={item._id}
            className="border shadow-lg rounded-lg hover:scale-105 duration-300 text-center"
          >
            <img
              src={item.images[0].url}
              alt={item.name}
              className="w-full h-[200px] object-cover rounded-t-lg"
            />
            <div className="px-2 py-4 text-center">
              <p className="font-bold text-xl text-pink-600 ">{item.name}</p>
              <Link to={`/medicaldevicedetail/${item._id}`}>
                <button className="pink-btn" sx={{ marginLeft: "auto" }}>
                  ລາຍລະອຽດ
                </button>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Allmachin;
