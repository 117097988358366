import React from "react";
import { Link } from "react-router-dom";

export default function ServiceCard({ lang, servicedata }) {
  return (
    <>
      <h1 className="text-center text-3xl text-primary font-semibold text-pink-700 font-Noto  ">
        {lang === "en" ? (
          <a className="text-pink-700">Our Services</a>
        ) : (
          <a className="text-pink-700 font-lao">ການບໍລິການກວດ</a>
        )}
      </h1>
      <div className="grid grid-cols-1 lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-2 gap-8 ">
        {servicedata.map((item) => {
          return (
            <div
              className=" rounded flex flex-col gap-5 items-center justify-center"
              key={item._id}
            >
              <Link to={`/landingpage/servicedetail/${item._id}`}>
                <div className="bg-white -mt-2 p-5 border-2 border-pink-700 rounded-full cursor-pointer hover:scale-105 duration-300">
                  <img
                    src={item?.images[0]?.url}
                    alt={item._id}
                    className="h-10 w-10 "
                  />
                </div>
              </Link>
              <Link to={`/landingpage/servicedetail/${item._id}`}>
                <h1 className="text-xl font-semibold text-primary py-5 pt-0 font-Noto text-purple-600 cursor-pointer text-center">
                  {lang === "en" ? (
                    <p className="text-md text-pink-700">
                      {item?.serviceName_en}
                    </p>
                  ) : (
                    <p className="text-lg text-pink-700">
                      {item?.serviceName_la}
                    </p>
                  )}
                </h1>
              </Link>
            </div>
          );
        })}
      </div>
    </>
  );
}
