import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { HomeOutlined } from "@ant-design/icons";
import { Breadcrumb } from "antd";
import { useSelector } from "react-redux";

export default function DoctorCard({ doctordata }) {
  const { lang } = useSelector((state) => state.lang.lang);
  const [doctors, setDoctors] = useState(doctordata);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (doctors) setDoctors(doctordata);
  }, [doctordata]);
  const filterType = (diagnosis) => {
    setDoctors(
      doctors.filter((item) => {
        return item.diagnosis === diagnosis;
      })
    );
  };

  // console.log("DoctorData1", doctordata);
  // console.log("DoctorDataNew", doctors);
  return (
    <>
      <div className="max-w-[1600px] m-auto px-4 ">
        <Breadcrumb className="font-Noto font-semibold">
          <Breadcrumb.Item>
            <Link to="/">
              <HomeOutlined style={{ fontSize: "23px", color: "#17cf73" }} />
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item className="text-lg  text-gray-500">
            <button
              className="
            bg-pink-500 text-white text-center pb-0 py-2 px-2 text-sm hover:bg-pink-300 rounded-full font-Noto
            "
              onClick={() => setDoctors(doctordata)}
            >
              {lang === "en" ? (
                <p className="text-white"> All Doctors</p>
              ) : (
                <p className="text-white"> ບັນດາທ່ານໝໍທັງໝົດ</p>
              )}
            </button>
          </Breadcrumb.Item>
        </Breadcrumb>

        <div className="grid grid-cols-1 lg:grid-4 md:grid-4 pb-3 pt-3 gap-3">
          <div className="">
            {doctors?.map((s) => (
              <button
                className="pink-btn font-Noto"
                key={s._id}
                onClick={() => filterType(s?.diagnosis)}
              >
                {s.diagnosis}
              </button>
            ))}
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-6 gap-4 md:grid-cols-4 sm:grid-cols-2 pt-0 pb-5 pr-3 pl-3">
          {doctors?.map((item) => (
            <div
              key={item?._id}
              className="border shadow-lg rounded-lg hover:scale-105 duration-300"
            >
              <Link to={`/landingpage/doctordetail/${item?._id}`}>
                <img
                  src={item?.images[0]?.url}
                  alt={item?._id}
                  className="w-full object-cover rounded-lg object-center lg:h-[250px] sm:h-[300px] sm:w-ull"
                />
              </Link>

              <div className=" md:grid-rows-2 px-2 py-4 font-Noto mx-0 my-0  ">
                <div className="">
                  <p className="font-bold text-center text-pink-600 text-xs">
                    {lang === "en" ? (
                      <p>{item?.name_en}</p>
                    ) : (
                      <p>{item?.name_la}</p>
                    )}
                  </p>
                </div>
                <div
                  className=" text-center  bg-gradient-to-r rounded-full
                    from-pink-200
                    via-pink-500
                    to-pink-300"
                >
                  <p className="font-bold text-white text-xs">
                    {item?.position}
                  </p>
                </div>
                <div className="text-center text-green-500 font-semibold ">
                  {lang === "en" ? (
                    <p className="text-xs">{item?.department?.name_en}</p>
                  ) : (
                    <p className="text-xs">{item?.department?.name}</p>
                  )}
                </div>
                <div className="font-bold text-center ">
                  <Link to={`/landingpage/doctordetail/${item?._id}`}>
                    <button className="pink-btn text-xs">Detail</button>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
