import React, { useState, useEffect } from "react";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

const LoadingToRedirect = () => {
  const [count, setCount] = useState(3);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((currentCount) => --currentCount);
    }, 1000);
    // Redirect
    count === 0 && navigate("/");

    return () => clearInterval(interval);
  }, [count]);

  return (
    <>
      <div className="h-24 text-center" />
      <div className="contrainer mx-auto font-Noto font-semibold">
        <Result
          status="500"
          title="ທ່ານບໍ່ມີສິດເຂົ້າເຖິງເສັ້ນທາງນີ້ໄດ້ຂໍໂທດຫຼາຍໆເດີ"
          subTitle="ທ່ານຈະກັບຄືນໜ້າຫຼັກພາຍໃນ"
          extra={<Button type="danger">{count}</Button>}
        />
      </div>
    </>
  );
};

export default LoadingToRedirect;
