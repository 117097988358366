import React, { useState, useEffect } from "react";
import { Breadcrumb, Menu } from "antd";
import { ListDayOnly, ReadDay } from "../../../functions/day";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
export default function DayDetailLandingpage() {
  const { lang } = useSelector((state) => state.lang.lang);
  const params = useParams();
  const [daysingledata, setDaysingleData] = useState([]);
  const [daydata, setDayData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { name_la, name_en, doctor, service, room_number, time_visit } =
    daysingledata;

  useEffect(() => {
    if (params?.id) loadData();
  }, [params?.id]);

  const loadData = () => {
    // Load Single Day Data
    setLoading(true);
    ReadDay(params.id)
      .then((res) => {
        setDaysingleData(res.data);
        setLoading(false);
        // console.log(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });

    // Load All Day Data
    setLoading(true);
    ListDayOnly()
      .then((res) => {
        setDayData(res.data);
        setLoading(false);
        // console.log(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  // console.log("DaySingleData=>", daysingledata);
  // console.log("AllDayData=>", daydata);
  // console.log("DoctorData=>", doctor);

  const dayfilter = daydata?.filter((item) => item._id !== daysingledata._id);

  const menu = (
    <Menu>
      {dayfilter?.map((day) => (
        <Menu.Item className="font-Noto">
          <Link to={`/landingpage/daydetail/${day._id}`}>
            {lang === "en" ? <a> {day.name_en}</a> : <a> {day.name_la}</a>}
          </Link>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      <div className="contrainer mx-auto pt-20 p-10">
        <div
          className="px-20 py-20  bg-pink-400 text-center
    
       font-Noto text-6xl font-semibold text-white rounded-t-xl"
        >
          {lang === "en" ? (
            <a className="text-white"> {name_en}</a>
          ) : (
            <a className="text-white"> {`ວັນ ${name_la}`}</a>
          )}
        </div>

        <div className="  pl-20 font-Noto font-semibold ">
          <Breadcrumb style={{ fontSize: 18 }}>
            <Breadcrumb.Item>
              <Link to="/">{lang === "en" ? <a>Home</a> : <a>ໜ້າຫຼັກ</a>}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item overlay={menu}>
              {lang === "en" ? <a>Other Day</a> : <a>ວັນອື່ນໆ</a>}
            </Breadcrumb.Item>
            <Breadcrumb.Item className="text-pink-500">
              {lang === "en" ? <a> {name_en}</a> : <a> ວັນ{name_la}</a>}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="text-center font-Noto font-semibold text-2xl text-white pt-5 pb-3 bg-pink-400 rounded-t-xl p-5">
          {lang === "en" ? (
            <a className="text-white"> Doctors</a>
          ) : (
            <a className="text-white"> ບັນດາທ່ານໝໍທີ່ກວດ</a>
          )}
        </div>

        <div className="w-auto mx-w-none  flex justify-center flex-wrap  border-2 border-pink-400 ">
          {doctor?.map((item) => {
            return (
              <div className="team-profile pb-5" key={item._id}>
                <Link to={`/landingpage/doctordetail/${item?._id}`}>
                  <img
                    src={item?.images[0]?.url}
                    alt={item?._id}
                    className="team-img"
                  />
                </Link>

                <div className="px-2 py-4 font-Noto mx-0 my-0  ">
                  <div className="">
                    <p className="font-bold text-center text-pink-600">
                      {lang === "en" ? (
                        <a> {item?.name_en}</a>
                      ) : (
                        <a> {item?.name_la}</a>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <br />
        <div className="text-center font-Noto font-semibold text-2xl text-white pt-5 pb-3 bg-pink-400 rounded-t-xl">
          {lang === "en" ? (
            <a className="text-white">Dianosis</a>
          ) : (
            <a className="text-white">ພະຍາດທີ່ກວດ</a>
          )}
        </div>
        <div className="w-auto mx-w-none  flex justify-center flex-wrap  border-2 border-pink-400 ">
          {service?.map((item) => {
            return (
              <div className="team-profile" key={item._id}>
                <Link to={`/landingpage/servicedetail/${item?._id}`}>
                  <img
                    src={item?.images[0]?.url}
                    alt={item?._id}
                    className="team-img"
                  />
                </Link>

                <div className="px-2 py-4 font-Noto mx-0 my-0 text-lg ">
                  <div className="">
                    <p className="font-bold text-center ">
                      {lang === "en" ? (
                        <p className="text-lg"> {item?.serviceName_en}</p>
                      ) : (
                        <p> {item?.serviceName_la}</p>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <br />
        <div className="text-center font-Noto font-semibold text-2xl text-white pt-5 pb-3 bg-pink-400 rounded-t-xl">
          {lang === "en" ? (
            <a className="text-white">Number</a>
          ) : (
            <a className="text-white">ຫ້ອງກວດ</a>
          )}
        </div>

        <div className="w-auto mx-w-none  flex justify-center flex-wrap  border-2 border-pink-400 pt-5 gap-5 pb-5 font-Noto font-semibold text-xl">
          {room_number?.map((r, i) => {
            return (
              <div
                className="text-center text-white  bg-pink-400 px-5 py-5 rounded-xl shadow-xl hover:bg-pink-500 hover:scale-105 duration-300"
                key={i}
              >
                {`NO: ${r}`}
              </div>
            );
          })}
        </div>
        <br />
        <div className="text-center font-Noto font-semibold text-2xl text-white pt-5 pb-3 bg-pink-400 rounded-t-xl">
          {lang === "en" ? (
            <a className="text-white">Time</a>
          ) : (
            <a className="text-white"> ໂມງກວດ</a>
          )}
        </div>

        <div className="w-auto mx-w-none  flex justify-center flex-wrap  border-2 border-pink-400 pt-5 gap-5 pb-5 font-Noto font-semibold text-xl">
          {time_visit?.map((r, i) => {
            return (
              <div
                className="text-center text-white  bg-pink-400 px-5 py-5 rounded-xl shadow-xl hover:bg-pink-500 hover:scale-105 duration-300"
                key={i}
              >
                {` ${r}`}
              </div>
            );
          })}
        </div>
      </div>
      ;
    </>
  );
}
