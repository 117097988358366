import React, { useState, useEffect } from "react";
import {
  UsergroupAddOutlined,
  FileImageOutlined,
  ApartmentOutlined,
  CustomerServiceOutlined,
  UserOutlined,
} from "@ant-design/icons";
import AdminLayout from "../../components/layout/AdminLayout";
import useNumbers from "../../hooks/useAdminNumbers";
import { alladmindashboard } from "../../functions/admindashboard";
import { Link } from "react-router-dom";

const AdminPage = () => {
  const [adminnumbers, setAdminnumbers] = useState({});
  const { departments, services, sliderimages, users, machin, doctor } =
    adminnumbers;
  useEffect(() => {
    alladmindashboard()
      .then((res) => {
        setAdminnumbers(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  console.log("AdminPage", adminnumbers);
  return (
    <AdminLayout>
      <div className="container mx-auto pt-20 font-Noto h-screen">
        <h2 className="text-gray-500 text-2xl  uppercase tracking-wide font-Phetsarath font-semibold text-center">
          ໜ້າຫຼັກ
        </h2>
        <ul
          role="list"
          className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4 font-Phetsarath font-semibold"
        >
          {/* Start Users */}
          <li key="1" className="col-span-1 flex shadow-sm rounded-md ">
            <div className=" bg-green-500 flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md">
              Users
            </div>
            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
              <div className="flex-1 px-4 py-2 text-sm truncate">
                <Link to="/admin/alluser">
                  <a className="text-gray-900  hover:text-gray-600 font-semibold text-lg">
                    ຜູ້ໃຊ້ງານທັງໝົດ
                  </a>
                </Link>
                <p className=" text-lg text-pink-700">{users} Members</p>
              </div>
              <div className="flex-shrink-0 pr-2">
                <Link to="/admin/alluser">
                  <UsergroupAddOutlined style={{ fontSize: "40px" }} />
                </Link>
              </div>
            </div>
          </li>
          {/* End  Users */}
          {/* Start  Imageslider */}
          <li key="2" className="col-span-1 flex shadow-sm rounded-md ">
            <div className=" bg-green-500 flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md">
              Images
            </div>
            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
              <div className="flex-1 px-4 py-2 text-sm truncate">
                <Link to="/admin/slider">
                  <a className="text-gray-900  hover:text-gray-600 font-semibold text-lg">
                    ຮູບສະໄລໂຊທັງໝົດ
                  </a>
                </Link>
                <p className="text-pink-700 text-lg">{sliderimages} ຮູບ</p>
              </div>
              <div className="flex-shrink-0 pr-2">
                <Link to="/admin/slider">
                  <FileImageOutlined style={{ fontSize: "40px" }} />
                </Link>
              </div>
            </div>
          </li>
          {/* End  Imageslider */}

          {/* Start  Department */}
          <li key="3" className="col-span-1 flex shadow-sm rounded-md ">
            <div className=" bg-green-500 flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md">
              ພ/ນ
            </div>
            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
              <div className="flex-1 px-4 py-2 text-sm truncate">
                <Link to="/admin/Department">
                  <a className="text-gray-900  hover:text-gray-600 font-semibold text-lg">
                    ພະແນກການຕ່າງໆ
                  </a>
                </Link>
                <p className="text-pink-700 text-lg">{departments} ພະແນກ</p>
              </div>
              <div className="flex-shrink-0 pr-2">
                <Link to="/admin/Department">
                  <ApartmentOutlined style={{ fontSize: "40px" }} />
                </Link>
              </div>
            </div>
          </li>
          {/* End  Department */}

          {/* Start  Department */}
          <li key="4" className="col-span-1 flex shadow-sm rounded-md ">
            <div className=" bg-green-500 flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md">
              ບ/ກ
            </div>
            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
              <div className="flex-1 px-4 py-2 text-sm truncate">
                <Link to="/admin/services">
                  <a className="text-gray-900  hover:text-gray-600 font-semibold text-lg">
                    ການບໍລິການຕ່າງໆ
                  </a>
                </Link>
                <p className="text-pink-700 text-lg">{services} ພະແນກ</p>
              </div>
              <div className="flex-shrink-0 pr-2">
                <Link to="/admin/services">
                  <CustomerServiceOutlined style={{ fontSize: "40px" }} />
                </Link>
              </div>
            </div>
          </li>
          {/* End  Department */}
          {/* Start  Machin */}
          <li key="4" className="col-span-1 flex shadow-sm rounded-md ">
            <div className=" bg-green-500 flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md">
              MDV
            </div>
            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
              <div className="flex-1 px-4 py-2 text-sm truncate">
                <Link to="/admin/services">
                  <a className="text-gray-900  hover:text-gray-600 font-semibold text-lg">
                    ເຄື່ອງມືການແພດ
                  </a>
                </Link>
                <p className="text-pink-700 text-lg">{machin} ເຄື່ອງ</p>
              </div>
              <div className="flex-shrink-0 pr-2">
                <Link to="/admin/machin">
                  <CustomerServiceOutlined style={{ fontSize: "40px" }} />
                </Link>
              </div>
            </div>
          </li>
          {/* End  Machin */}
          {/* Start  Machin */}
          <li key="4" className="col-span-1 flex shadow-sm rounded-md ">
            <div className=" bg-green-500 flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md">
              Dr
            </div>
            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
              <div className="flex-1 px-4 py-2 text-sm truncate">
                <Link to="/admin/services">
                  <a className="text-gray-900  hover:text-gray-600 font-semibold text-lg">
                    ທ່ານໝໍທັງໝົດ
                  </a>
                </Link>
                <p className="text-pink-700 text-lg">{doctor} ທ່ານ</p>
              </div>
              <div className="flex-shrink-0 pr-2">
                <Link to="/admin/machin">
                  <UserOutlined style={{ fontSize: "40px" }} />
                </Link>
              </div>
            </div>
          </li>
          {/* End  Machin */}
        </ul>
      </div>
    </AdminLayout>
  );
};

export default AdminPage;
