import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ServiceFormCreate from "../../../components/forms/ServiceFormCreate";
import Adminlayout from "../../../components/layout/AdminLayout";
import { CreateService } from "../../../functions/service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ListDoctor } from "../../../functions/doctor";
import { ListDay } from "../../../functions/day";
import FileUpload from "../../../components/forms/FileUpload";

const time_visitdata = [
  { value: "8:00-16:00" },
  { value: "8:00-12:00" },
  { value: "13:30-16:00" },
];
const room_numberdata = [
  { value: "OPD" },
  { value: "1" },
  { value: "2" },
  { value: "3" },
  { value: "4" },
  { value: "5" },
  { value: "6" },
  { value: "7" },
  { value: "8" },
  { value: "9" },
  { value: "10" },
  { value: "11" },
  { value: "12" },
  { value: "13" },
  { value: "14" },
  { value: "15" },
  { value: "16" },
  { value: "17" },
  { value: "18" },
  { value: "19" },
  { value: "20" },
];

const ServiceCreate = () => {
  const { user } = useSelector((state) => state.user);
  console.log(user);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dayvisitdata, setDayvisitdata] = useState([]);
  const [doctordata, setDoctordata] = useState([]);
  const [values, setValues] = useState({
    serviceName_la: "",
    serviceName_en: "",
    description_la: "",
    description_en: "",
    dayvisit: [],
    doctor: [],
    time_visit: [],
    room_number: [],
    images: [],
  });

  useEffect(() => {
    // Load Dayvisit data
    setLoading(true);
    ListDay()
      .then((res) => {
        setDayvisitdata(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    // Load Services data
    setLoading(true);
    ListDoctor()
      .then((res) => {
        setDoctordata(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const handleChange = (e) => {
    console.log("name", values);
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    CreateService(user.token, values)
      .then((res) => {
        setLoading(false);
        toast.success("Create " + res.data.name + " Success");
        navigate("/admin/service/allservice");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response);
      });
  };

  return (
    <Adminlayout>
      <div className="contrainer mx-auto p-5 pt-20">
        <div className="col-span-9 shadow-xl rounded px-6 bg-white pt-5 pb-7 mt-6 lg:mt-0 font-Noto">
          <ServiceFormCreate
            onSubmit={onSubmit}
            handleChange={handleChange}
            values={values}
            setValues={setValues}
            room_numberdata={room_numberdata}
            time_visitdata={time_visitdata}
            dayvisitdata={dayvisitdata}
            doctordata={doctordata}
          />
          <FileUpload
            loading={loading}
            setLoading={setLoading}
            values={values}
            setValues={setValues}
          />
        </div>
      </div>
    </Adminlayout>
  );
};

export default ServiceCreate;
