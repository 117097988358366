import React from "react";
import DirectorLayout from "../../components/layout/DirectorLayout";

const DirectorPage = () => {
  return (
    <DirectorLayout>
      <h1>Director Pages</h1>
    </DirectorLayout>
  );
};

export default DirectorPage;
