import React from "react";
import { Select } from "antd";
const { Option } = Select;
const DayvisitForm = ({
  handleSubmit,
  handleChange,
  setValues,
  values,
  room_numberdata,
  time_visitdata,
  doctordb,
  servicedb,
}) => {
  const {
    name_la,
    name_en,
    description_la,
    description_en,
    doctor,
    service,
    time_visit,
    room_number,
  } = values;
  return (
    <form
      onSubmit={handleSubmit}
      className="font-Noto p-5 shadow-2xl rounded-2xl bg-white"
    >
      <h3 className="text-lg capitalize mb-4 font-semibold text-green-500 text-center">
        ເພີ່ມວັນກວດ
      </h3>

      <hr className="p-3" />
      <div className="space-y-4">
        <div className="grid sm:grid-cols-2 gap-4">
          <div>
            <input
              placeholder="ຊື່ວັນກວດເປັນພາສາລາວ"
              type="text"
              name="name_la"
              className="form-control"
              value={name_la}
              onChange={handleChange}
            />
          </div>

          <input
            type="text"
            placeholder="ຊື່ວັນກວດເປັນພາສາອັງກິດ"
            name="name_en"
            className="form-control"
            value={name_en}
            onChange={handleChange}
          />

          <div>
            <Select
              showSearch
              mode="multiple"
              style={{ width: "100%" }}
              className="form-control"
              placeholder="ເລືອກທ່ານໝໍທີ່ຈະກວດ"
              value={doctor}
              onChange={(value) => setValues({ ...values, doctor: value })}
            >
              {doctordb?.map((s) => (
                <Option key={s._id} value={s._id} className="font-Noto">
                  {s.name_la}
                </Option>
              ))}
            </Select>
          </div>

          <div>
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="ເລືອກການບໍລິການຕ່າງໆ"
              className="form-control"
              value={service}
              onChange={(value) => setValues({ ...values, service: value })}
            >
              {servicedb?.map((s) => (
                <Option key={s._id} value={s._id} className="font-Noto">
                  {s.serviceName_la}
                </Option>
              ))}
            </Select>
          </div>

          <div>
            <Select
              mode="multiple"
              className="form-control"
              style={{ width: "100%" }}
              placeholder="ເລືອກເວລາກວດ"
              value={time_visit}
              onChange={(value) => setValues({ ...values, time_visit: value })}
            >
              {time_visitdata?.map((s) => (
                <Option key={s.value} value={s.value}>
                  {s.value}
                </Option>
              ))}
            </Select>
          </div>

          <div>
            <Select
              mode="multiple"
              className="form-control"
              style={{ width: "100%" }}
              placeholder="ເລືອກຫ້ອງກວດເບີ"
              value={room_number}
              onChange={(value) => setValues({ ...values, room_number: value })}
            >
              {room_numberdata?.map((s) => (
                <Option key={s.value} value={s.value}>
                  {s.value}
                </Option>
              ))}
            </Select>
          </div>

          <div className="pt-3">
            <textarea
              id="message"
              rows="4"
              value={description_la}
              name="description_la"
              onChange={handleChange}
              className="form-control"
              placeholder="ຂໍ້ມູນຕ່າງໆເປັນພາສາລາວ"
            ></textarea>
          </div>
          <div className="pt-3">
            <textarea
              id="message"
              rows="4"
              value={description_en}
              name="description_en"
              onChange={handleChange}
              className="form-control"
              placeholder="ຂໍ້ມູນຕ່າງໆເປັນພາສາອັງກິດ"
            ></textarea>
          </div>
        </div>
      </div>
      <br />
      <button className="pink-btn">Save</button>
    </form>
  );
};

export default DayvisitForm;
