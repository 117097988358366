import React from "react";

export default function LoadingImageSlider({ count }) {
  const cards = () => {
    let totalCards = [];

    for (let i = 0; i < count; i++) {
      totalCards.push(
        <>
          <div class="flex flex-col w-full gap-5 p-2 mx-auto bg-white shadow-lg select-none sm:p-4 sm:h-64 rounded-2xl sm:flex-row animate-pulse"></div>
        </>
      );
    }

    return totalCards;
  };

  return <div className="flex">{cards()}</div>;
}
