import react from "react";

import { Menu, Avatar, Image } from "antd";
import { LogoutOutlined, HomeOutlined, DownOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { logout } from "../../redux/userSlice";
import { toast } from "react-toastify";
const { SubMenu } = Menu;

const DirecterTopNav = () => {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // context

  //   const handleClick = (e) => {
  //     console.log("click ", e);
  //     setCurrent(e.key);
  //   };

  const signOut = () => {
    localStorage.removeItem("token");
    dispatch(logout());
    navigate("/");
  };

  const handlelogout = () => {
    dispatch(logout());
    toast.warn("Logout Success");
    navigate("/");
  };

  return (
    <Menu
      mode="horizontal"
      style={{ position: "fixed", zIndex: 1, width: "100%" }}
    >
      <Menu.Item
        key="mail"
        style={{
          display: "block",
          marginBottom: "15px",
        }}
        icon={
          <HomeOutlined
            style={{
              fontSize: "23px",
              color: "#0dd179",
            }}
          />
        }
      >
        <Link to="/">
          <a>Home</a>
        </Link>
      </Menu.Item>

      <>
        <Menu.Item key="UserImage" style={{ marginLeft: "auto" }}>
          <Avatar
            src={
              <Image
                src="https://res.cloudinary.com/mtphospital/image/upload/v1668852016/doctor-medical-icon-cdr-187019168_fzthyi.jpg"
                style={{ width: 40 }}
              />
            }
          />
        </Menu.Item>
        <SubMenu key="SubMenu" icon={<DownOutlined />} title={user?.username}>
          <Menu.ItemGroup>
            <Menu.Item key="Dashboard_user_redirect">
              <Link to={`/${user?.role}/index`}>
                <a>Dashboard</a>
              </Link>
            </Menu.Item>

            <Menu.Item
              key="Admin_Logout"
              onClick={handlelogout}
              icon={<LogoutOutlined />}
            >
              <a>Logout</a>
            </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>
      </>
    </Menu>
  );
};

export default DirecterTopNav;
