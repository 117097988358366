import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { HomeOutlined } from "@ant-design/icons";

import { toast } from "react-toastify";

import {
  updateSliderimage,
  getSingleSliderimage,
} from "../../../functions/sliderimage";

import { useSelector } from "react-redux";
import Adminlayout from "../../../components/layout/AdminLayout";
import { useParams, useNavigate } from "react-router-dom";

const UpdateSliderimage = () => {
  const { slug } = useParams();
  console.log("slug", slug);

  const navigate = useNavigate();

  const { user } = useSelector((state) => ({ ...state.user }));
  console.log(user);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  const [file, setFile] = useState("");
  const [fileold, setFileold] = useState("");
  const [filename, setFilename] = useState("");

  useEffect(() => {
    loadSliderImage(slug, user.token);
  }, []);

  const loadSliderImage = (slug, authtoken) => {
    getSingleSliderimage(slug, authtoken)
      .then((res) => {
        console.log("res", res.data);
        setFileold(res.data.pic);
        setFilename(res.data.pic);
        setName(res.data.name);
      })
      .catch((err) => {
        toast.error(err);
        console.log(err);
      });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("filename", filename);
    formData.append("fileold", fileold);
    formData.append("data", name);

    updateSliderimage(formData, slug, user.token)
      .then((res) => {
        loadSliderImage(user.token);
        setLoading(false);
        toast.success("Update " + res.data.name + " Success");
        navigate("/admin/slider");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response);
      });
  };

  return (
    <Adminlayout>
      <div className="flex items-center justify-center w-full pt-20">
        <div className="shadow-xl bg-slate-300 rounded-lg">
          <div className="text-center p-2">
            <Link to="/admin/slider">
              <a className="font-Phetsarath font-semibold text-md">
                <HomeOutlined style={{ fontSize: "32px", paddingTop: "3px" }} />
              </a>
              <p className="p-3">Back</p>
            </Link>
          </div>

          <div className="flex justify-center text-xl font-Phetsarath text-pink-600 font-semibold">
            ແກ້ໄຂ ຮູບພາບສະໄລ້
          </div>

          {loading ? (
            <h4>Loading...</h4>
          ) : (
            <h4 className="text-center">Add Images</h4>
          )}
          <form onSubmit={onSubmit} className="flex-row p-5 ">
            <div className="flex">
              <label className="pr-5 pt-2">ຊື່ຮູບ</label>
              <input
                type="text"
                className="py-2 px-3 rounded-l-md border border-gray-400"
                value={name}
                autoFocus
                required
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className="custom-file mb-4">
              <input
                type="file"
                className="custom-file-input"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                  setFilename(e.target.files[0].name);
                }}
              />
              <br />
              <label
                className="border w-full border-gray-300 dark:border-gray-700 pl-1 py-1 shadow-lg rounded text-lg focus:outline-none bg-transparent focus:border-indigo-700 text-gray-800 dark:text-gray-100"
                htmlFor="customfile"
              >
                {filename}
              </label>
            </div>
            <div className="text-center p-6">
              <button className="py-3 px-6 rounded-lg bg-teal-700 text-white ">
                Update
              </button>
            </div>
          </form>
          <hr />
        </div>
      </div>
    </Adminlayout>
  );
};

export default UpdateSliderimage;
