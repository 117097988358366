import React from "react";

// Antd
import { LockOutlined, UserOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";

// Logo image
import logo from "../../components/logo.png";

// Function helper
import { login } from "../../functions/auth";

// redux
import { useDispatch } from "react-redux";
import { auth } from "../../redux/userSlice";

// V.6
import { useNavigate, useLocation } from "react-router-dom";

import { toast } from "react-toastify";
import { useState } from "react";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const roleBaseRedirect = (role) => {
    let intended = location.state;
    console.log(intended);
    if (intended) {
      navigate("../" + intended);
    } else if (role === "admin") {
      navigate("/admin/index");
    } else if (role === "director") {
      navigate("/director/index");
    } else if (role === "user") {
      navigate("/user/index");
    } else {
      navigate("/patient/index");
    }

    // if (intended) {
    //   navigate("../" + intended);
    // } else {
    //   if (role === "admin") {
    //     navigate("/admin/index");
    //   } else {
    //     navigate("/user");
    //   }
    // }
  };

  const onFinish = async (values) => {
    console.log("onFish=>", values);
    setLoading(true);
    login(values)
      .then((res) => {
        setLoading(false);
        console.log("resdata", res.data.payload);
        toast.success(res.data.payload.user.username + " Login Success");

        localStorage.setItem("token", res.data.token);
        // localStorage.setItem("user", JSON.stringify(res.data.payload));

        dispatch(
          auth({
            token: res.data.token,
            username: res.data.payload.user.username,
            role: res.data.payload.user.role,
            _id: res.data.payload.user._id,
          })
        );

        roleBaseRedirect(res.data.payload.user.role);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response.data);
        toast.error(err.response.data);
      });
  };

  return (
    <>
      <div className="flex justify-center h-screen items-center bg-background-login ">
        <div className="text-center shadow-md p-20 bg-gray-100 rounded-xl">
          <Form
            name="normal_login"
            className="login-form "
            // initialValues={{
            //   username: "ket",
            //   password: "123",
            // }}
            onFinish={onFinish}
          >
            <img
              className="h-[100px] w-[250px] text-center inline-block"
              src={logo}
              alt="logo"
            />

            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input your Username!",
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Username"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>

            <Form.Item className="text-center font-Noto ">
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                shape="round"
                disabled={loading}
              >
                {loading ? <LoadingOutlined /> : <p> Login</p>}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Login;
