import React from "react";
import DirectorLayout from "../../components/layout/DirectorLayout";

import { useParams } from "react-router-dom";
const UpdateDirectorProfile = () => {
  const { id } = useParams();
  return (
    <DirectorLayout>
      <div className="pt-20">{id}</div>
    </DirectorLayout>
  );
};

export default UpdateDirectorProfile;
