import axios from "axios";

export const ListRoom = async () => {
  return await axios.get(`${process.env.REACT_APP_API}/room`);
};

export const CreateRoom = async (authtoken, value) =>
  await axios.post(`${process.env.REACT_APP_API}/room`, value, {
    headers: {
      authtoken,
    },
  });

export const DeleteRoom = async (authtoken, id) =>
  await axios.delete(`${process.env.REACT_APP_API}/room/${id}`, {
    headers: {
      authtoken,
    },
  });

export const UpdateRoom = async (authtoken, value, id) =>
  await axios.put(`${process.env.REACT_APP_API}/room/${id}`, value, {
    headers: {
      authtoken,
    },
  });

export const ReadRoom = async (id) =>
  await axios.get(`${process.env.REACT_APP_API}/room/${id}`);

export const ListRoomByCount = async (count) =>
  await axios.get(`${process.env.REACT_APP_API}/roomByCount/${count}`);
