import React, { useState } from "react";

// AdminLayout
import Adminlayout from "../../components/layout/AdminLayout";

// React Router DOM V6
import { useNavigate } from "react-router-dom";

//Ant design Icon
import { UserOutlined, LockOutlined } from "@ant-design/icons";
//Ant design Components
import { Form, Input, Button, Col, Row } from "antd";

// Toastify Notifications
import { toast } from "react-toastify";

//Redux
import { useSelector } from "react-redux";

//Functions helper register
import { register } from "../../functions/auth";

const Register = () => {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    console.log("values => ", values);
    setLoading(true);
    try {
      register(user.token, values).then((values) => {
        console.log("values=>", values);
        toast.success(values.response.data);
      });
      if (values?.error) {
        toast.error(values.error);
        setLoading(false);
      } else {
        toast.success("Successfully Register");

        setLoading(false);
        // redirect

        navigate("/admin/alluser");
      }
    } catch (err) {
      toast.error("Signup failed. Try again.");
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <Adminlayout>
      <Row>
        <Col span={8} offset={8}>
          <h1
            style={{ paddingTop: "100px" }}
            className="font-Noto text-xl font-semibold text-center text-gray-500"
          >
            ລົງທະບຽນເຂົ້າໃຊ້ງານ
          </h1>
          {loading ? (
            <h4>Loading...</h4>
          ) : (
            <h4 className="text-center font-semibold text-xl text-green-400">
              Admin CreatePerson
            </h4>
          )}
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            {/* name */}
            <Form.Item
              name="username"
              rules={[{ required: true, message: "Please input your name!" }]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Name"
              />
            </Form.Item>

            {/* password */}
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your Password!" },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>

            <Form.Item className="text-center font-Noto">
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                ລົງທະບຽນ
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Adminlayout>
  );
};

export default Register;
