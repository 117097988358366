import React from "react";

export default function LoadingServiceCard({ count }) {
  const cards = () => {
    let totalCards = [];

    for (let i = 0; i < count; i++) {
      totalCards.push(
        <>
          <div className="justify-center animate-pulse" key={i}>
            <div className="w-20 h-20 bg-gray-200 rounded-full mb-5 items-centerS" />
            <div className="w-20 h-5 bg-gray-100 rounded-md " />
          </div>
        </>
      );
    }

    return totalCards;
  };

  return <div className="grid grid-cols-6">{cards()}</div>;
}
