import axios from "axios";

export const register = async (authtoken, value) =>
  await axios.post(`${process.env.REACT_APP_API}/register`, value, {
    headers: {
      authtoken,
    },
  });

export const login = async (value) =>
  await axios.post(`${process.env.REACT_APP_API}/login`, value);

export const currentUser = async (authtoken) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/current-user`,
    {},
    {
      headers: {
        authtoken,
      },
    }
  );
};

export const currentAdmin = async (authtoken) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/current-admin`,
    {},
    {
      headers: {
        authtoken,
      },
    }
  );
};
export const currentDirector = async (authtoken) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/current-director`,
    {},
    {
      headers: {
        authtoken,
      },
    }
  );
};
