import React from "react";

import { Layout } from "antd";
import UserNav from "../sidebar/UserNav";
import UserTopNav from "../Topnav/Usertopnav";

const { Content } = Layout;

const Userlayout = ({ children }) => {
  return (
    <Layout>
      {/* <UserTopNav /> */}
      <Layout>
        <UserNav />
        <Content style={{ padding: "10px" }}>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default Userlayout;
