import axios from "axios";

export const createDepartment = async (authtoken, value) =>
  await axios.post(`${process.env.REACT_APP_API}/department`, value, {
    headers: {
      authtoken,
    },
  });

export const listDepartments = async () =>
  await axios.get(`${process.env.REACT_APP_API}/department`);

export const ReadDepartment = async (id) =>
  await axios.get(`${process.env.REACT_APP_API}/department/${id}`);

export const deleteDepartment = async (authtoken, id) =>
  await axios.delete(`${process.env.REACT_APP_API}/department/${id}`, {
    headers: {
      authtoken,
    },
  });

export const UpdateDepartment = async (authtoken, id, value) =>
  await axios.put(`${process.env.REACT_APP_API}/department/${id}`, value, {
    headers: {
      authtoken,
    },
  });

export const listDepartmentByCount = async (count) =>
  await axios.get(`${process.env.REACT_APP_API}/departmentByCount/${count}`);
