import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useState, useEffect } from "react";
import { getSliderimageLandingPage } from "../../functions/sliderimage";

export default function ImageSlider() {
  const [sliderimage, setSliderimage] = useState([]);

  useEffect(() => {
    getSliderimageLandingPage()
      .then((res) => {
        setSliderimage(res.data);
        // console.log(res.data);
      })
      .catch((err) => {
        // toast.error(err);
        console.log(err);
      });
  }, []);

  // console.log("Images Slider =>", sliderimage);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,

    appendDots: (dots) => {
      return <ul style={{ margin: "0px" }}>{dots}</ul>;
    },
  };

  return (
    <Slider {...settings}>
      {sliderimage &&
        sliderimage.map((item) => {
          return (
            <>
              <div className="flex justify-center" key={item._id}>
                <img
                  src={`${process.env.REACT_APP_API_Img}/images/sliderimages/${item.pic}`}
                  alt={item._id}
                  style={{ width: "100%", height: "500px" }}
                />
              </div>
            </>
          );
        })}
    </Slider>
  );
}
