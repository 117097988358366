import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import RoomDetailCard from "../../../components/cards/RoomDetailCard";
import { ReadRoom } from "../../../functions/room";

export default function DetailRoomLandingPage() {
  const param = useParams();
  const [roomsingledata, setRoomsingledata] = useState([]);
  const { lang } = useSelector((state) => state.lang.lang);
  useEffect(() => {
    //code
    loadData();
  }, []);

  const loadData = () => {
    ReadRoom(param.id)
      .then((res) => {
        //code
        setRoomsingledata(res.data);
        // console.log(res.data);
      })
      .catch((err) => {
        //err
        console.log(err.response.data);
      });
  };

  return (
    <div className="container pt-20 mx-auto">
      <RoomDetailCard Room={roomsingledata} lang={lang} />
    </div>
  );
}
