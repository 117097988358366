import React from "react";
import { Form, Input, Button, Space } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
const { TextArea } = Input;
function ExperienceProjects() {
  return (
    <div>
      <h5>
        <b>Experience</b>
      </h5>
      <hr className="pb-3" />
      <Form.List name="experience">
        {(fields, { add, remove }) => (
          <>
            <div className="space-y-1">
              <div className="grid sm:grid-cols-5 gap-4">
                {fields.map(({ key, name, ...restField }) => (
                  <>
                    <div className="w-full">
                      <Form.Item
                        {...restField}
                        name={[name, "company"]}
                        rules={[
                          { required: true, message: "Missing first name" },
                        ]}
                      >
                        <Input placeholder="Company" />
                      </Form.Item>
                    </div>

                    <div className="w-full">
                      <Form.Item
                        {...restField}
                        name={[name, "years"]}
                        rules={[
                          { required: true, message: "Missing first name" },
                        ]}
                      >
                        <Input placeholder="Years" />
                      </Form.Item>
                    </div>

                    <div className="w-full">
                      <Form.Item
                        {...restField}
                        name={[name, "place"]}
                        rules={[
                          { required: true, message: "Missing first name" },
                        ]}
                      >
                        <Input placeholder="Place" />
                      </Form.Item>
                    </div>
                    <div className="w-full">
                      <Form.Item
                        {...restField}
                        name={[name, "range"]}
                        rules={[
                          { required: true, message: "Missing first name" },
                        ]}
                      >
                        <Input placeholder="Year Range" />
                      </Form.Item>
                    </div>
                    <div className="w-full">
                      <MinusCircleOutlined
                        style={{ fontSize: 25, color: "tomato" }}
                        onClick={() => remove(name)}
                      />
                    </div>
                  </>
                ))}
              </div>
            </div>

            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={
                  <PlusOutlined style={{ fontSize: 20, color: "#2ee815" }} />
                }
              >
                Add Experience
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      <h5>
        <b>Projects</b>
      </h5>
      <hr className="pb-3" />
      <Form.List name="projects">
        {(fields, { add, remove }) => (
          <>
            <div className="space-y-1">
              <div className="grid sm:grid-cols-4 gap-4">
                {fields.map(({ key, name, ...restField }) => (
                  <>
                    <div className="col-md-4">
                      <Form.Item
                        {...restField}
                        name={[name, "title"]}
                        rules={[
                          { required: true, message: "Missing first name" },
                        ]}
                      >
                        <Input placeholder="Title" />
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <Form.Item
                        {...restField}
                        name={[name, "rating"]}
                        rules={[
                          { required: true, message: "Missing first name" },
                        ]}
                      >
                        <TextArea placeholder="Description" />
                      </Form.Item>
                    </div>
                    <div className="col-md-2">
                      <Form.Item
                        {...restField}
                        name={[name, "range"]}
                        rules={[
                          { required: true, message: "Missing first name" },
                        ]}
                      >
                        <Input placeholder="Year Range" />
                      </Form.Item>
                    </div>

                    <div className="col-md-2">
                      <MinusCircleOutlined
                        style={{ fontSize: 25, color: "tomato" }}
                        onClick={() => remove(name)}
                      />
                    </div>
                  </>
                ))}
              </div>
            </div>

            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={
                  <PlusOutlined style={{ fontSize: 20, color: "#2ee815" }} />
                }
              >
                Add Project
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </div>
  );
}

export default ExperienceProjects;
