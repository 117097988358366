import { createSlice } from "@reduxjs/toolkit";
const initialStae = {
  lang: "en",
};

export const langSlice = createSlice({
  name: "langStore",
  initialState: initialStae,
  reducers: {
    la: (state, action) => {
      state.lang = action.payload;
    },
  },
});
export const { la } = langSlice.actions;
export default langSlice.reducer;
