import React, { useState, useEffect } from "react";
import DayVisitCard from "../../components/cards/DayVisitCard";
import DoctorCardLandingPage from "../../components/cards/DoctorCardLandingPage";
import RoomCard from "../../components/cards/RoomCard";
import ServiceCard from "../../components/cards/ServiceCard";
import FooterLandingPage from "../../components/FooterLandingPage";
import MedicalDevices from "../../components/landingpages/MedicalDevices";
import { ListRoomByCount } from "../../functions/room";
import ImageSlider from "./ImageSlider";
import { EyeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { ListDay } from "../../functions/day";
import { ListServices } from "../../functions/service";
import { listMachinByCount } from "../../functions/machin";
import { ListDoctor } from "../../functions/doctor";
//css React -slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LoadingServiceCard from "../../components/cards/LoadingServiceCard";
import LoadingDayCard from "../../components/cards/LoadingDayCard";
import LoadingImageSlider from "../../components/cards/LoadingImageSlider";
import LoadingDoctorCard from "../../components/cards/LoadingDoctorCard";

export default function HomeLandingPages() {
  const { lang } = useSelector((state) => state.lang.lang);
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dayvisitdata, setDayVisitdata] = useState([]);
  const [servicedata, setServicedata] = useState([]);
  const [machin, setMachin] = useState([]);
  const [doctoralldata, setDoctorAlldata] = useState([]);
  useEffect(() => {
    setLoading(true);
    ListRoomByCount(4)
      .then((res) => {
        setLoading(false);
        setRooms(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    localStorage.setItem("lang", "la");
    //Load Day visit
    setLoading(true);
    ListDay()
      .then((res) => {
        setLoading(false);
        setDayVisitdata(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    //load Services
    setLoading(false);
    ListServices()
      .then((res) => {
        setLoading(false);
        setServicedata(res.data);
        // console.log(res.data);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
    //load Medical Devices
    setLoading(true);
    listMachinByCount(4)
      .then((res) => {
        setLoading(false);
        setMachin(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    //Load Doctor
    setLoading(true);
    ListDoctor()
      .then((res) => {
        setLoading(false);
        setDoctorAlldata(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  const DoctorLandingPageShow = doctoralldata.filter((d) => {
    return d.position == "ຫົວໜ້າພະແນກ" && d.professional_level == "1";
  });
  return (
    <>
      {/* Start Images Slider */}
      <div className="pt-12">
        {loading ? <LoadingImageSlider count={1} /> : <ImageSlider />};
      </div>
      {/* End Images Slider */}

      {/* Start Services Card  */}
      <div className="max-w-[1500px] mx-auto  ">
        {loading ? (
          <LoadingServiceCard count={18} />
        ) : (
          <ServiceCard lang={lang} servicedata={servicedata} />
        )}
      </div>
      {/* End Services Card  */}

      {/*  Start Day visit */}

      <div className="max-w-[1500px] mx-auto  pt-5">
        <div className="border-2 border-pink-500 rounded-xl">
          <p className="text-center p-3 bg-pink-400 rounded-t-xl font-Noto font-semibold text-xl text-white">
            {" "}
            {lang === "en" ? (
              <a className="text-white">Day</a>
            ) : (
              <a className="text-white"> ວັນກວດພະຍາດ</a>
            )}
          </p>
          {loading ? (
            <LoadingDayCard count={5} />
          ) : (
            <DayVisitCard lang={lang} dayvisitdata={dayvisitdata} />
          )}
        </div>
      </div>
      {/*  End Day visit */}
      {/*  Start Doctor*/}
      <div className="max-w-[1500px] mx-auto  pt-5">
        {loading ? (
          <LoadingDoctorCard count={6} />
        ) : (
          <DoctorCardLandingPage
            lang={lang}
            Link={Link}
            doctoralldata={doctoralldata}
            DoctorLandingPageShow={DoctorLandingPageShow}
          />
        )}
      </div>
      {/*  End Doctor*/}
      {/*  Start MedicalDevices*/}
      <div className="max-w-[1500px] mx-auto  pt-10">
        <MedicalDevices lang={lang} machin={machin} />
      </div>
      {/*  End MedicalDevices*/}
      <br />
      {/*  Start RoomCard*/}
      <div className="max-w-[1500px] mx-auto  border-2 border-pink-400 rounded-xl">
        <div className="text-center p-2 bg-pink-400 rounded-t-xl font-Noto font-semibold text-xl text-white flex justify-center ">
          <div className="">
            {" "}
            {lang === "en" ? (
              <a className="text-white"> Beb Room</a>
            ) : (
              <a className="text-white"> ຫ້ອງນອນຄົນເຈັບ</a>
            )}
          </div>
        </div>
        <p className="text-right">
          <Link to="/landingpage/rooms">
            <button className="pr-3">
              <div className="flex flex-round">
                <a className="pl-3">
                  <EyeOutlined
                    style={{
                      fontSize: "25px",
                      pendingright: "3px",
                      color: "#ed1191",
                    }}
                  />
                </a>
                {lang === "en" ? (
                  <a className="text-pink-600 pl-2">See All </a>
                ) : (
                  <a className="text-pink-600  pl-2 font-Noto font-semibold pt-1">
                    {" "}
                    ເບິ່ງທັງໝົດ
                  </a>
                )}
              </div>
            </button>
          </Link>
        </p>
        <div className="grid grid-cols-1 xl:grid-cols-4  lg:grid-cols-4 gap-4 md:grid-cols-4 sm:grid-cols-1 xs:grid-cols-1 pb-5 pr-3 pl-3">
          <RoomCard rooms={rooms} lang={lang} Link={Link} />
        </div>
      </div>
      {/*  End RoomCard*/}
      <div className="max-w-[1500px] mx-auto  pt-5">
        <FooterLandingPage />
      </div>
    </>
  );
}
