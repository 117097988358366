import {
  CameraOutlined,
  UserSwitchOutlined,
  HomeOutlined,
  UserAddOutlined,
  ScheduleOutlined,
  ClusterOutlined,
  UsergroupAddOutlined,
  ApartmentOutlined,
  ApiOutlined,
} from "@ant-design/icons";

export const AdminNavdata = [
  {
    id: 1,

    icon: <UserSwitchOutlined style={{ fontSize: 20, color: "#e310ab" }} />,
    title: "Users",

    Menu_item: [
      {
        id: 1,
        link: "/admin/alluser",
        icon: (
          <UsergroupAddOutlined style={{ fontSize: 20, color: "#e310ab" }} />
        ),
        label: "All users",
      },
      {
        id: 2,
        link: "/register",
        icon: <UserSwitchOutlined style={{ fontSize: 20, color: "#e310ab" }} />,
        label: "Add User",
      },
    ],
  },
  {
    id: 2,

    icon: <CameraOutlined style={{ fontSize: 20, color: "#e310ab" }} />,
    title: "ຮູບສະໄລ",

    Menu_item: [
      {
        id: 1,
        link: "/admin/slider",
        icon: <CameraOutlined style={{ fontSize: 20, color: "#e310ab" }} />,
        label: "ຮູບສະໄລ",
      },
    ],
  },
  {
    id: 3,

    icon: <UserAddOutlined style={{ fontSize: 20, color: "#e310ab" }} />,
    title: "Doctors",

    Menu_item: [
      {
        id: 1,
        link: "/admin/alldoctor",
        icon: <UserAddOutlined style={{ fontSize: 20, color: "#e310ab" }} />,
        label: "All Doctors",
      },
      {
        id: 2,
        link: "/admin/doctor/create",
        icon: <UserAddOutlined style={{ fontSize: 20, color: "#e310ab" }} />,
        label: "Add New",
      },
    ],
  },
  {
    id: 4,

    icon: <ScheduleOutlined style={{ fontSize: 20, color: "#e310ab" }} />,
    title: "ເພີ່ມວັນກວດ",

    Menu_item: [
      {
        id: 1,
        link: "/admin/day/create",
        icon: <ScheduleOutlined style={{ fontSize: 20, color: "#e310ab" }} />,
        label: "All Day",
      },
    ],
  },
  {
    id: 5,

    icon: <ApartmentOutlined style={{ fontSize: 20, color: "#e310ab" }} />,
    title: "Departments",

    Menu_item: [
      {
        id: 1,
        link: "/admin/Department/create",
        icon: <ApartmentOutlined style={{ fontSize: 20, color: "#e310ab" }} />,
        label: "All Departments",
      },
    ],
  },
  {
    id: 6,

    icon: <ApiOutlined style={{ fontSize: 20, color: "#e310ab" }} />,
    title: "Medical Devices",

    Menu_item: [
      {
        id: 1,
        link: "/admin/machin",
        icon: <ApiOutlined style={{ fontSize: 20, color: "#e310ab" }} />,
        label: "Medical Devices",
      },
    ],
  },
  {
    id: 7,

    icon: <ClusterOutlined style={{ fontSize: 20, color: "#e310ab" }} />,
    title: "ການບໍລິການຕ່າງໆ",

    Menu_item: [
      {
        id: 1,
        link: "/admin/service/create",
        icon: <ClusterOutlined style={{ fontSize: 20, color: "#e310ab" }} />,
        label: "New Service",
      },
      {
        id: 2,
        link: "/admin/service/allservice",
        icon: <ClusterOutlined style={{ fontSize: 20, color: "#e310ab" }} />,
        label: "All Service",
      },
    ],
  },
  {
    id: 8,

    icon: <HomeOutlined style={{ fontSize: 20, color: "#e310ab" }} />,
    title: "ຫ້ອງນອນ",

    Menu_item: [
      {
        id: 1,
        link: "/admin/room/create",
        icon: <HomeOutlined style={{ fontSize: 20, color: "#e310ab" }} />,
        label: "New Room",
      },
      {
        id: 2,
        link: "/admin/room/allroom",
        icon: <HomeOutlined style={{ fontSize: 20, color: "#e310ab" }} />,
        label: "All Room",
      },
    ],
  },
];

export const Languages = [
  { en: "/assets/flags/en.png", la: "/assets/flags/la.png" },
];
