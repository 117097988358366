import axios from "axios";

export const createMachin = async (authtoken, value) =>
  await axios.post(`${process.env.REACT_APP_API}/machin`, value, {
    headers: {
      authtoken,
    },
  });
export const listMachin = async () =>
  await axios.get(`${process.env.REACT_APP_API}/machin`);

export const listMachinByCount = async (count) =>
  await axios.get(`${process.env.REACT_APP_API}/machinByCount/${count}`);

export const readMachin = async (id) =>
  await axios.get(`${process.env.REACT_APP_API}/machin/${id}`);

export const deleteMachin = async (authtoken, id) =>
  await axios.delete(`${process.env.REACT_APP_API}/machin/${id}`, {
    headers: {
      authtoken,
    },
  });

export const editMachin = async (authtoken, id, value) =>
  await axios.put(`${process.env.REACT_APP_API}/machin/${id}`, value, {
    headers: {
      authtoken,
    },
  });
