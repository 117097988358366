import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import UserFormProfileUpdate from "../../../components/forms/UserFormProfileUpdate";
import Adminlayout from "../../../components/layout/AdminLayout";
import { ReadUser, UpdateProfileAdmin } from "../../../functions/users";
import FileUpload from "../../../components/forms/FileUpload";

const UpdateAdminProfile = () => {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState([]);

  useEffect(() => {
    ReadUser(user._id, user.token)
      .then((res) => {
        setValues({ ...values, ...res.data });
        console.log("res.data", res.data);
        console.log("...values", values);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handlechange = (e) => {
    console.log("name", values);
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    UpdateProfileAdmin(user.token, values, user._id)
      .then((res) => {
        setLoading(false);
        toast.success("Create " + res.data.name + " Success");
        navigate("/admin/index");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response);
      });
  };

  return (
    <Adminlayout>
      <div className="pt-20 contrainer mx-auto ">
        {" "}
        <UserFormProfileUpdate
          values={values}
          handlechange={handlechange}
          onSubmit={onSubmit}
        />
        <FileUpload
          loading={loading}
          setLoading={setLoading}
          values={values}
          setValues={setValues}
        />
      </div>
    </Adminlayout>
  );
};

export default UpdateAdminProfile;
