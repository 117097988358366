import React from "react";

import { Select } from "antd";

const { Option } = Select;

const ServiceFormCreate = ({
  onSubmit,
  handleChange,
  values,
  setValues,
  time_visitdata,
  room_numberdata,
  dayvisitdata,
  doctordata,
}) => {
  const {
    serviceName_la,
    serviceName_en,
    description_la,
    description_en,
    doctor,
    dayvisit,
    time_visit,
    room_number,
  } = values;

  return (
    <form onSubmit={onSubmit}>
      <h3 className="text-lg capitalize mb-4 font-semibold text-green-500">
        New Service
      </h3>

      <hr className="p-3" />
      <div className="space-y-4">
        <div className="grid sm:grid-cols-2 gap-4">
          <div>
            <input
              type="text"
              placeholder="ຊື່ການບໍລິການເປັນພາສາລາວ"
              className="input-box w-full px-2 py-2 rounded-lg outline-none bg-slate-100"
              name="serviceName_la"
              value={serviceName_la}
              required
              onChange={handleChange}
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="ຊື່ການບໍລິການເປັນພາສາອັງກິດ"
              className="input-box w-full px-2 py-2 rounded-lg outline-none bg-slate-100"
              name="serviceName_en"
              value={serviceName_en}
              required
              onChange={handleChange}
            />
          </div>
          <div>
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="ເລືອກວັນທີ່ກວດ"
              value={dayvisit}
              onChange={(value) => setValues({ ...values, dayvisit: value })}
            >
              {dayvisitdata?.map((s) => (
                <Option key={s._id} value={s._id} className="font-Noto">
                  {s.name_la}
                </Option>
              ))}
            </Select>
          </div>

          <div>
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="ເລືອກເວລາກວດ"
              value={time_visit}
              onChange={(value) => setValues({ ...values, time_visit: value })}
            >
              {time_visitdata?.map((s) => (
                <Option key={s.value} value={s.value}>
                  {s.value}
                </Option>
              ))}
            </Select>
          </div>
          <div>
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="ເລືອກທ່ານໝໍ"
              value={doctor}
              onChange={(value) => setValues({ ...values, doctor: value })}
            >
              {doctordata?.map((d) => (
                <Option key={d._id} value={d._id} className="font-Noto">
                  {d.name_la}
                </Option>
              ))}
            </Select>
          </div>
          <div>
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="ເລືອກຫ້ອງກວດເບີ"
              value={room_number}
              onChange={(value) => setValues({ ...values, room_number: value })}
            >
              {room_numberdata?.map((s) => (
                <Option key={s.value} value={s.value}>
                  {s.value}
                </Option>
              ))}
            </Select>
          </div>
          <div>
            <textarea
              id="message"
              rows="4"
              name="description_la"
              value={description_la}
              onChange={handleChange}
              className="input-box w-full px-2 py-2 rounded-lg outline-none bg-slate-100"
              placeholder="ຄຳອະທິບາຍເປັນພາສາລາວ"
            ></textarea>
          </div>
          <div>
            <textarea
              id="message"
              rows="4"
              name="description_en"
              value={description_en}
              onChange={handleChange}
              className="input-box w-full px-2 py-2 rounded-lg outline-none bg-slate-100"
              placeholder="ຄຳອະທິບາຍເປັນພາສາອັງກິດ"
            ></textarea>
          </div>
        </div>
      </div>
      <div className="pt-3 ">
        <button className="px-6 py-2 bg-green-500 text-white  rounded-lg hover:bg-green-700">
          Save
        </button>
      </div>
    </form>
  );
};

export default ServiceFormCreate;
