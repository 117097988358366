import { Table, Avatar, Image } from "antd";

import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import Adminlayout from "../../../components/layout/AdminLayout";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { useSelector } from "react-redux";
import { DeleteRoom, ListRoom } from "../../../functions/room";

export default function AllRooms() {
  const { user } = useSelector((state) => state.user);
  const [roomdata, setRoomdata] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    ListRoom()
      .then((res) => {
        setRoomdata(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRemove = (id) => {
    if (window.confirm("Are you sure Delete!")) {
      DeleteRoom(user.token, id)
        .then((res) => {
          console.log(res);
          loadData();
          toast.error("Remove Data " + res.data.roomName_la + " Success!!!");
        })
        .catch((err) => {
          console.log(err);
          toast.error("Error!!! Insert Data");
        });
    }
  };

  const columns = [
    {
      title: "ປະເພດຫ້ອງນອນ",
      dataIndex: "roomName_la",
      key: "roomName_la",
    },
    {
      title: "ລາຄາ",
      dataIndex: "price",
      key: "price",
    },

    {
      title: "ຕົວຢ່າງຮູບ",
      render: (record) => (
        <Avatar src={<Image src={record?.images[0]?.url} />} />
      ),
    },
    {
      title: "Actions",
      render: (record) => (
        <>
          <span
            onClick={() => handleRemove(record._id)}
            className="cursor-pointer ml-auto"
          >
            <DeleteOutlined
              style={{
                fontSize: "22px",
                color: "#b34969",
              }}
            />
          </span>
          <Link to={"/admin/room/update/" + record._id}>
            <span>
              <EditOutlined
                style={{
                  fontSize: "22px",
                  color: "#b34969",
                  marginLeft: "5px",
                }}
              />
            </span>
          </Link>
        </>
      ),
    },
  ];

  return (
    <Adminlayout>
      <div className="contrainer mx-auto p-5 pt-20 font-Noto">
        <div className="col-span-9 shadow-xl rounded px-6 bg-white pt-5 pb-7 mt-6 lg:mt-0 font-Phetsarath">
          <Table columns={columns} dataSource={roomdata} />
        </div>
      </div>
    </Adminlayout>
  );
}
