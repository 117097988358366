import axios from "axios";

export const ListServices = async () => {
  return await axios.get(`${process.env.REACT_APP_API}/service`);
};
export const ListServicesCustom = async () => {
  return await axios.get(`${process.env.REACT_APP_API}/servicecustom`);
};

export const CreateService = async (authtoken, value) =>
  await axios.post(`${process.env.REACT_APP_API}/service`, value, {
    headers: {
      authtoken,
    },
  });

export const DeleteService = async (authtoken, id) =>
  await axios.delete(`${process.env.REACT_APP_API}/service/${id}`, {
    headers: {
      authtoken,
    },
  });

export const UpdateService = async (authtoken, value, id) =>
  await axios.put(`${process.env.REACT_APP_API}/service/${id}`, value, {
    headers: {
      authtoken,
    },
  });

export const ReadService = async (id) =>
  await axios.get(`${process.env.REACT_APP_API}/service/${id}`);
