import React from "react";
import { Link } from "react-router-dom";

export default function MedicalDeviceCard({ medicaldevicedata, lang }) {
  return (
    <>
      {medicaldevicedata?.map((item) => (
        <div
          key={item._id}
          className="border shadow-lg rounded-lg hover:scale-105 duration-300 text-center"
        >
          <img
            src={item?.images[0]?.url}
            alt={item?.name}
            className="w-full h-[200px] object-cover rounded-t-lg"
          />
          <div className="px-2 py-4 text-center">
            <p className="font-bold text-xl text-pink-600 ">{item?.name}</p>
            <Link to={`/medicaldevicedetail/${item?._id}`}>
              <button className="pink-btn" sx={{ marginLeft: "auto" }}>
                {lang === "en" ? "Detail" : "ລາຍລະອຽດ"}
              </button>
            </Link>
          </div>
        </div>
      ))}
    </>
  );
}
