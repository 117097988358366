import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DoctorFormUpdate from "../../../components/forms/DoctorFormUpdate";
import Adminlayout from "../../../components/layout/AdminLayout";
import { DoctorUpdateProfile, ReadDoctor } from "../../../functions/doctor";
import { toast } from "react-toastify";
import { Breadcrumb } from "antd";
import { HomeOutlined } from "@ant-design/icons";
const DoctorUpdate = () => {
  const { user } = useSelector((state) => state.user);
  console.log("userToken", user);
  //Get Id use Parames
  const { id } = useParams();
  const navigate = useNavigate();
  //State
  // const [doctor, setDoctor] = useState([]);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    name_en: "",
    name_la: "",
    deeper_la: "",
    deeper_en: "",
    experience_en: "",
    experience_la: "",
    education_la: "",
    education_en: "",
    mobileNumber: "",
    department: "",
    position: "",
    professional_level: "",
    mobileNumber: "",
    email: "",
    images: [],
    description: "",
    dayvisit: [],
    service: [],
    time_visit: [],
    room_number: [],
  });

  useEffect(() => {
    ReadDoctor(id)
      .then((dc) => {
        // console.log("single Doctor", dc);
        // 1 load single Doctor
        setValues({ ...values, ...dc.data });
        console.log(dc.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    DoctorUpdateProfile(user.token, values, id)
      .then((res) => {
        setLoading(false);
        toast.success("Create " + res.data.name + " Success");
        navigate("/admin/alldoctor");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response);
      });
  };
  const handledoctor = (e) => {
    console.log("name", values);
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  // console.log("doctorSingleData", doctor);
  console.log("Values", values);
  return (
    <Adminlayout>
      <div className="contrainer mx-auto p-5 pt-20">
        <Breadcrumb className="font-Phetsarath font-semibold">
          <Breadcrumb.Item href="/admin/alldoctor">
            <HomeOutlined style={{ fontSize: "23px", color: "#17cf73" }} />
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/admin/alldoctor">
            <span>ລາຍການທ່ານໝໍທັງໝົດ</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>ແກ້ໄຂເຄື່ອງມືການແພດ</Breadcrumb.Item>
        </Breadcrumb>
        <div className="col-span-9 shadow-xl rounded px-6 bg-white pt-2 pb-7 mt-6 lg:mt-0 font-Noto">
          <DoctorFormUpdate
            onSubmit={onSubmit}
            handledoctor={handledoctor}
            values={values}
            setValues={setValues}
          />
        </div>
      </div>
    </Adminlayout>
  );
};
export default DoctorUpdate;
