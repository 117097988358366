import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { currentDirector } from "../../functions/auth";
import LoadingToRedirect from "./LoadingToRedirect";

const DirectorRoute = ({ children }) => {
  const { user } = useSelector((state) => state.user);
  const [ok, setOk] = useState(false);

  useEffect(() => {
    if (user && user.token) {
      currentDirector(user.token)
        .then((res) => {
          //res
          // console.log(res);
          setOk(true);
        })
        .catch((err) => {
          //err
          // console.log(err);
          setOk(false);
        });
    }
  }, [user]);

  return ok ? children : <LoadingToRedirect />;
};

export default DirectorRoute;
